import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import iconEyePass from "../images/on-boarding/icon-eye-pass.svg";

class PasswordInputs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      pwScore: -1,
      error: {
        password1: true,
        password2: true,
      },
      style: {
        password1: '',
        password2: ''
      }
    }

    this.showPassword = this.showPassword.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);

  }

  render() {
    return (
      <>
        <div className={"form-group has-feedback " + (this.state.style.password1)}>
          <div className="showPass" onClick={this.showPassword}><img src={iconEyePass} alt="Show Password" /> <span ref="textchange">show</span></div>
          {this.props.withoutLabel ?
            null
            :
            < label >
              <FormattedMessage id="WA.Landing.Password" />
            </label>
          }
          <input
            id="password1"
            name="password1"
            type="password"
            className="form-control"
            placeholder={this.props.intl.formatMessage({ id: 'WA.Landing.EnterYourPassword' })}
            onChange={this.passwordChanged}
          />
          <div class="meter-gauge">
            <div data-value={this.state.pwScore}></div>
          </div>
 
          {this.state.error.password1 ?
            <span className="help-block">
              <FormattedMessage
                id="WA.PasswordFormation"
              />
            </span>
            : null
          }
          <span className={"glyphicon glyphicon-" + (this.state.error.password1 ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
        </div>
        <div className={"form-group has-feedback " + (this.state.style.password2)}>
          {this.props.withoutLabel ?
            null
            :
            <label>
              <FormattedMessage id="WA.EditProfile.RepeatPassword" />
            </label>
          }
          <input
            id="password2"
            name="password2"
            type="password"
            className="form-control"
            placeholder={this.props.intl.formatMessage({ id: 'WA.EditProfile.RepeatPasswordPlaceholder' })}
            onChange={this.passwordChanged}
            disabled={this.state.error.password1}
          />
          <span className={"glyphicon glyphicon-" + (this.state.error.password2 ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
        </div>
      </>
    );
  }

  showPassword() {
    let inputPass = document.getElementById('password1');
    if (inputPass.type  === "password") {
      inputPass.type = "text";
      this.refs.textchange.innerHTML =  "hide";
    } else {
      inputPass.type = "password";
      this.refs.textchange.innerHTML = "show";
    }
  }
  

  passwordChanged(event) {
    const pw1 = document.getElementById("password1").value;
    const pw2 = document.getElementById("password2").value;
    let password = null;
    let error1 = false;
    let error2 = false;
    let pwScore = zxcvbn(pw1).score; 

    if (pwScore >= 2) {
      error1 = false;
    } else {
      error1 = true;
    }
    if (pw1 === pw2 && pw1) {
      error2 = false;
    } else {
      error2 = true;
    }
    if (!error1 && !error2) {
      password = pw1;
    } else {
      password = null;
    }
    this.setState({
      password,
      pwScore: pw1.length===0?-1:pwScore,
      error: {
        ...this.state.error,
        password1: error1,
        password2: error2
      },
      style: {
        ...this.state.style,
        password1: error1 ? "has-error" : "has-success",
        password2: error1 ? '' : error2 ? "has-error" : "has-success"
      }
    });
    this.props.onChange(password, this.props.name);
  }
};

PasswordInputs.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  withoutLabel: PropTypes.bool,
  name: PropTypes.string
};

export default injectIntl(PasswordInputs);