import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getMessages } from "./internalization";

// pages
import Login from "./Login";
import Dashboard from "./Dashboard";
import SignInMail from "./SignInMail";
import SignInMobile from "./SignInMobile";
import SignUpMail from "./SignUpMail";
import SignUpMobile from "./SignUpMobile";
import SignUpSocial from "./SignUpSocial";
import Profile from "./page/Profile";
import ProfileEdit from "./page/ProfileEdit";
import "./styles/main.scss";
import Authenticated from "./comp/Authenticated";
import ForgottenPassword from "./ForgottenPassword";
import SetNewPassword from "./SetNewPassword";
import UpgradeAccount from "./page/UpgradeAccount";
import PaymentSuccess from "./page/PaymentSuccess";
import ExtInvitation from "./page/ExtInvitation";
import FriendsAndBalls from "./page/FriendsAndBalls";
import RatingsAndRankings from "./page/rankings/RatingAndRankings";
import PublicProfile from "./page/PublicProfile";
import GlobalUnsubscribe from "./page/GlobalUnsubscribe";

import HeaderExt from "./comp/HeaderExt";
// en pages

import "react-toastify/dist/ReactToastify.css";
import Toast from "./comp/Toast";
import Footer from "./comp/Footer.js";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://cfc7aade090944029d3a2fe357309d8a@o416761.ingest.sentry.io/5977375",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


const Page404 = () => (
  <div>
    <HeaderExt />
    <div className="container content">
      <div className="title-upgrade text-center">
        <p>
          <FormattedMessage id="WA.Page.404" />
        </p>
        <h1>
          <FormattedMessage id="WA.Page.404" />
        </h1>
      </div>
    </div>
  </div>
);

//const trackingId = "UA-74488550-3"; // Replace with your Google Analytics tracking ID
//ReactGA.initialize(trackingId);
//const history = createBrowserHistory();

// Initialize google analytics page view tracking
//history.listen(location => {
//  ReactGA.set({ page: location.pathname }); // Update the user's current page
//  ReactGA.pageview(location.pathname); // Record a pageview for the given page
//});

class App extends Component {
  constructor(props) {
    super(props);
    this.store = props.store;
  }

  render() {
    if (!this.props.language) return <p style={{ color: "white" }}>Loading...</p>; // we should wait unti the applicationPreferences from store
    return (
      <IntlProvider
        locale={this.props.language}
        key={this.props.language}
        messages={getMessages(this.props.language.toLowerCase())}>
        <Provider store={this.store}>
          {/*<Router history={history}>*/}
          <Router>
          <>
              <div id="main-content">
                <Toast />
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/signinmail" exact component={SignInMail} />
                  <Route path="/signinmobile" exact component={SignInMobile} />
                  <Route path="/signupmail" exact component={SignUpMail} />
                  <Route path="/signupmobile" exact component={SignUpMobile} />
                  <Route path="/signupsocial" exact component={SignUpSocial} />
                  <Route path="/forgottenpassword" component={ForgottenPassword} />
                  <Route path="/setNewPassword/:token" exact component={SetNewPassword} />
                  <Route path="/extinv/:gameId/:token" exact component={ExtInvitation} />
                  <Route path="/unsub" exact component={GlobalUnsubscribe} />
                  <Route path="/publicProfile/:code" exact component={PublicProfile} />
                  {/* <Route path="/logout" exact component={Logout} /> */}
                  <Authenticated path="/" exact component={Dashboard} />
                  <Authenticated path="/profile" exact component={() => <Profile success={false} />} />
                  <Authenticated path="/profile/success" exact component={() => <Profile success={true} />} />
                  <Authenticated path="/profile/edit" exact component={ProfileEdit} />
                  <Authenticated path="/email/validate" exact component={ProfileEdit} />
                  <Authenticated path="/upgradeAccount" exact component={UpgradeAccount} />
                  <Authenticated path="/paymentSuccess" exact component={PaymentSuccess} />
                  <Authenticated path="/friendsAndBalls" exact component={FriendsAndBalls} />
                  <Authenticated path="/ratingsAndRankings" exact component={RatingsAndRankings} />
                  <Route component={Page404} />
                </Switch>
              </div>
              <Route component={Footer} />
            </>
          </Router>
        </Provider>
      </IntlProvider>
    );
  }

  componentDidCatch(error, errorInfo) {
    this.props.showToast(error);
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  language: state.appConfig ? state.appConfig.language : null,
  user: state.userData,
  loggedIn: state.loggedIn
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showToast: data => dispatch({ type: "SHOW_TOAST", data })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
