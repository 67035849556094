import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

class ProfileBox extends Component {

  render() {
    return (
      <div className="box-info">
        {this.props.value ?
          <p>
            {this.props.value}
          </p>
          :
          <FormattedMessage id={this.props.valueKey} tagName="p" />
        }
        <p className="header">
          <FormattedMessage id={this.props.titleKey} />
        </p>
        {this.props.children}
      </div>
    );
  }
}

ProfileBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ProfileBox);