import { initialState } from "./store";
import { getLocale } from "./internalization";
//import ReactGA from 'react-ga';

const actionStates = (state, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        loading: true
      };
    case "STOP_LOADING":
      return {
        ...state,
        loading: false
      };      
    case "GET_SERVER_TOKEN_SUCCEEDED":
      sessionStorage.setItem("tokenData", JSON.stringify(action.data));
      return {
        ...state,
        serverToken: action.data,
        loggedIn: true,
        loginError: null
      };
    case "GET_SERVER_TOKEN_FAILED":
      return {
        ...state,
        serverToken: null,
        loggedIn: false,
        loginError: action.error
      };
    case "SAVE_SOCIAL_DATA":
      return {
        ...state,
        user: action.data
      };
    case "GET_CONFIG_SUCCEEDED":
      return {
        ...state,
        config: action.data
      };
    case "GET_APP_DATA_SUCCEEDED": {
      if(!action.data["language"]){
        action.data.language = getLocale();
      }
      return {
        ...state,
        appConfig: action.data
      };
    }
    case "SET_LANGUAGE":{
      return {
        ...state,
        appConfig: {
          ...state.appConfig,
          language: action.data
        }
      }
    }
    case "SET_DEFAULT_APP_CONFIG": {
      return {
        ...state,
        appConfig: {
          language: getLocale(),
          dateFormat: getLocale(),
          timeFormat: getLocale()
        }
      };
    }
    case "LOGOUT_SUCCEEDED":
      // keep app config
      let s = { ...initialState };
      s.config = state.config;
      return { ...s };
    case "SIGNUP_REQUEST":
      return {
        ...state,
        signUpSuccess: false,
        signUpError: null
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        signUpSuccess: true
      };
    case "SIGNUP_FAILED":
      return {
        ...state,
        signUpError: action.error
      };
    case "GET_USERDATA_SUCCEEDED":
//      ReactGA.set({
//        userId: action.data.code, 
//      })
      return {
        ...state,
        userData: action.data
      };
    case "SHOW_TOAST":
      return {
        ...state,
        toast: action
      };
    default:
      return state;
  }
};
export default function(state, action) {
  const newState = actionStates(state, action);
  var s = {
    ...newState,
    loading: newState.loading || action.type.match(/.*_REQUEST/g) !== null
  };
  return s;
}
