import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { getConfig, getLanguage } from "./resource";
import { store } from "./store";

  // eslint-disable-next-line
  Date.prototype.addHours = function(h) {    
    this.setTime(this.getTime() + (h*3600000)); 
    return this;   
  };


getConfig()
  .then(response => {
    store.dispatch({ type: "GET_CONFIG_SUCCEEDED", data: response.data });
    getLanguage(response.data.webTranslationURL)
      .then(langData => {
        window.ipm2Lang = langData.data;
        ReactDOM.render(<App store={store} />, document.getElementById("root"));
      })
      .catch(error => {
        alert("No network connection. Please check you network links!");
      });
    //window.initScript(response.data.googleApiKey);
  })
  .catch(error => {
    alert("Network connection problem. Unable to reach the server. Please check you network links!");
  });
