import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

class InputRadio extends Component {

  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
  }

  render() {
    return (
      <>
        {this.props.options.map(option =>
          <div className="radio" key={option.value}>
            <label>
              <input 
                type="radio" 
                name={this.props.name} 
                value={option.value} 
                checked={option.value === this.props.value}
                onChange={this.changed} 
              />
              <FormattedMessage id={option.labelKey} />
            </label>
          </div>
        )}
      </>
    );
  }

  changed(e) {
    this.props.onChange(e.target.value, this.props.name);
  }
}

InputRadio.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default injectIntl(InputRadio);