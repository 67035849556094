import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link, Redirect } from "react-router-dom";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import PasswordInputs from './comp/PasswordInputs.js';
import { setNewPassword } from './resource';


class SetNewPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token,
      password: "",
      error: {
        password: true,
      },
      success: null
    }

    this.passwordChanged = this.passwordChanged.bind(this);
    this.submit = this.submit.bind(this);
  }

  render() {
    if (this.state.success) {
      return <Redirect to="/login" />;
    }
    return (
      <div>

        <HeaderOnBoarding />

        <div className="container content-login">
          <div className="row">

            <div className="col-xs-12 col-md-8 col-md-offset-2">

              <div className="wrapperOnboard text-center">
                <div className="boxTitle">
                  <h1 className="h3"><FormattedMessage id="WA.SetNewPassword" /></h1>
                </div>
              </div>

              <div className="login-info">
                <form onSubmit={this.submit}>
                  <PasswordInputs onChange={this.passwordChanged} />
                  <div className={"form-group has-feedback " + (!this.state.forgottenPasswordError ? "has-error" : "has-success")}>
                    <input
                      type="submit"
                      className="btn-gral verde form-control"
                      value={this.props.intl.formatMessage({ id: 'WA.FB.Send' })}
                    />
                    {this.state.success === false ?
                      <span className="help-block has-error">
                        <FormattedMessage id="WA.PasswordFormationError" />
                        &nbsp;
                        <Link to="/forgottenpassword">
                          <FormattedMessage id="WA.Landing.ForgotYourPassword?" />
                        </Link>
                      </span>
                      : null
                    }
                  </div>
                </form>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {

  }

  passwordChanged(newPassword) {
    this.setState({
      password: newPassword,
      error: {
        ...this.state.error,
        password: !newPassword
      }
    })
  }

  async submit(event) {
    event.preventDefault();
    for (let key in this.state.error) {
      if (this.state.error[key]) {
        return;
      }
    }

    try {
      await setNewPassword(this.state.token, this.state.password);
      this.setState({
        success: true
      });
    } catch (e) {
      this.setState({
        success: false
      });
    }
  }
}

SetNewPassword.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SetNewPassword);