import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import Modal from '../comp/Modal';
import PropTypes from "prop-types";
import LoadingSVG from "../images/loading.gif";

class ErrorModal extends Component {
  render() {
    return <Modal show={this.props.errorMessage} onClose={(e) => this.props.onClose()} minHeight="50px" padding="10px" paddingTop="0px" >
      {this.props.children ?
        this.props.children :
        <>
          <p style={{ padding: '1em' }}>
            <i class="fas fa-exclamation-circle error"></i> {this.props.errorMessage}
          </p>
        </>
      }
      {this.props.onConfirm ?
        <>
          <p>
            <button type="button" disabled={this.props.loading} class="confirm-button simple-button" onClick={(e) => this.props.onConfirm()}><div>{this.props.loading ? <img src={LoadingSVG} alt="check"/>:<i class="fas fa-check"></i>}</div><FormattedMessage id={this.props.confirmAnchor} /></button>
            <button type="button" disabled={this.props.loading} class="cancel-button simple-button" onClick={(e) => this.props.onClose()}><FormattedMessage id={this.props.cancelAnchor} /></button>
          </p>
        </>
        :
        <p>
          <button type="button" class="confirm-button simple-button" onClick={(e) => this.props.onClose()}><FormattedMessage id="WA.Button.GotIt" /></button>
        </p>
      }
    </Modal>
  }
}
ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  errorMessage: PropTypes.string,
  confirmAnchor: PropTypes.string,
  cancelAnchor: PropTypes.string,
};

export default ErrorModal;