import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from 'lodash';
import { getMinutesByTime, getTimeByMinutes, timeToHStyle, parseDate } from "../utils";
import { connect } from "react-redux";


class GameCalendar extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      open: props.open,
      selectedDate: null,
      intervalls: null,
      day: null,
      selected: [],
      allSelectedDate: []
    };

    let language = props.intl.locale;
    if (window.navigator.language.length === 5) {
      language = language + "-" + window.navigator.language.substring(3, 5);
    }
    this.formatterDay = new Intl.DateTimeFormat(language, { weekday: "long" });
    this.formatterDate = new Intl.DateTimeFormat(language, { day: "numeric", month: "long" });
    this.formatterTemplate = new Intl.DateTimeFormat(language, { hour: 'numeric' });

    this.calendarHover = this.calendarHover.bind(this);
    this.calendarOut = this.calendarOut.bind(this);
    this.onSelectIntervall = this.onSelectIntervall.bind(this);
  };

  calcIntervall(fromS, toS) {
    let intervall = 30; // 30 mins is the distance

    let period = [fromS, toS];
    let minutes = period.map((time) => {
      return getMinutesByTime(time);
    });
    let from = minutes[0];
    let to = minutes[1];
    let results = [];
    if ((from % 60) !== 0) {
      results.push({ from: from - 30, active: false });
    }

    while (from + intervall <= to) {
      results.push({ from, active: true });
      from += intervall;
    }
    if (results.length % 2 !== 0) {
      let prevMinutes = results[results.length - 1].from;
      results.push({ from: prevMinutes + 30, active: false });
    }
    return results;
  }

  getSiblings(target, reqSiblingCount, next) {
    if (target.classList.contains("inactive")) {
      return [];
    }
    let siblings = [];
    siblings.push(target);
    let current = target;
    for (let i = 1; i < reqSiblingCount; i++) {
      let curMinute = parseInt(current.dataset.minute);
      let sibling = current.nextSibling
      if (!next) {
        sibling = current.previousSibling;
      }

      if (sibling) {
        if (!sibling.dataset) {
          sibling = sibling.parentNode;
        }
        let siblingMinute = parseInt(sibling.dataset.minute);
        console.log("siblingMinute:" + siblingMinute);
        let inactive = sibling.classList.contains("inactive");

        let siblingMinuteCalc = curMinute + 30;
        if (!next) {
          siblingMinuteCalc = curMinute - 30;
        }

        if (siblingMinuteCalc === siblingMinute && !inactive) {
          siblings.push(sibling);
          current = sibling;
        } else {
          break;
        }
      } else {
        break;
      }
    }
    return siblings;
  }

  calendarHover(event) {
    event.preventDefault();
    if (!this.props.readOnly) {
      let itemToLighten = this.getItemsToLighten(event.target);
      itemToLighten.forEach((target) => {
        if (!target.classList.contains("half-hour-selected")) {
          target.classList.add("half-hour-selected")
        }
      });
    }
  }
  calendarOut(event) {
    event.preventDefault();
    if (!this.props.readOnly) {
      let calendarSelected = this.getSelectedCalendars(this.props.calendar);

      let selectedStartMinutes = this.getFromMinutes(calendarSelected);

      let selectedMinutes = this.getSelectedMinutes(selectedStartMinutes);

      let itemsToLighten = this.getItemsToLighten(event.target);
      itemsToLighten.forEach((target) => {
        if (selectedMinutes.indexOf(parseInt(target.dataset.minute)) === -1) {
          target.classList.remove("half-hour-selected");
        }
      });
    }
  }
  getItemsToLighten(node, found = []) {
    let reqSiblingCount = this.props.duration / 30;
    let itemsToLighten = this.getSiblings(node, reqSiblingCount, true);
    if (itemsToLighten.length === reqSiblingCount) {
      return itemsToLighten;
    }
    itemsToLighten = this.getSiblings(node, reqSiblingCount, false);
    if (itemsToLighten.length === reqSiblingCount) {
      return itemsToLighten.reverse();
    }
    if (node.nextSibling && node.nextSibling.dataset) {
      if(found.indexOf(node.nextSibling) === -1){
        found.push(node.nextSibling);
        return this.getItemsToLighten(node.nextSibling, found);
      }
    }
    if (node.previousSibling && node.previousSibling.dataset) {
      if(found.indexOf(node.previousSibling) === -1){
        found.push(node.previousSibling);
        return this.getItemsToLighten(node.previousSibling, found);
      }
    }
    return [];
  }
  selectAllSlots(currentDate, target) {
    //if (target.classList.contains("inactive")) {
//      return [];
    //}
    const siblings = [];
    if (!target.dataset) {
      target = target.parentNode;
    }

    const reqSiblingCount = this.props.duration / 30;
    // let itemsToLighten = this.getSiblings(target, reqSiblingCount, true);
    // if(itemsToLighten.length>0){
    //   siblings.push(target);
    // }
    let current = {nextSibling: target};
    while (current.nextSibling) {
      let sibling = current.nextSibling
      if (sibling) {
        let itemsToLighten = this.getSiblings(sibling, reqSiblingCount, true);
        if(itemsToLighten.length === reqSiblingCount){
          let inactive = sibling.classList.contains("inactive");
          if (inactive) {
            current = sibling;
            continue;
          }
          let siblingMinute = parseInt(sibling.dataset.minute);
          console.log("siblingMinute:" + siblingMinute);
          siblings.push(sibling);
        }
      }
      current = sibling;
    }
    let calendarStructure = siblings.map(box => {
      const minute = box.dataset.minute;
      let end = parseInt(minute) + parseInt(this.props.duration);
      let fromTime = getTimeByMinutes(minute);
      let toTime = getTimeByMinutes(end);
      let selectedInterval = {
        date: this.props.calendar[0].date,
        fromTime,
        toTime,
        invitationId: 'id'
      };
      return selectedInterval;
    });
    let allDate = this.state.allSelectedDate;
    if (allDate.indexOf(currentDate) > -1) {
      calendarStructure = calendarStructure.filter(interval => interval.date !== currentDate);
      allDate = this.state.allSelectedDate.filter(time => time !== currentDate)
    } else {
      allDate.push(currentDate);
    }
    this.setState({ ...this.state, allSelectedDate: allDate })
    this.props.onSelect(calendarStructure);
  }
  onSelectIntervall(event) {
    event.preventDefault();

    if (!this.props.readOnly) {
      let clickTarget = event.target;
      let tagName = clickTarget.tagName.toUpperCase();
      if (tagName === "SUP") {
        clickTarget = clickTarget.parentNode;
      }

      let itemToLighten = this.getItemsToLighten(clickTarget);

      if (itemToLighten.length === 0) {
        return;
      }
      let selectedMinutes = [];
      if (!this.props.single) {
        let calendarSelected = this.getSelectedCalendars(this.props.calendar);
        selectedMinutes = this.getFromMinutes(calendarSelected);
      }
      let start = itemToLighten[0].dataset.minute || itemToLighten[0].parentNode.dataset.minute;
      let poz = selectedMinutes.indexOf(parseInt(start));
      if (poz !== -1) {
        selectedMinutes.splice(poz, 1);
      } else {
        selectedMinutes.push(start);
      }
      let calendarStructure = selectedMinutes.map(minute => {
        let end = parseInt(minute) + parseInt(this.props.duration);
        let fromTime = getTimeByMinutes(minute);
        let toTime = getTimeByMinutes(end);
        let selectedInterval = {
          date: this.props.calendar[0].date,
          fromTime,
          toTime,
          invitationId: 'id'
        };
        return selectedInterval;
      });

      this.props.onSelect(calendarStructure);
    }
  }

  getFromMinutes(calendar) {
    let minutes = [];
    calendar.forEach(cal => {
      minutes.push(getMinutesByTime(cal.fromTime));
    });
    return minutes;
  }
  getSelectedCalendars(calendar) {
    let calendarSelected = calendar.filter(item => {
      return !!item.invitationId;
    });
    return calendarSelected;
  }

  getSelectedMinutes(selectedStartMinutes) {
    let selectedMinutes = [];
    let nrOfHalfHours = this.props.duration / 30;
    selectedStartMinutes.forEach(min => {
      selectedMinutes.push(min);
      for (let i = 1; i < nrOfHalfHours; i++) {
        selectedMinutes.push(min + (i * 30));
      }
    });
    return selectedMinutes;
  }
  render() {

    let calendar = _.orderBy(this.props.calendar, "fromTime");

    let minutes = [];

    calendar.filter(cal => !cal.invitationId).forEach(cal => {
      let m = this.calcIntervall(cal.fromTime, cal.toTime);
      if (minutes.length > 0) {
        minutes.push({ from: '...', active: false, placeHolder: true })
      }
      minutes = minutes.concat(m);
    });

    let calendarSelected = this.getSelectedCalendars(calendar);

    let selectedStartMinutes = this.getFromMinutes(calendarSelected);

    let selectedMinutes = this.getSelectedMinutes(selectedStartMinutes);

    const currDate = parseDate(calendar[0].date);
    let day = this.formatterDate.format(currDate)
    let dayOfWeek = this.formatterDay.format(currDate)


    let slots = [];

    const templateDateAMPM = this.formatterTemplate.format(new Date());
    const isAMPM = templateDateAMPM.indexOf("AM") !== -1 || templateDateAMPM.indexOf("PM") !== -1;

    let i = 0;
    while (i < minutes.length) {
      let fromHour = minutes[i];
      let selectedHour = selectedMinutes.indexOf(fromHour.from) !== -1;
      let selectedHalfHour = selectedMinutes.indexOf(fromHour.from + 30) !== -1;
      let selectedFirstHour = selectedStartMinutes.indexOf(fromHour.from) !== -1;
      let selectedFirstHalfHour = selectedStartMinutes.indexOf(fromHour.from + 30) !== -1;
      if (fromHour.placeHolder) {
        slots.push(
          <div className="hour inactive">
            {fromHour.from}
          </div>
        )
        i += 1;
      } else {
        let fromHalfHour = minutes[i + 1];
        const hour = parseInt(fromHour.from / 60);
        slots.push(
          <>
            <div key={fromHour.from} className={`hour ${!fromHour.active ? 'inactive' : ''} ${selectedHour ? 'half-hour-selected' : ''} ${selectedFirstHour ? 'first-selected' : ''}`} data-minute={fromHour.from} data-date={calendar[0].date}
              onMouseOver={this.calendarHover} onMouseOut={this.calendarOut} onClick={this.onSelectIntervall}>
              {isAMPM ?
                <>{hour > 12 ? hour - 12 : hour}<span className="am-pm">{hour > 12 ? 'PM' : 'AM'}</span></>
                :
                <>{hour}<span className="am-pm">&nbsp;</span></>
              }
            </div>
            <div key={fromHour.from + 30} className={`half-hour ${!fromHalfHour.active ? 'inactive' : ''} ${selectedHalfHour ? 'half-hour-selected' : ''} ${selectedFirstHalfHour ? 'first-selected' : ''}`} data-minute={fromHour.from + 30} data-date={calendar[0].date}
              onMouseOver={this.calendarHover} onMouseOut={this.calendarOut} onClick={this.onSelectIntervall}>
              30
            </div>
          </>
        );
        i += 2;
      }
    }

    const onSelectAll = (event) => {
      event.preventDefault();
      const firstNode = document.getElementById(calendar[0].date);
      this.selectAllSlots(calendar[0].date, firstNode.firstChild);
    }
    const allSelectedEnabled = this.state.allSelectedDate.indexOf(calendar[0].date) > -1;
    return (
      <div class="calendar-day">
        <p class="day">{dayOfWeek}, {day}</p>
        <div className="selected-range">
          {calendarSelected.map(selected => {
            let horario = timeToHStyle(selected.fromTime, isAMPM) + " - " + timeToHStyle(selected.toTime, isAMPM) + " "
            return <span className='selected-hour'>{horario}</span>
          })}
        </div>
        <p class="subtitles">
          <button onClick={onSelectAll} class={`select-all-button ${allSelectedEnabled&&'selected'}`}><i class="fas fa-check-double"/>&nbsp;<FormattedMessage id="WA.SelectAll" /></button>
          <FormattedMessage id="WA.AvailableTimes" />
        </p>
        <div id={calendar[0].date}>
          {slots}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData || {},
    countryCode: state.config.countryCode,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GameCalendar)
);



