import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class InputCheckbox extends Component {

  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
  }

  render() {
    return (
      <>
        {this.props.options.map(opt => (
          <div className="col-sm-4" key={opt.value}>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  value={opt.value}
                  checked={this.props.values.includes(opt.value)}
                  onChange={this.changed}
                />
                <FormattedMessage id={opt.labelKey} />
              </label>
            </div>
          </div>
        ))}
      </>
    );
  }

  changed(e) {
    const value = e.target.value;
    
    if (e.target.checked && !this.props.values.includes(value)) {
      this.props.onChange(
        [
          ...this.props.values,
          value
        ],
        this.props.name
      );
    } else if (!e.target.checked && this.props.values.includes(value)) {
      this.props.onChange(
        this.props.values.filter(e => e !== value),
        this.props.name
      );
    }
  }
}

InputCheckbox.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default injectIntl(InputCheckbox);