import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { getGameReport, setScoreGameAction, setPartnerAction } from "./resource.js";
import Header from "./comp/Header.js";

import iconScores from "./images/icon-scores.png";
import appStoreBadge from "./images/app-store_badge.png";
import googleStoreBadge from "./images/google_play_badge.png";
import iconMagnetEmpty from "./images/icon-magnet-empty.png";
import myMatchesExample from "./images/my-matches-example.jpg";
import GameSummaryRule from "./types/GameSummaryRule";


import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import GameWithScore from "./comp/GameWithScore.js";
import GamePending from "./comp/GamePending.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.fm = props.intl.formatMessage;
    this.state = {
      loading: true,
      games: [],
      oriGames: [],
      validScoreList: {},
      gamePartnerSelection: {},
      order: {
        field: 'summary.date',
        desc: true
      },
      archiveGames: false
    };
    this.gameSummaryRule = new GameSummaryRule();
    this.searchPlayer = this.searchPlayer.bind(this);
    this.queryDashboardData.bind(this);
    this.saveScore = this.saveScore.bind(this);
    this.partnerSelection = this.partnerSelection.bind(this);
  }


  componentDidMount() {
    this.queryDashboardData(this.state.archiveGames);
  }
  queryDashboardData(archiveGames) {
    getGameReport(archiveGames).then(resp => {

      // prepare dashboard data
      resp.data.games.map((game) => {
        if (!game.summary) {
          game.summary = {};
          this.gameSummaryRule.enrichSummary(game);

          //game.summary.date = game.date || _.sortBy(game.calendarPreference, 'date')[0].date;
          if (game.summary.location) {
            game.summary.locationString = game.summary.location.name;
          } else {
            game.summary.locationString = this.fm({id:"WA.UnkownLocation"});
          }

        }
        return game;
      });
      this.setState({ loading: false, games: resp.data.games, oriGames: resp.data.games, validScoreList: {}, archiveGames });
    });
  }
  orderTable(games) {
    games = _.sortBy(games, this.state.order.field);
    if (this.state.order.desc) {
      games = games.reverse();
    }
    return games;
  }
 
  searchPlayer(event) {
    event.preventDefault();
    let s = event.target.value;
    let result = this.state.oriGames.filter(item => {

      let inviteeNames = item.invitee ? item.invitee.map(i => " " + i.user.firstName + " " + i.user.lastName).join() : "";
      let searchBase = item.owner.firstName + " " + item.owner.lastName + inviteeNames;
      var r = searchBase.toLowerCase().indexOf(s.trim()) > -1;
      if (!r) {
        let ivn = item.invitee.filter(inv => {
          return (inv.type = "CONFIRMED" && (inv.user.firstName.indexOf(s) > -1 || inv.user.lastName.indexOf(s) > -1));
        });
        return (r |= ivn.length > 0);
      }
      return r;
    });
    this.setState({ ...this.state, games: result });
  }
  partnerSelection(game) {
    setPartnerAction(game.id, this.state.gamePartnerSelection[game.id]).then((response) => {
      this.queryDashboardData(this.state.archiveGames);
    });
  }

  saveScore(game) {
    if (!game.gameSet || game.gameSet.length === 0) return;
    game.gameSet.splice(-1, 1);
    setScoreGameAction(game.id, { sets: game.gameSet, winner: game.winner }).then(resp => {
      this.queryDashboardData(this.state.archiveGames);
    });
  }

  render() {
    if (this.state.loading) {
      return 'Loading...';
    }

    return (
      <div>
        <Header />
        <div className="container">
          <h1 className="title-section">
            <a href="/#" class={!this.state.archiveGames ? 'active' : ''} onClick={() => this.queryDashboardData(false)}><FormattedMessage id="WA.Title.MyMatches" /></a> | <a href="/#" class={this.state.archiveGames ? 'active' : ''} onClick={() => this.queryDashboardData(true)}><FormattedMessage id="WA.Title.PastMatches" /></a>
          </h1>
        </div>
        <div className="container">
          {this.state.oriGames.length > 0 ? (
            <div className="wrapper-matches">
              <div className="wrapper-filters">
                <div className="filters">
                  <div className="box-filter">
                    <input
                      type="text"
                      className="search"
                      placeholder={this.fm({ id: "WA.Title.SearchPlayer" })}
                      onChange={this.searchPlayer}
                    />
                  </div>
                </div>
                {/* filters */}
              </div>
              <div className="table-mathes">
                <div className="table-top">
                  <div className="table-top-titles">
                    <a href="/#" className="white" onClick={(e) => this.setState({ ...this.state, order: { field: "summary.date", desc: !this.state.order.desc } })}>
                      <FormattedMessage id="WA.Title.Dates" />
                    </a>
                    {this.state.order.field === "summary.date" &&
                      <a href="/#" className="right white mg-right-1em" onClick={(e) => this.setState({ ...this.state, order: { field: "summary.date", desc: !this.state.order.desc } })}>
                        {this.state.order.desc ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}
                      </a>
                    }
                  </div>
                  <div className="table-top-titles score">
                    <FormattedMessage id="WA.Title.Scores" />
                  </div>
                  <div className="table-top-titles">
                    <a href="/#" className="white" onClick={(e) => this.setState({ ...this.state, order: { field: "summary.location", desc: !this.state.order.desc } })}>
                      <FormattedMessage id="WA.Title.Locations" />
                    </a>
                    {this.state.order.field === "summary.location" &&
                      <a href="/#" className="right white mg-right-1em" onClick={(e) => this.setState({ ...this.state, order: { field: "summary.location", desc: !this.state.order.desc } })}>
                        {this.state.order.desc ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}
                      </a>
                    }
                  </div>
                </div>
                <div className="table">{this.state.games && 
                this.orderTable(this.state.games).map( (game) => {
                  if(this.state.archiveGames){
                    return <GameWithScore game={game} user={this.props.user} state={this.state} changeState={(s)=> this.setState(s)} saveScore={this.saveScore}/>;
                  }else{
                    return <GamePending game={game} user={this.props.user} state={this.state} changeState={(s)=> this.setState(s)} saveScore={this.saveScore}/>;
                  }
                  
                })
                }</div>
              </div>
            </div>
          ) : (
              <div className="wrapper-matches-empty">
                <div className="icon-score"><img className="img-responsive" src={iconScores} alt="" /></div>
                <FormattedHTMLMessage id="WA.Dashboard.NoMatch" />
                <div className="available">
                  <p><FormattedMessage id="WA.Dashboard.Available" /></p>
                  <div className="avalible-btn">
                    <a href="https://itunes.apple.com/uy/app/iplayme2/id1227762283" target="_blank" rel="noopener noreferrer"><img src={appStoreBadge} alt="" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.iplayme2&amp;hl=en_US" target="_blank" rel="noopener noreferrer"><img src={googleStoreBadge} alt="" /></a>
                  </div>
                </div>
                <img className="img-responsive matches-example" src={myMatchesExample} alt="" />
              </div>
            )}
        </div>
        <img src={iconMagnetEmpty} style={{ display: 'none' }} alt="" />

      </div>
    );
  }
}

Dashboard.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
