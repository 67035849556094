import React from "react";

export default () => (
    <div className="color-bars">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  );

