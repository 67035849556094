import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class InputText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
    if (props.validator) {
      this.validator = this.props.validator;
    } else {
      this.validator = this.validator;
    }
    this.handleInput = this.handleInput.bind(this);
    if(this.props.onValidated){
      this.valid = this.props.valid.bind(this);
      this.notValid = this.props.notValid.bind(this);
    }
  }

  validator(value, name) {
    return !!value && value.length > 1;
  }

  handleInput(e) {
    const value = e.target.value;
    this.setState({ value });
  }

  componentDidUpdate(prevProps, prevState) {
    let result = this.validator(this.state.value, this.props.name);
    if (prevState.value !== this.state.value) {
      this.props.onChange(this.state.value, this.props.name);
      if(this.props.onValidated){
        this.props.onValidated({ [this.props.name]: !result });
        if (result) {
          this.valid();
        } else {
          this.notValid();
        }
      }
    }
  }

  render() {
    return (
      <>
        {this.props.label ? 
          <label>
            <FormattedMessage id={this.props.label} />
          </label>
          :
          null
        }
        <input
          className={this.props.className}
          placeholder={this.props.intl.formatMessage({ id: this.props.placeholder })}
          onChange={this.handleInput}
          value={this.props.value || this.state.value}
          name={this.props.name}
        />
      </>
    );
  }
}

InputText.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidated: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired
};

export default injectIntl(InputText);
