import GameStyle from "../types/GameStyle";
import MatchType from "../types/MatchType";
import { minBy } from "lodash";
import { formatUserName } from "../utils";
import _ from 'lodash';

class GameSummaryRule {
  getSummaryLocation = (game) => {
    if (!!game.location) {
      return game.location
    }
    if (!!game.locationPreference) {
      const possibleSelections = game.locationPreference.filter(
        loc => !loc.invitationId,
      )
      if (possibleSelections.length === 1) {
        return possibleSelections[0].location
      }
    }
    return undefined
  }

  constructor(props, context) {
    const gameStyle = new GameStyle();
    this.SINGLE = {
      CONFIRMED: {
        OPPONENT_INVITEE: [],
        OWNER: [],
        SHARE_INVITEE: []
      },
      SCHEDULED: {
        OPPONENT_INVITEE: [],
        OWNER: []
      }
    };
    this.OPENDOUBLE = {
      CONFIRMED: {
        OPPONENT_INVITEE: [],
        OWNER: []
      },
      SCHEDULED: {
        OPPONENT_INVITEE: [],
        OWNER: []
      }
    }

    this.SINGLE.CONFIRMED.SHARE_INVITEE[0] = { anchor: 'GS.S.confirmed.invitee.pending', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => true };
    this.SINGLE.CONFIRMED.OPPONENT_INVITEE[0] = { anchor: 'GS.S.confirmed.invitee.pending', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && (_invitation.status === 'PENDING' || _invitation.status === 'SENT') && _gamePreferences.opponentsSelection === 'FIX' };
    this.SINGLE.CONFIRMED.OPPONENT_INVITEE[1] = { anchor: 'GS.S.confirmed.invitee.pending', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && (_invitation.status === 'PENDING' || _invitation.status === 'SENT') && _gamePreferences.opponentsSelection === 'OPENCHALLENGE' };
    this.SINGLE.CONFIRMED.OPPONENT_INVITEE[2] = { anchor: 'GS.S.confirmed.invitee.accepted', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && _invitation.status === 'ACCEPTED' };
    this.SINGLE.CONFIRMED.OPPONENT_INVITEE[3] = { anchor: '', icon: gameStyle.FLAT, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && _invitation.status === 'CONFIRMED' };
    this.SINGLE.CONFIRMED.OWNER[0] = { anchor: 'GS.S.confirmed.invitee.accepted.inv.0', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => _nrOfInvitationAccepted === 0 };
    this.SINGLE.CONFIRMED.OWNER[1] = { anchor: 'GS.S.confirmed.invitee.accepted.inv.not.0', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => _nrOfInvitationAccepted > 0 };
    this.SINGLE.SCHEDULED.OPPONENT_INVITEE[0] = { anchor: 'GS.S.scheduled.invitee', icon: gameStyle.SETTLED, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => true };
    this.SINGLE.SCHEDULED.OWNER[0] = { anchor: 'GS.S.scheduled.owner', icon: gameStyle.SETTLED, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => true };
    this.OPENDOUBLE.CONFIRMED.OPPONENT_INVITEE[0] = { anchor: 'GS.OD.confirmed.invitee.pending', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && (_invitation.status === 'PENDING' || _invitation.status === 'SENT') && _gamePreferences.opponentsSelection === 'FIX' };
    this.OPENDOUBLE.CONFIRMED.OPPONENT_INVITEE[1] = { anchor: 'GS.OD.confirmed.invitee.pending', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && (_invitation.status === 'PENDING' || _invitation.status === 'SENT') && _gamePreferences.opponentsSelection === 'OPENCHALLENGE' };
    this.OPENDOUBLE.CONFIRMED.OPPONENT_INVITEE[2] = { anchor: 'GS.OD.confirmed.invitee.accepted.lmc', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && _invitation.status === 'ACCEPTED' && _gamePreferences.candidateMethod === 'CHOOSE' };
    this.OPENDOUBLE.CONFIRMED.OPPONENT_INVITEE[3] = { anchor: 'GS.OD.confirmed.invitee.accepted.fcfs', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && _invitation.status === 'ACCEPTED' && _gamePreferences.candidateMethod === 'FIRST' };
    this.OPENDOUBLE.CONFIRMED.OPPONENT_INVITEE[4] = { anchor: 'GS.OD.confirmed.invitee.confirmed', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => !!_invitation && _invitation.status === 'CONFIRMED' };
    this.OPENDOUBLE.CONFIRMED.OWNER[0] = { anchor: 'GS.OD.confirmed.owner.accepted.inv.0', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => _nrOfInvitationAccepted === 0 };
    this.OPENDOUBLE.CONFIRMED.OWNER[1] = { anchor: 'GS.OD.confirmed.owner.accepted.inv.not.0.lmc', icon: gameStyle.ACTION, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => _nrOfInvitationAccepted > 0 && _gamePreferences.candidateMethod === 'CHOOSE' };
    this.OPENDOUBLE.CONFIRMED.OWNER[2] = { anchor: 'GS.OD.confirmed.owner.accepted.inv.not.0.fcfs', icon: gameStyle.PENDING, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => _nrOfInvitationAccepted > 0 && _gamePreferences.candidateMethod === 'FIRST' };
    this.OPENDOUBLE.SCHEDULED.OPPONENT_INVITEE[0] = { anchor: 'GS.OD.scheduled.invitee', icon: gameStyle.SETTLED, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => true };
    this.OPENDOUBLE.SCHEDULED.OWNER[0] = { anchor: 'GS.OD.scheduled.owner', icon: gameStyle.SETTLED, condition: (_invitation, _gamePreferences, _nrOfInvitationAccepted) => true };
  };
  enrichSummary(game) {
    game.summary.location = this.getSummaryLocation(game)
    if(game.date || (game.calendarPreference && game.calendarPreference.length>0)){
      game.summary.date = game.date || _.sortBy(game.calendarPreference, 'date')[0].date;
    }

    game.summary.inviteeCount = game.invitee.filter(
      inv => inv.status === 'ACCEPTED',
    ).length
    game.summary.opponentCount = game.invitee.filter(
      inv => inv.status === 'CONFIRMED',
    ).length
    game.summary.owner = formatUserName(game.owner)
    game.summary.invitee = game.invitee
      .filter(inv => inv.status === 'ACCEPTED')
      .map(inv => formatUserName(inv.user))
      .join(' & ')

    if (!!game.calendarPreference) {
      let first = null;
      if(game.currentInvitee){
        let inviteeFiltered = game.calendarPreference.filter( (cp) => cp.invitationId === game.currentInvitee.id);
        first = minBy(
          inviteeFiltered,
          (calP) => {
            return calP.date+" "+calP.fromTime;
          },
        )
        game.summary.variousTimeslot = inviteeFiltered.length>1;
      }
      if(!first){
        let ownerFiltered = game.calendarPreference.filter( (cp) => !cp.invitationId);
        first = minBy(
          ownerFiltered,
          (calP) => {
            return calP.date+" "+calP.fromTime;
          },
        )
        game.summary.variousTimeslot = ownerFiltered.length>1;
      }
      if (first) {
        game.summary.gameDateFrom = first.date;
        game.summary.firstDate = first;
      }
      if (first && game.action.indexOf("TIME_SELECTION") === -1) {
        game.summary.gameTimeFrom = first.fromTime;
        game.summary.gameDate = first.date;
      }
    }
    if (game.userRole === 'OWNER') {
      game.summary.opponent = game.invitee
        .filter(inv => inv.status === 'CONFIRMED')
        .map(inv => formatUserName(inv.user))
        .join(' & ')
    } else {
      game.summary.opponent = formatUserName(game.owner);
      let invitee = game.invitee
        .filter(inv => inv.status === 'CONFIRMED' && inv.id !== game.currentInvitee.id)
        .map(inv => formatUserName(inv.user))
        .join(' & ');
      if (invitee !== '') {
        game.summary.opponent += invitee;
      }
    }


    const key = game.gamePreference.matchType;
    let ruleItems = this[key];
    game.summary.requiredPlayerCount = game.gamePreference.matchType === MatchType.SINGLE ? 1 : 3
    game.summary.missingOpponentsCount = game.summary.requiredPlayerCount - game.summary.opponentCount

    console.log("> game[" + game.code + "]");
    if (!!ruleItems) {
      ruleItems = ruleItems[game.status]
      if (!!ruleItems) {
        ruleItems = ruleItems[game.userRole]
        if (!!ruleItems) {
          let i = 0
          let currentRule = false
          for (i; i < ruleItems.length && !currentRule; i++) {
            currentRule = ruleItems[i].condition(
              game.currentInvitee,
              game.gamePreference,
              game.summary.inviteeCount,
            )
          }
          if (currentRule) {
            game.summary.backgroundColor = ruleItems[i - 1].bg
            game.summary.icon = ruleItems[i - 1].icon
            game.summary.title = ruleItems[i - 1].anchor
          }
        } else {
          console.log("not found game[" + game.code + "] userRole: " + game.userRole);
        }
      } else {
        console.log("not found game[" + game.code + "] status: " + game.status);
      }
    } else {
      console.log("not found game[" + game.code + "] key: " + key);
    }
  }
}

export default GameSummaryRule;


