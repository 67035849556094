import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { validate } from 'email-validator';
import InputText from './InputText';

class ProfileMainEmailBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newEmail: "",
      emails: [...props.user.emails],
      error: {
        newEmail: true
      }
    }
    this.deleteEmail = this.deleteEmail.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  render() {
    return (
      <div className="box-info mails">
        <p>
          <FormattedMessage id="WA.EditProfile.SelectMainEmail" />
        </p>
        {this.state.emails.map(email =>
          <div className="radio mails" key={email.email}>
            <label>
              {email.main ?
                <input type="radio" name="email" value={email} checked disabled />
                :
                <input type="radio" name="email" value={email} disabled />
              }
              {email.email}
            </label>
            {email.main ?
              null :
              <a href="#/" onClick={this.deleteEmail}>
                <i className="fa fa-trash" aria-hidden="true" data-email={email.email} />
              </a>
            }
          </div>
        )}

        <div className="wrapper-radio-check">
          <div className={"form-group has-feedback " + (this.state.error.newEmail ? "has-error" : "has-success")} >
            <InputText
              placeholder="WA.EditProfile.EnterNewEmail"
              name="newEmail"
              className="form-control"
              validator={validate}
              onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res }}) }}
              valid={this.noop}
              notValid={this.noop}
              onChange={this.updateValue}
            />
            <span className={"glyphicon glyphicon-" + (this.state.error.newEmail ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true" />
          </div>
          <a className="add-mail" href="/#" onClick={this.addEmail}>
            <i className="fa fa-plus-circle" aria-hidden="true" />
            &nbsp;Add
          </a>
        </div>
      </div>
    );
  }

  deleteEmail(e) {
    e.preventDefault();
    const email = e.target.dataset.email;
    const emails = this.state.emails.filter(e => e.email !== email);
    this.setState({
      emails
    });
    this.props.onChange(emails, this.props.name);
  }

  addEmail(e) {
    e.preventDefault();
    if (this.state.newEmail && 
        !this.state.error.newEmail && 
        !this.state.emails.map(e => e.email)
                          .includes(this.state.newEmail)) {
      const emails = [...this.state.emails, {
        email: this.state.newEmail,
        main: false
      }];
      this.setState({
        emails,
        newEmail: ""
      });
      this.props.onChange(emails, this.props.name);
    }
  }

  noop() {
    return true;
  }

  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }
}

ProfileMainEmailBox.propTypes = {
  name: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ProfileMainEmailBox);