import React,{Component } from 'react'
import PropTypes from 'prop-types';
import profilePic from '../images/icon-no-pic.png'
import { connect } from "react-redux";

class ProfilePic extends Component {

  render() {
    let url = this.props.url
    if(url && url.indexOf('://')===-1){
      url=this.props.baseURL+'thumb/'+url;
    }
    let classN = `profile-pic ${!!this.props.selected?'selected':''}`;
    return this.props.onClick? (<div style={{background:`url(${url || this.props.defaultProfilePic || profilePic})`}} className={classN} onClick={(e) => { 
      e.preventDefault(); this.props.onClick() 
    }} ></div>)
        : (<div style={{background:`url(${url || this.props.defaultProfilePic || profilePic})`}} className={classN}></div>);
  }
  
}

ProfilePic.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  defaultProfilePic: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
  baseURL: state.config.profilePicture
});


const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePic);
