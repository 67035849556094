import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

class InputSelect extends Component {

  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
  }

  render() {
    return (
      <div className="form-group">
        <select
          className="form-control"
          name={this.props.name}
          onChange={this.changed}
          value={this.props.value || ""}
        >
          {this.props.options.map(option =>
            <option
              value={option.value}
              key={option.label}
            >
              {option.label}
            </option>
          )}
        </select>
      </div>
    );
  }

  changed(e) {
    this.props.onChange(e.target.value, this.props.name);
  }
}

InputSelect.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default injectIntl(InputSelect);