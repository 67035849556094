import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HeaderExt from "../comp/HeaderExt";
import GameSummary from "../comp/GameSummary";
import { setAuth } from "../resource";

class ExtInvitation extends Component {
  constructor(props) {
    super(props);
    if (props.match.params.token !== 'n') {
      setAuth(props.match.params.token);
    }
  }

  render() {
    let p;
    let gameId = this.props.match.params.gameId;
    let webaccessEnabled = this.props.match.params.token === 'n';
    // parse password from gameId query param
    if (gameId.indexOf("-") !== -1) {
      p = gameId.substring(gameId.indexOf("-") + 1, gameId.length);
      gameId = gameId.substring(0, gameId.indexOf("-"));
    }
    return (
      <div>
        <HeaderExt />
        <div className="container content game-summary">
          <div className="row">
            <div className="col-sm-12">
              <GameSummary key={gameId} gameId={gameId} password={p} webaccessEnabled={webaccessEnabled}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ExtInvitation.propTypes = {
  intl: intlShape.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExtInvitation)
);
