import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import profilePic from "../images/icon-no-pic.png";
import Header from "../comp/Header.js";
import ProfileBox from "../comp/ProfileBox";
import { uploadProfilePicture } from "../resource";
import "croppie/croppie.css";
import Croppie from "croppie";
import { getTechSkills, deleteProfile } from "../resource";
import ErrorModal from '../comp/ErrorModal';
import DeblockUser from '../comp/DeblockUser';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSuccess: false,
      uploadedFile: {},
      techSkill: {},
      errorMessage: null,
      onConfirm: null,
      onClose: null
    };
    this.onImageDrop = this.onImageDrop.bind(this);
    this.clearTempPicture = this.clearTempPicture.bind(this);
    this.finishUpload = this.finishUpload.bind(this);
    this.croppie = null;
    this.fm = props.intl.formatMessage;
    this.deleteProfile = this.deleteProfile.bind(this);
  }
  componentDidMount() {
    getTechSkills().then(resp => {
      this.setState({ ...this.state, techSkill: resp.data });
    });
  }

  onImageDrop(files) {
    this.setState({
      ...this.state,
      uploadedFile: {
        file: files[0]
      }
    });
    setTimeout(() => {
      let pi = document.getElementById("croppie-panel");
      if (!pi) return;
      if (Croppie.bind) { };
      this.croppie = new window.Croppie(pi, {
        viewport: { width: 200, height: 200, type: "circle" },
        boundary: { width: 200, height: 200 },
        showZoomer: true,
        enableOrientation: true
      });
      console.debug("croppie set");
      this.croppie.bind({
        url: URL.createObjectURL(files[0]),
        orientation: 1
      });
    }, 0);
  }

  clearTempPicture() {
    this.setState({
      ...this.state,
      uploadedFile: {}
    });
    this.croppie = null;
  }

  finishUpload() {
    this.croppie.result("blob").then(blob => {
      if (blob) {
        let promise = uploadProfilePicture(blob);
        promise
          .then(result => {
            console.log("image uploaded");
            this.props.getUserData();
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  }
  deleteProfile(){
    let promise = deleteProfile();
    promise.then(response => {
      this.setState({...this.state, onConfirm:null, 
        errorMessage: this.fm({id: "WA.AccountNowDeleted"}),
        onClose: this.props.logOut
      });
    });

    promise.catch(error => {
      this.setState({...this.state, errorMessage: this.fm({id: error.response.data.message})});
      console.log(error);
    });
  }


  componentWillUpdate() {
    console.debug("component will update");
    if (this.croppie != null) {
      this.clearTempPicture();
    }
  }

  render() {
    if (!this.props.user) {
      return null;
    }
    let genderKey = "WA.UnknownProfile";
    if (this.props.user.gender) {
      genderKey = `WA.EditProfile.Gender.${this.props.user.gender}`;
    }
    let forehandKey = "WA.UnknownProfile";
    if (this.props.user.forehand) {
      forehandKey = `WA.EditProfile.ForehandStyle.${this.props.user.forehand}`;
    }
    let backhandKey = "WA.UnknownProfile";
    if (this.props.user.backhand) {
      backhandKey = `WA.EditProfile.BackhandStyle.${this.props.user.backhand}`;
    }
    return (
      <div>
        <Header />
        <div className="container">
          <h1 className="title-section">
            <FormattedMessage id="WA.ProfileHeader" />
          </h1>
        </div>
        <ErrorModal show={this.state.errorMessage} errorMessage={this.state.errorMessage} confirmAnchor="WA.ButtonDeleteMyAccountOK" cancelAnchor="WA.ButtonDeleteMyAccountCancel" onConfirm={this.state.onConfirm} onClose={(e) => this.state.onClose?this.state.onClose():this.setState({ ...this.state, errorMessage: null })} />
        <div className="container content">
          <div className="row">
            <div className="col-xs-12 col-md-5">
              <div className="box-profile">
                <div className="top-box-profile">
                  {this.state.uploadedFile.file ? (
                    <>
                      <div id="croppie-panel" />
                      <div className="img-btn-bar">
                        <i className="material-icons icon-green" onClick={this.finishUpload}>
                          done
                        </i>
                        <i className="material-icons icon-red" onClick={this.clearTempPicture}>
                          clear
                        </i>
                      </div>
                    </>
                  ) : (
                      <div className="profile-pic-big">
                        <Dropzone multiple={false} accept="image/*" onDrop={this.onImageDrop.bind(this)}>
                          <img
                            className="img-responsive"
                            src={this.props.user.profileImageUrl || profilePic}
                            alt="profile"
                          />
                          <a href="#/" className="edit-profile-pic">
                            <i className="fa fa-camera" aria-hidden="true" />
                          </a>
                        </Dropzone>
                      </div>
                    )}

                  <h1>
                    {this.props.user.firstName} {this.props.user.lastName}
                  </h1>
                  <p>{this.props.user.location}</p>
                </div>
                <div className="row items-profile">
                  <div className="col-xs-6 item-profile">
                    {this.state.techSkill.iplayme2Rating &&
                      <div className="">
                        <p><FormattedNumber value={this.state.techSkill.iplayme2Rating} intlStyle="decimal" minimumFractionDigits="1" maximumFractionDigits="1" /></p>
                        <p>
                          <span class="header">
                            <FormattedMessage id="WA.TechSkill.Iplayme2Rating" />
                          </span>
                        </p>
                      </div>
                    }
                  </div>
                  <div className="col-xs-6 item-profile">
                    {this.state.techSkill.MatchRecord &&
                      <div className="">
                        <p>{this.state.techSkill.MatchRecord}</p>
                        <p>
                        <span class="header">
                            <FormattedMessage id="WA.TechSkill.MatchRecord" />
                          </span>
                        </p>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div >
                <DeblockUser/>
              </div>
            </div>
            <div className="col-xs-12 col-md-7">
              <div className="wrapper-info-profile">
                <div className="row">
                  {this.props.success ? this.renderSuccess() : null}
                  <div className="col-xs-12 col-md-6 col-boxes-info">
                    <ProfileBox titleKey="WA.EditProfile.FirstName" value={this.props.user.firstName} />
                    <ProfileBox titleKey="WA.EditProfile.LastName" value={this.props.user.lastName} />
                    <div className="box-info mails">
                      <p className="active">{this.props.user.emails.filter(e => e.main)[0].email}</p>
                      {this.props.user.emails.filter(e => !e.main)
                        .map(email =>
                          <p key={email.email}>{email.email}</p>
                        )}
                      <p>
                        <FormattedMessage id="WA.Label.EmailAddresses" />
                      </p>
                    </div>
                    <ProfileBox titleKey="WA.Landing.Password" value="********" />
                    <ProfileBox titleKey="WA.EditProfile.Gender" valueKey={genderKey} />
                    <ProfileBox
                      titleKey="WA.EditProfile.YearOfBirth"
                      value={this.props.user.yearOfBirth}
                      valueKey="WA.UnknownProfile"
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 col-boxes-info">
                    <ProfileBox titleKey="WA.EditProfile.ForehandStyle" valueKey={forehandKey} />
                    <ProfileBox titleKey="WA.EditProfile.BackhandStyle" valueKey={backhandKey}
                    />
                    <ProfileBox
                      titleKey="WA.EditProfile.TennisTalk"
                      value={this.props.user.tennisTalks.join(" - ")}
                      valueKey="WA.UnknownProfile"
                    />
                    <div className="edit">
                      <Link className="btn-gral verde" to="/profile/edit" style={{ whiteSpace: 'nowrap' }}>
                        <i class="fas fa-pencil-alt"></i> <FormattedMessage id="WA.EditProfile.EditProfile" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 col-boxes-info">
                    &nbsp;
                  </div>
                  <div className="col-xs-6 col-boxes-info">
                    <a href="/#" onClick={(e)=>{ e.preventDefault(); this.setState({...this.state, errorMessage: this.fm({id:"WA.ConfirmationDeleteMyAccount"}), onConfirm: this.deleteProfile})}} className="btn-gral gray">
                      <i class="far fa-trash-alt"></i> <FormattedMessage id="WA.ButtonDeleteMyAccount" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className="col-xs-12 col-boxes-info">
        <div className="alert alert-success fade in" role="alert">
          <Link to="/profile" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Link>
          <strong>
            <FormattedMessage id="WA.EditProfile.Msg.WellDone" />
          </strong>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  intl: intlShape.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserData: () => dispatch({ type: "GET_USERDATA" }),
  logOut: () => dispatch({ type: "LOGOUT" })
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
);
