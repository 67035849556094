import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import iconWhere from "../images/icon-where.png";
import { formatUserName } from "../utils.js";
import GameTitle from "./GameTitle.js";
import ProfilePic from "./ProfilePic";
class GamePending extends Component {
  constructor(props) {
    super(props);
    this.fm = props.intl.formatMessage;
    this.orderForMe = this.orderForMe.bind(this);
  }
  orderForMe(players) {
    let user = this.props.user;
    if (players.length === 1) {
      return players;
    }
    return players[0].id === user.id ? [players[0], players[1]] : [players[1], players[0]];
  }
  startSetScore(state, game) {
    game.gameSet = [{ initiatorScore: "?", opponentScore: "?" }];
    let validScoreList = state.validScoreList;
    validScoreList[game.id] = [{ initiatorScore: [], opponentScore: [] }];
    return { ...state, ...validScoreList };
  };
  playerNames(players, user, sep, selected) {
    if (!sep) {
      sep = ' &';
    }
    let pn = players.map((item, index) => {
      let s = '';
      if (index !== players.length - 1) {
        s = sep;
      }
      if (user && item.id === user.id) {
        return <span key={user.id}>{this.fm({ id: "WA.Title.Me" })}{s}</span>;
      } else {

        return <span className={item.id === selected ? 'selected' : ''}>{formatUserName(item)}{s}</span>;
      }
    });

    return pn;
  }

  render() {
    let game = this.props.game;
    let changeState = this.props.changeState;
    let state = this.props.state;

    let otherTeam;
    // set based on teams
    let setPartner = null;
    game.summary.meAsOwner = game.owner.id === this.props.user.id;

    if (game.initiatorTeam) {
      let me = game.initiatorTeam.players.filter(i => i && i.id === this.props.user.id);
      if (me.length > 0) {
        otherTeam = game.opponentTeam.players;
      } else {
        otherTeam = game.initiatorTeam.players;
      }
    } else {
      if (game.summary.meAsOwner) {
        otherTeam = game.invitee.map(inv => inv.user);
        if (otherTeam.length > 1 && game.action.indexOf("PARTNER_SELECTION") > -1) {

          setPartner = (user) => {
            let p = state.gamePartnerSelection;
            p[game.id] = user.id;
            changeState({ ...state, gamePartnerSelection: { ...p } })
          }
        }
      } else {
        otherTeam = [game.owner];
      }
    }
    if (!otherTeam) {
      console.log("other player is null");
    }
    // will do the merge

    return (
      <div className="table-row" key={game.id}>
        <GameTitle game={game}/>
        <div className="table-cell cell-score">
          <div style={{ display: "table", width: "100%" }}>
            <div className="player-box">
              <div className="player-line">
                <div className={`player ${setPartner ? 'partner' : ''}`}>

                  {otherTeam.map((user) =>
                    <div style={{ float: 'left', marginRight: '10px' }}>
                      <div style={{ textAlign: 'center' }}>
                        {user.firstName}&nbsp;
                    </div>
                      <div>
                        <div style={{ margin: 'auto', width: '45px' }}>
                          <ProfilePic url={user.profileImageId} onClick={(e) => { if (setPartner) setPartner(user) }} selected={state.gamePartnerSelection[game.id] === user.id} />
                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        {user.lastName}&nbsp;
                    </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-cell cell-sides">
          <div className="content-table-cell">
            <img src={iconWhere} alt="where" />
            {game.summary.locationString ? game.summary.locationString : this.fm({ id: "WA.UnknownLocation" })}
          </div>
        </div>
        {/* table-row */}
      </div>
    );
  }
}

GamePending.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GamePending)
);
