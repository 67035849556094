import React, { Component } from "react";
import PropTypes from "prop-types";
import DateFormat from "./DateFormat";
import iconDate from "../images/icon-date.png";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";

function getTitle(game) {
  if (game.status === 'PLAYED') {
    return null;
  }
  if (!game.summary.meAsOwner) {
    return (
      <>
        <a className="game-action" href={`/extinv/${game.id}/n`}>
          <i class="fas fa-exclamation-triangle warning"></i>&nbsp;
      {game.summary.title && <FormattedMessage id={game.summary.title} values={game.summary} />}
        </a>
      </>);
  } else {
    return (
      <>
        <i class="far fa-hourglass"></i>&nbsp;
        {game.summary.title && <FormattedMessage id={game.summary.title} values={game.summary} />}
      </>
    );
  }
}

class GameTitle extends Component {

  render() {
    return (
      <div className="table-cell cell-sides">
        {getTitle(this.props.game)}
        <div className="content-table-cell">
          <div style={{ height: '50px', display: 'table-cell', verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            <img src={iconDate} alt="date" />
            {this.props.game.summary.firstDate ?
              <>
                <DateFormat date={this.props.game.summary.firstDate.date} />&nbsp;
          {this.props.game.summary.variousTimeslot ?
                  <><br /><FormattedMessage id="GS.LabelTimeslotsVarious" /></>
                  :
                  <>{this.props.game.summary.firstDate.fromTime}</>
                }
              </>
              :
              <DateFormat date={this.props.game.summary.date} />
            }
          </div>
          <p className="font-05em">(<i className="fas fa-hashtag" /> {this.props.game.code})</p>
        </div>
      </div>)
  }
}

GameTitle.propTypes = {
  intl: intlShape.isRequired,
  game: PropTypes.object.isRequired
};


export default injectIntl(GameTitle);
