import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import CountryData from "./comp/CountryData";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import InputText from "./comp/InputText";
import iconAlert from "./images/on-boarding/icon-alert.svg";
import iconEyePass from "./images/on-boarding/icon-eye-pass.svg";
import iconNewAccount from "./images/on-boarding/icon-newAccount.svg";
import iconReturningPlayer from "./images/on-boarding/icon-ReturningPlayer.svg";
import { parseQueryStringToObject } from "./utils";


class Login extends Component {

  constructor(props) {
    super(props);

    const defaultContry = CountryData.filter((country, index) => this.props.countryCode === country.countryCode);
    let prefix = 'BE';
    if (defaultContry.length === 1){
      prefix = defaultContry[0].prefix;
    }

    this.state = {
      phone: '',
      prefix,
      password: '',
      fieldError: false,
      bounce: false,
      trigger: '',
      requestSent: false
    };
    this.showPassword = this.showPassword.bind(this);

    this.passwordChanged = this.passwordChanged.bind(this);
    this.phoneChanged = this.phoneChanged.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.fm = props.intl.formatMessage;
    this.updateValue = this.updateValue.bind(this);

  }
  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }
  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <HeaderOnBoarding />

        <div className="container content-login">
          <div className="row">

            <div className="col-xs-12 col-md-8 col-md-offset-2">

              <div className="wrapperOnboard text-center">
                <div className="boxTitle">
                  <img src={iconReturningPlayer} alt="Returning Player" />
                  <h1 className="h3"><FormattedMessage id="WA.MobileSignin.Title" /></h1>
                  <p><FormattedMessage id="WA.ReturningPlayer" /></p>
                </div>
                {this.renderHeader()}
                <div className="login-info text-left">
                  <form onSubmit={this.loginClicked}>

                    <div className="form-group-2">
                      <div className="form-group">
                        <label >
                          <FormattedMessage id="WA.Share.CountryCode" />
                        </label>
                        <select
                          name="prefix"
                          className="form-control"
                          onChange={(event) => this.updateValue(event.target.value, "prefix")}
                          value={this.state.prefix}
                        >
                          {
                            CountryData.map((country, index) => {
                              let cc = country.countryCode === this.props.countryCode;
                            return <option value={`${country.prefix}`} key={index} selected={cc}>{`${country.country} +${country.prefix}`}</option>
                            }
                            )
                          }
                        </select>
                      </div>
                      <div className="form-group">
                        <label >
                          <FormattedMessage id="WA.Landing.Phone" /> - {this.props.countryCode}
                        </label>
                        <InputText
                          label="&nbsp;"
                          placeholder="WA.Landing.PhonePlaceholder"
                          name="phone"
                          value={this.state.phone}
                          className="form-control"
                          onChange={this.phoneChanged}
                        />
                      </div>
                    </div>
                    <div className={"form-group " + (this.state.passwordError ? "has-error" : null)}>
                      <div className="showPass" onClick={this.showPassword}><img src={iconEyePass} alt="Show Password" /> <span ref="textchange"><FormattedMessage id="WA.ShowPassword" /></span></div>
                      <label htmlFor="password">
                        <FormattedMessage id="WA.Landing.Password" />
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        required
                        placeholder={this.props.intl.formatMessage({ id: 'WA.Landing.EnterYourPassword' })}
                        onChange={this.passwordChanged}
                      />
                      <p className="help-block">
                        <Link to="/forgottenpassword">
                          <FormattedMessage id="WA.Landing.ForgotYourPassword?" />
                        </Link>
                      </p>
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        className="btn-gral verde"
                        onClick={this.loginClicked}
                        disabled={!this.state.password || !this.state.phone}
                        value={this.props.intl.formatMessage({ id: 'WA.Landing.LoginButton' })}
                      />
                    </div>
                  </form>

                </div>

              </div>



            </div>



            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <Link className="newAccountCTA text-center" to="/signupsocial">
                <img class="" src={iconNewAccount} alt="Create New Account" />
                <h2 className="h3">
                  <FormattedMessage
                    id="WA.Landing.DontHaveAnAccountYet"
                  />
                </h2>
                <p><FormattedMessage id="WA.StartPlayingNow" /></p>
                <span className="itisfree"><FormattedMessage id="WA.ItsFreeForever" /></span>
              </Link>
            </div>


          </div>
        </div>
      </>
    );
  }

  showPassword() {
    let inputPass = document.getElementById('password');
    if (inputPass.type === "password") {
      inputPass.type = "text";
      this.refs.textchange.innerHTML = this.fm({ id: "WA.HidePassword" });
    } else {
      inputPass.type = "password";
      this.refs.textchange.innerHTML = this.fm({ id: "WA.ShowPassword" });
    }
  }

  passwordChanged(event) {
    this.setState({ password: event.target.value });
  }

  phoneChanged(phone) {
    this.setState({
      ...this.state,
      phone
    });
  }

  loginClicked(event) {
    event.preventDefault();
    if (this.state.password && this.state.phone) {
      this.setState({ requestSent:true, fieldError: false, bounce: true });
      this.props.loginWithPassword('+'+this.state.prefix + this.state.phone, this.state.password);
    } else {
      this.setState({ fieldError: true, bounce: true });
    }
  }
  componentDidMount() {
    if (this.props.location.search) {
      const query = parseQueryStringToObject(this.props.location.search);
      if (query.at) {
        let variables = parseQueryStringToObject(atob(query.at));
        this.props.loginWithApple(variables.id, variables.fn, variables.ln);
      }
    }
    window.AppleID.auth.init({
      clientId: this.props.appleClientId,
      scope: 'name email',
      redirectURI: window.document.location.href.substring(0, window.document.location.href.lastIndexOf("/")) + "/api/appleback",
      state: (Math.random() * 10000).toString()
    });
  }

  renderHeader  = () => {
    if(!this.state.requestSent) return;
    
    let bounce = this.state.bounce;
    if (bounce) {
      setTimeout(() => {
        this.setState({ bounce: false });
      }, 2000);
    }
    let errorMEssage = null;
    let errorC = "help-block";
    let bounceC = "anim-fade";

    if (this.state.fieldError) {
      errorMEssage = 'WA.Error.LoginField';
    } else if (this.props.loginError) {
      errorMEssage = 'WA.MobileLogin.Error';
    }

    if (errorMEssage) {
      return (<div className="alertBox media">
        <div className="media-left media-middle">
          <img src={iconAlert} alt="Warning" />
        </div>
        <div className="media-body">
          <span className={[errorC, bounceC].join(" ")}>
            <FormattedMessage id={errorMEssage} />
          </span>
        </div>
      </div>)
    } else {
      return null;
    }

  }
};

Login.propTypes = {
  fbAppId: PropTypes.string,
  getServerToken: PropTypes.func.isRequired,
  googleClientId: PropTypes.string,
  intl: intlShape.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginError: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  fbAppId: state.config.fbAppId,
  googleClientId: state.config.googleClientId,
  appleClientId: state.config.appleClientId,
  loggedIn: state.loggedIn,
  loginError: state.loginError,
  countryCode: state.config.countryCode
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getServerToken: (response, provider) => {
    dispatch({ type: 'SOCIAL_LOGIN_SUCCEED', provider, response });
  },
  loginWithPassword: (username, password) => {
    dispatch({ type: 'LOGIN_WITH_PASSWORD', username, password });

  },
  loginWithApple: (token) => {
    dispatch({ type: 'LOGIN_WITH_APPLE', token });

  }

  // ,
  // getConfig: () => {
  //   dispatch({ type: 'GET_CONFIG' })
  // }
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));