import { validate as validateEmail } from 'email-validator';
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from "react-intl";
import PhoneInput from "react-phone-number-input";
import flags from 'react-phone-number-input/flags';
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import ErrorModal from '../comp/ErrorModal';
import GameCalendar from '../comp/GameCalendar';
import Modal from '../comp/Modal';
import iconType from "../images/game-url/icon-ball.svg";
import iconCourt from "../images/game-url/icon-court-reserved.svg";
import iconDateWarning from "../images/game-url/icon-date-warning.svg";
import iconDate from "../images/game-url/icon-date.svg";
import iconDialog from "../images/game-url/icon-dialog.svg";
import iconForm from "../images/game-url/icon-form.svg";
import iconLocationWarning from "../images/game-url/icon-location-warning.svg";
import iconLocation from "../images/game-url/icon-location.svg";
import iconLogistic from "../images/game-url/icon-logistic.svg";
import iconPhone from "../images/game-url/icon-phone.svg";
import iconRacket from "../images/game-url/icon-racket.svg";
import iconTimer from "../images/game-url/icon-timer.svg";
import iconWarning from "../images/game-url/icon-warning.svg";
import whiteDefaultAvatar from "../images/icon-no-pic-white.png";
import iconSeeking from "../images/icon-seeking.png";
import { declineGameAction, getGameById, getNotes, sendGameAction, sendNote, setAuth } from '../resource';
import GameStyle from "../types/GameStyle";
import GameSummaryRule from "../types/GameSummaryRule";
import { calendarPreferenceFix, getLocale, getMinutesByTime } from "../utils";
import GameSummaryMocks from "../__mock__/GameSummaryMocks.json";
import GameSummaryField from "./GameSummaryField";
import InputText from "./InputText";
import ProfilePic from "./ProfilePic";
import DownloadBanner from "./DownloadBanner";


class GameSummary extends Component {
  getInitState() {
    return {

      email: "",
      firstName: "",
      lastName: "",
      prefix: "",
      phone: "",
      test: false,
      note: "",
      shareMyPhone: false,
      style: {
        firstName: '',
        lastName: '',
        email: ''
      },

      loading: false,
      fieldStates: {},

      //gameId: props.gameId,
      //invitee: null,

      game: null,
      gameNotes: [],
      ampm: false,

      selectedLocationId: null,
      selectedLocationPref: null,
      isLocationSpec: false,
      isTimeSpec: false,

      calendarSelectedByIndex: {},

      errorMessage: null,
      errorMessageVariable: {}
    };
  };

  constructor(props, context) {
    super(props);
    this.fm = props.intl.formatMessage;
    this.state = this.getInitState();

    let language = getLocale(props.intl);
    this.dateFormatter = new Intl.DateTimeFormat(language, { day: "numeric", month: "long", hour: 'numeric', minute: 'numeric' });

    this.gameStyle = new GameStyle();
    this.gameSummaryRule = new GameSummaryRule();
    this.updateValue = this.updateValue.bind(this);
    this.language = props.intl.locale;
    this.sendDeclineAction = this.sendDeclineAction.bind(this);
    this.confirmEvent = this.confirmEvent.bind(this);
    this.sendAcceptAction = this.sendAcceptAction.bind(this);
    this.sendOpponentSelection = this.sendOpponentSelection.bind(this);
    this.selectCalendar = this.selectCalendar.bind(this);
    this.openMap = this.openMap.bind(this);
    this.updateSelectedLocation = this.updateSelectedLocation.bind(this);
    this.sendUserNote = this.sendUserNote.bind(this);
    this.matchCategoryOptions = ["EITHER", "PRACTICEHIT", "IPM2MATCH"].map((cat) => {
      return { label: this.fm({ id: "ENUM.game.matchCategory." + cat }), value: cat };
    });
    this.matchTypeOptions = ["EITHER", "SINGLE", "OPENDOUBLE"].map((cat) => {
      return { label: this.fm({ id: "ENUM.game.matchType." + cat }), value: cat };
    });
    window.loadMock = this.loadMock.bind(this);
  }
  componentDidMount() {
    this.updateGameData();
    this.refreshNotes();
    if (this.props.password) {
      this.validateAcceptance(this.props.gameId, this.props.password).then((resp) => {
        this.updateGame(resp.data);
      }).catch((error) => {
        this.displayErrorPopUp(error);
      });
    }
  }
  updateGameData() {
    getGameById(this.props.gameId).then((gameData) => {
      this.updateGame(gameData.data);
    }).catch((error) => {
      console.log("Error loading game:" + error);
      return false;
    });
  }
  refreshNotes() {
    getNotes(this.props.gameId).then((notesData) => {
      this.setState({ ...this.state, gameNotes: notesData.data, note: '' })
    }).catch((error) => {
      console.log("Error loading game:" + error);
      return false;
    });
  }
  sendUserNote(event) {
    event.preventDefault();
    if (this.state.note) {
      sendNote(this.state.game.id, this.state.note).then(
        (result) => {
          this.refreshNotes();
        }
      );
    }
  }


  multipleSelectionEnabled() {
    return true;
    //return this.state.game && (this.state.game.gamePreference.matchType === "SINGLE" || this.state.game.gamePreference.matchType === "OPENDOUBLE");
  }

  render() {
    if (!this.state.game) {
      console.log("no game");
      return "No game";
    }
    this.gameSummaryRule.enrichSummary(this.state.game);
    const game = this.state.game;

    const mergedCalendarOptions = this.getMergedCalendarOptions(game);

    let calendarOptionsByDate = _.groupBy(mergedCalendarOptions, "date");
    calendarOptionsByDate = _.orderBy(calendarOptionsByDate, ["date"]);
    let submitEnabled = this.state.valid;
    _.reduce(this.state.error, (result, error, key) => {
      submitEnabled = submitEnabled && !error;
    });

    let selectedLocation = game.location;
    if (!selectedLocation && this.state.selectedLocationPref) {
      selectedLocation = this.state.selectedLocationPref.location;
    }
    const isDisplaySeeking = this.getDisplaySeeking(game);

    const headerText = this.getHeaderText(game);
    const notPartOfGame = game.action.length === 0;

    return (
      <>
        <Modal show={!!this.state.profileURL} onClose={(e) => this.setState({ ...this.state, profileURL: null })}>
          <iframe height="500" width="100%" title="User Profile" src={this.state.profileURL} frameborder="0" allowfullscreen></iframe>
        </Modal>
        <ErrorModal show={!!this.state.errorMessage} errorMessage={this.state.errorMessage} onClose={(e) => this.setState({ ...this.state, errorMessage: null })} />

        {this.state.test &&
          <select
            name="prefix"
            className="form-control"
            onChange={(event) => {
              this.setState({ ...this.getInitState(), style: this.getInitState().style, test: true });
              this.loadMock(event.target.value);
            }}
          >
            {
              Object.getOwnPropertyNames(GameSummaryMocks).map((key, index) =>
                <option value={key} key={key} >{key}</option>
              )
            }
          </select>}

        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">

            <div className="statusCard">
              {this.props.webaccessEnabled &&
                <a href="/#" className="game-action"><i className="fas fa-chevron-left"></i> <FormattedMessage id="WA.Game.BackToDashboard" /></a>
              }
              {headerText}
            </div>
          </div>


          <div className="col-sm-12">
            <div className="text-right gameCode"><FormattedMessage id="WA.GameID" values={{ id: game.code }} /></div>
          </div>

          <div className="col-sm-12">
            <div className="cardGS">
              <div className="game-summary-field">
                <div className="field-text">
                  <div className="field-icon">
                    <img className="img-responsive" src={iconType} alt="Ball" />
                  </div>
                  <div className="field-header">
                    <FormattedMessage id="GS.LabelTypeOfMatch" />
                  </div>
                  <div className="field-content">
                    <div className="typeSpan">
                      <FormattedMessage id={`ENUM.game.matchCategory.${game.gamePreference.matchCategory}`} />
                      <FormattedMessage id="WA.Game.Duration" values={{ min: game.gamePreference.duration }} />
                    </div>

                    {game.gamePreference.gameSetRule &&
                      <><FormattedMessage id={`ENUM.game.gameSetRule.${game.gamePreference.gameSetRule}`} /> - </>
                    }
                    <FormattedMessage id={`ENUM.game.matchType.${game.gamePreference.matchType}`} /> -{" "}
                    {game.gamePreference.opponentsGender &&
                      <FormattedMessage id={`ENUM.gender.${game.gamePreference.opponentsGender}`} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="cardGS">
              <div className="game-summary-field">
                <div className="field-text">
                  <div className="field-icon">
                    {this.isGameScheduled(game) ? <img className="img-responsive" src={iconTimer} alt="Timer" />
                      : game.summary.icon === this.gameStyle.ACTION ? <img className="img-responsive" src={iconWarning} alt="Warning" /> : <img className="img-responsive" src={iconTimer} alt="Timer" />
                    }
                  </div>
                  <div className="field-header"><FormattedMessage id="WA.Invitation.Expiration" /></div>
                  <div className="field-content">
                    {this.getInvitationExpiryDays(game)}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <GameSummaryField open={true}>
            <div className="field-text">
              <div className="field-icon">
                <img className="img-responsive" src={iconRacket} alt="Racket" />
              </div>
              <div className="field-header">
                <FormattedMessage id="WA.Title.Player" />
              </div>
            </div>

            <div>
              <div className="player-item">
                <a href="#/" onClick={(e) => this.setState({ ...this.state, profileURL: "/publicProfile/" + game.owner.code + "?noheader" })} className="">
                  <ProfilePic url={game.owner.profileImageId} defaultProfilePic={whiteDefaultAvatar} /><div className="name">{this.getUserName(game.owner)}</div>
                </a>
              </div>
              {game.invitee.filter(invitee => invitee.type === "OPPONENT"
                && ((invitee.status === "ACCEPTED" && isDisplaySeeking) || invitee.status === "CONFIRMED")
                && invitee.id !== game.currentInvitee.id
              ).map(invitee => {
                return (
                  <div className="player-item">
                    {notPartOfGame ?
                      <a href="#/"><ProfilePic url={invitee.user.profileImageId} defaultProfilePic={whiteDefaultAvatar} /><div className="name">{this.getUserName(invitee.user)}</div></a>
                      :
                      <a href="#/" onClick={(e) => this.setState({ ...this.state, profileURL: "/publicProfile/" + invitee.user.code + "?noheader" })}>
                        <ProfilePic url={invitee.user.profileImageId} defaultProfilePic={whiteDefaultAvatar} /><div className="name">{this.getUserName(invitee.user)}</div>
                      </a>}

                  </div>
                );
              })}
              {isDisplaySeeking &&
                <div className="player-item">
                  <div className="circle-border" style={{ borderColor: "#80dc3c" }}>
                    <img src={iconSeeking} alt="seeking" style={{ height: "30px" }} />
                  </div>
                  <div className="name" style={{ color: '#80dc3c' }}><FormattedMessage id="WA.SeekingPlayer" /></div>
                </div>
              }
            </div>
          </GameSummaryField>


          <GameSummaryField open={true}>
            <div className="field-text">
              <div className="field-icon">
                {this.state.isTimeSpec ? <img className="img-responsive" src={iconDateWarning} alt="Date" /> : <img className="img-responsive" src={iconDate} alt="Date" />}
              </div>
              <div className="field-header">
                {this.state.isTimeSpec ? <FormattedMessage id="WA.Select.Time" /> : <FormattedMessage id="WA.Title.Date" />}
              </div>
            </div>
            <div>
              {calendarOptionsByDate.map((cp, i) => {
                if (!this.isInviteeStatusPending(game) || this.isGameScheduled(game)) {
                  if (cp.filter(item => !!item.invitationId).length === 0) {
                    return null;
                  }
                }
                return <GameCalendar key={i} calendar={cp} duration={game.gamePreference.duration} readOnly={!this.isInviteeStatusPending(game)} single={!this.multipleSelectionEnabled()} onSelect={(selected) => { this.selectCalendar(selected, i, cp) }} />
              })}
            </div>
          </GameSummaryField>



          <GameSummaryField open={true}>
            <div className="field-text" data-lat={this.getLocationLat(selectedLocation)}
              data-lon={this.getLocationLon(selectedLocation)}
              data-addr={this.getLocationAddr(selectedLocation)}
              onClick={this.openMap}>
              <div className="field-icon">
                {this.state.isLocationSpec ? <img className="img-responsive" src={iconLocationWarning} alt="Location" /> : <img className="img-responsive" src={iconLocation} alt="Location" />}
              </div>
              <div className="field-header">
                <FormattedMessage id="GS.LabelLocation" />
              </div>
              <div className="field-content" >
                {selectedLocation ?
                  selectedLocation.name + ", " + selectedLocation.address
                  :
                  this.fm({ id: "WA.Select.One" })
                }</div>
            </div>
            {this.state.isLocationSpec &&
              game.locationPreference.filter(lp => this.isInviteeStatusPending(game) || (!this.isInviteeStatusPending(game) && this.state.selectedLocationId === lp.location.id)).filter(lp => !lp.invitationId).map(item => {
                let selected = this.state.selectedLocationId === item.location.id;
                return (
                  <div className={`location-item ${selected ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); this.updateSelectedLocation(item.location.id) }}>
                    <div className="location">
                      <div className={`name ${selected ? 'selected' : ''}`}>{item.location.name}</div>
                      <div className={`address ${selected ? 'selected' : ''}`}><i className="fas fa-map-marker-alt"></i> {item.location.address}</div>
                    </div>
                    <div className={`checkmark ${selected ? 'selected' : ''}`}><i className="fas fa-check-circle"></i></div>
                  </div>
                )
              })
            }
          </GameSummaryField>



          <div className="col-sm-12">
            <div className="cardGS">
              <div className="game-summary-field">
                <div className="field-text">
                  <div className="field-icon">
                    <img className="img-responsive" src={iconLogistic} alt="Logistics" />
                  </div>
                  <div className="field-header">
                    <FormattedMessage id="GAG.SectionLOGISTICS" />
                  </div>
                  {game.owner.phone && <div className="field-content pd-top-1em"><div className="item-logistic"><img className="img-responsive" src={iconPhone} alt="Phone" /> {game.owner.phone}</div></div>}
                  <div className="field-content pd-top-1em">
                    {game.locationResolved &&
                      <>
                        <div className="item-logistic">
                          <img src={iconCourt} alt="Court Reserved" />
                          <FormattedMessage id="GAG.LabelCourtReserved" />
                        </div>
                      </>
                    }
                    {game.shareMyPhone && <> - <FormattedMessage id="GS.LabelYouSharedYourPhone" /></>}
                  </div>

                  {this.getNotes(game)}
                  {this.getPhoneInput(game)}

                </div>
              </div>
            </div>
          </div>


          {this.state.isShare &&
            <>
              <div className="col-sm-12">
                <div className="cardGS">
                  <div className="game-summary-field">
                    <div className="field-text">
                      <div className="field-icon">
                        <img className="img-responsive" src={iconForm} alt="Form" />
                      </div>
                      <div className="field-header">
                        <FormattedMessage id="WA.Share.CompleteForm" />
                      </div>
                      <div className="field-content">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className={"form-group has-feedback " + (this.state.style.firstName)}>
                              <InputText label="WA.EditProfile.FirstName" placeholder="WA.SignUpFirstNamePlaceholder" name="firstName" className="form-control"
                                onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                                valid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-success' } }) }}
                                notValid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-error' } }) }}
                                onChange={this.updateValue}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className={"form-group has-feedback " + (this.state.style.lastName)}>
                              <InputText label="WA.EditProfile.LastName" placeholder="WA.SignUpLastNamePlaceholder" name="lastName" className="form-control"
                                onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                                valid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-success' } }) }}
                                notValid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-error' } }) }}
                                onChange={(value) => this.setState({ lastName: value })}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className={"form-group has-feedback " + (this.state.style.email)}>
                              <InputText
                                label="WA.Landing.EmailAddress"
                                placeholder="WA.Landing.EmailAddressPlaceholder"
                                name="email"
                                className="form-control"
                                validator={validateEmail}
                                onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res }, valid: true }) }}
                                valid={() => { this.setState({ style: { ...this.state.style, email: 'has-success' } }) }}
                                notValid={() => { this.setState({ style: { ...this.state.style, email: 'has-error' } }) }}
                                value={this.state.email}
                                onChange={(value, name) => {
                                  this.updateValue(value.toLowerCase(), name);
                                }
                                } />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-12">
                                <label >
                                  <FormattedMessage id="WA.Landing.Phone" />
                                </label>
                                <PhoneInput
                                  style={{ paddingTop: "11px" }}
                                  className="form-control"
                                  value={this.state.phone}
                                  country={this.props.countryCode}
                                  flags={flags}
                                  onChange={(value) => {
                                    this.updateValue(value, "phone");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </>
          }
          {this.getActionButtons(game, mergedCalendarOptions)}
          <div className="col-sm-12" style={{ marginTop: '20px' }}>
            <div className="statusCard">
              {headerText}
            </div>
          </div>
        </div>

        <DownloadBanner />

      </>
    );
  }

  openMap(event) {
    event.preventDefault();
    let lat = event.currentTarget.dataset.lat;
    let lon = event.currentTarget.dataset.lon;
    let addr = event.currentTarget.dataset.addr;
    if (lat !== "0" && lon !== "0") {
      if ((navigator.platform.indexOf("iPhone") !== -1) ||
        (navigator.platform.indexOf("iPad") !== -1) ||
        (navigator.platform.indexOf("iPod") !== -1)) {
        /* if we're on iOS, open in Apple Maps */
        window.open("maps://maps.google.com/maps?daddr=" + addr + "&amp;ll=" + lat + "," + lon);
      } else {
        /* else use Google */
        window.open("https://maps.google.com/maps?daddr=" + addr + "&amp;ll=" + lat + "," + lon);
      }
    }
  }
  loadMock(name) {
    this.updateGame(GameSummaryMocks[name]);
  }

  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }

  sendDeclineAction(event) {

    event.preventDefault();
    declineGameAction(this.state.game.id).then((result) => {
      this.setState({ ...this.state, game: result.data });
    });
  }

  sendOpponentSelection(event) {
    let data = {
      "actionType": "OPPONENT_SELECTION",
      "contactList": [{
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "email": this.state.email,
        "note": this.state.note,
        "shareMyPhone": this.state.shareMyPhone
      }
      ]
    };
    let phone = this.state.phone;
    if (!!phone) {
      data.contactList[0].phone = this.state.prefix + phone;
    }
    return sendGameAction(this.state.game.id, data);
  }

  sendAcceptAction(sendConfirmation) {
    let actionData = { next: {} };
    let nextData = actionData;

    if (this.state.isLocationSpec) {
      let data = {
        "actionType": "LOCATION_SELECTION",
        "preferedLocation": [{
          id: this.state.selectedLocationId
        }]
      }
      nextData.next = data;
      nextData = nextData.next;
    }

    if (this.state.isTimeSpec) {

      let requestFormat = []
      let preferedCalendar = Object.values(this.state.calendarSelectedByIndex);
      preferedCalendar.forEach(item => {
        requestFormat = requestFormat.concat(item);
      });
      requestFormat = requestFormat.map(item => {
        return {
          date: item.date,
          fromTime: item.fromTime,
          toTime: item.toTime
        };
      });
      let data = {
        "actionType": "TIME_SELECTION",
        "preferedCalendar": requestFormat,
        next: {}
      }
      nextData.next = data;
      nextData = nextData.next;
    }
    if (sendConfirmation) {
      nextData.next = {
        actionType: "ACCEPT",
        note: this.state.note,
        shareMyPhone: this.state.shareMyPhone
      };
    } else {
      delete nextData.next;
    }

    return sendGameAction(this.state.game.id, actionData.next);
  }
  validateAcceptance(gameId, password) {
    return sendGameAction(gameId, { actionType: "ACCEPT", password: password });
  }


  displayErrorPopUp(error) {
    let messageId = "E.gen.serverError";
    let errorCode = _.get(error, 'response.data.error');

    if (errorCode === 'E-C24.2') { // in case of SHARE contact already invited
      messageId = "WA.sharedGameURLBlockContact";
    } else if (errorCode === 'E-C24.1') { // in case of SHARE user already invited
      messageId = "WA.sharedGameURLBlockUser";
    } else if (error.response && error.response.data && error.response.data.message) {
      messageId = error.response.data.message;
    }
    let errorMessageVariable = {};
    if (this.state.game) {
      errorMessageVariable.gameOwner = this.getUserName(this.state.game.owner);
    }
    this.setState({ ...this.state, loading: false, errorMessage: this.fm({ id: messageId }, errorMessageVariable) });
  }

  confirmEvent(event) {
    event.preventDefault();
    if (this.state.isShare) {
      this.setState({ ...this.state, loading: true });
      this.sendOpponentSelection(event).then((result) => {
        let opList = result.data.invitee.filter(i => i.type === "OPPONENT" && (i.email.toLowerCase() === this.state.email.toLowerCase() || (i.phone && i.phone === this.state.phone)));
        if (opList.length === 1) {
          setAuth(opList[0].accessToken);
          const sendConfirmation = !opList[0].secured;

          if (sendConfirmation || this.state.isTimeSpec || this.state.isLocationSpe) {
            this.sendAcceptAction(sendConfirmation).then(acceptResult => {
              window.history.pushState({ gameURL: 'URL' }, 'token', opList[0].accessToken);
              this.updateGame(acceptResult.data);
            })
          } else {
            window.history.pushState({ gameURL: 'URL' }, 'token', opList[0].accessToken);
            window.location.reload();
          }
        } else {
          throw new Error("sharing game:" + result.data.code + " opList.length:" + opList.length + " invitee:" + result.data.invitee);
        }
      }).catch((error) => {
        this.displayErrorPopUp(error);
      });
    } else {
      this.setState({ ...this.state, loading: true });
      this.sendAcceptAction(true).then(result => {
        this.updateGame(result.data);
      }).catch((error) => {
        this.displayErrorPopUp(error);
      });
    }
  }

  selectCalendar(selected, i, cp) {
    let cal = this.state.calendarSelectedByIndex;
    if (!this.multipleSelectionEnabled()) {
      cal[0] = selected;
    } else {
      cal[i] = selected;
    }
    this.setState({ ...this.state, calendarSelectedByIndex: cal });
  }

  updateGame(game) {
    let selectedLocationId = null;
    let selectedLocationPref = null;
    let selectedTime = null;
    let invitee = game.currentInvitee;
    game.calendarPreference = calendarPreferenceFix(game.calendarPreference);

    let isLocationSpec = game.action.indexOf("LOCATION_SELECTION") > -1;
    let isTimeSpec = game.action.indexOf("TIME_SELECTION") > -1 && !this.isValidationRequired(game);

    let isShare = 'SHARE_INVITEE' === game.userRole && game.status !== "SCHEDULED" && game.status !== "CANCELED";


    if (game.locationPreference.length === 1) {
      selectedLocationPref = game.locationPreference[0];
    } else {
      let selectedLp = game.locationPreference.filter(p => p.invitationId === invitee.id);
      if (selectedLp.length === 1) {
        selectedLocationPref = selectedLp[0];
      }
    }
    selectedLocationId = selectedLocationPref ? selectedLocationPref.location.id : null;

    let calendarP = game.calendarPreference.filter(p => p.invitationId === invitee.id);
    if (calendarP.length === 1) {
      selectedTime = calendarP[0].local.from;
    }

    if (isShare) {
      isTimeSpec = game.moreCalendarPeriods;
      isLocationSpec = game.locationPreference.filter(lp => !lp.invitationId).length > 1;
    }

    // calendarSelectedByIndex has to be cleared after refreshing the data from the back-end, because selected calendar duplication issue can be appeared. data comming from back-end and fron front-end selected intervalls. 

    this.setState({
      ...this.state, game, selectedLocationId, selectedLocationPref, calendarSelectedByIndex: {},
      selectedTime, isLocationSpec, isTimeSpec, gamePreference: { ...game.gamePreference },
      isShare, note: invitee.note, shareMyPhone: invitee.shareMyPhone,
      loading: false
    });

  }

  updateSelectedLocation(selectedLocationId) {
    let selectedLp = this.state.game.locationPreference.filter(p => p.location.id === selectedLocationId);
    if (selectedLp.length > 0) {
      this.setState({
        ...this.state, selectedLocationId, selectedLocationPref: selectedLp[0]
      });
    }
  }

  getUserName(user) {
    if (user) {
      let name = user.firstName;
      if (user.lastName) {
        name += " " + user.lastName;
      }
      return name;
    }
    return '';
  }

  getLocationLat(location) {
    if (location) {
      return location.lat;
    }
    return 0;
  }

  getLocationLon(location) {
    if (location) {
      return location.lon;
    }
    return 0;
  }

  getLocationAddr(location) {
    if (location) {
      return location.address;
    }
    return 0;
  }

  getInvitationExpiryDays(game) {
    let invitationExpiryDays;
    if (game.invitationExpiryDays && game.invitationExpiryDays !== 0) {
      let date = new Date(game.createDt + "Z");
      let expDate = date.addHours(parseInt(game.invitationExpiryDays) * 24);
      let calendarS = game.calendarPreference.map(cal => {
        return cal.date + "T" + cal.fromTime + "Z";
      });
      calendarS.sort();
      let latestCalendarDate = new Date(calendarS[calendarS.length - 1]);
      expDate = latestCalendarDate.getTime() < expDate.getTime() ? latestCalendarDate : expDate;
      let remains = expDate.getTime() - new Date().getTime();
      if (remains > 0) {
        let amount = remains / 60000 / 60 / 24;
        let unit = this.fm({ id: "WA.TimeUnitHours" });
        if (amount < 1) {
          amount *= 24;
        }
        else if (amount < 2) {
          unit = this.fm({ id: "WA.TimeUnitDay" });
        }
        else {
          unit = this.fm({ id: "WA.TimeUnitDays" });
        }
        invitationExpiryDays = this.fm({ id: "WA.GameInvitationExpire" }, { amount: parseInt(amount), unit });
      }
      else {
        invitationExpiryDays = this.fm({ id: "WA.GameInvitationExpired" });
      }
    }
    else {
      invitationExpiryDays = this.fm({ id: "DB.LabelDashboardExpirationNone" });
    }
    return invitationExpiryDays;
  }

  getDisplaySeeking(game) {
    let displaySeeking = false;
    if (game.status !== 'SCHEDULED' && game.gamePreference.candidateMethod === 'FIRST' && game.gamePreference.matchType === 'SINGLE') {
      displaySeeking = true;
    } else if (game.status !== 'SCHEDULED' && game.calendarPreference.length === 1 && game.locationPreference.length === 1) {
      let fromMin = getMinutesByTime(game.calendarPreference[0].fromTime);
      let toMin = getMinutesByTime(game.calendarPreference[0].toTime);
      displaySeeking = (toMin - fromMin) === parseInt(game.gamePreference.duration);
    }
    return displaySeeking;
  }
  hasAction(game) {
    return game.status !== "SCHEDULED" && !this.isValidationRequired(game)
      && (this.state.isLocationSpec || this.state.isTimeSpec || game.action.indexOf("ACCEPT") !== -1 || this.state.isShare);
  }
  hasAccept(game) {
    return game.action.indexOf("ACCEPT") > -1 || this.state.isShare;
  }
  getActionButtons(game, mergedCalendarOptions) {
    //
    let hasAction = this.hasAction(game);
    let hasDecline = game.action.indexOf("DECLINE") > -1;
    let hasAccept = this.hasAccept(game);
    const isCalendarSelected = game.calendarPreference.length < mergedCalendarOptions.length;

    let formValid = (this.state.selectedLocationId || !this.state.isLocationSpec)
      && (isCalendarSelected || !this.state.isTimeSpec)
      && (!this.state.isShare || (this.state.style.firstName === "has-success" && this.state.style.lastName === "has-success" && this.state.style.email === "has-success"));
    const acceptDisabled = !formValid || this.state.loading;
    const declineDisabled = this.props.loading;

    if (hasAction) {
      return (<div className="col-sm-12">
        <div className="game-summary-button" style={{ textAlign: "center" }}>
          {hasDecline &&
            <button type="button" className="decline" disabled={declineDisabled} onClick={this.sendDeclineAction}>
              {this.isInviteeStatusAccepted(game) ? <FormattedMessage id="WA.Button.WithdrawAcceptance" /> : <FormattedMessage id="WA.Button.Decline.Game" />}
            </button>
          }
          {hasAccept &&
            <button type="button" className="confirm" onClick={this.confirmEvent} disabled={acceptDisabled}><FormattedMessage id="WA.Button.Accept.Game" /></button>
          }
        </div>
      </div>);
    }
    return null;
  }

  getNotes(game) {
    return <>
      {this.state.gameNotes.sort((note1, note2) => {
        return Date.parse(note1.createDt) - Date.parse(note2.createDt);
      }).map(noteData => {
        const me = game.currentInvitee.user.code === noteData.user.code;
        return <div className="field-content pd-top-1em"><div className={`${me ? 'item-note-my' : 'item-note-item'}`}><img style={{ float: me ? 'right' : 'left' }} src={iconDialog} alt="Notes" />{this.dateFormatter.format(Date.parse(noteData.createDt))} - <b>{me ? this.fm({ id: "WA.Title.Me" }) : this.getUserName(noteData.user)}</b></div>
          <div className={`${me ? 'item-logistic-right' : 'item-logistic'}`}>{noteData.message}</div></div>
      })}
      {!this.state.isShare && !this.isNotPartOfTheGame(game) &&
        <>
          <div className="field-content pd-top-1em">
            <textarea rows="2" style={{ width: "100%", marginTop: "5px" }} placeholder={this.fm({ id: "GS.LabelTypeSomething" })}
              onChange={(e) => this.setState({ ...this.state, note: e.target.value })} value={this.state.note} />
          </div>
          <div className="field-content, file-content-button pd-top-1em">
            <button type="button" className="confirm" onClick={this.sendUserNote}>
              <FormattedMessage id="WA.Button.Note" />
            </button>
          </div>
        </>}
    </>
  }
  getPhoneInput(game) {
    let hasAction = this.hasAction(game);
    let hasAccept = this.hasAccept(game);
    if (hasAction && hasAccept) {
      return (
        <>
          {(this.state.isShare || game.currentInvitee.hasPhone) &&
            <div className="field-content pd-top-1em">
              <label htmlFor="shareMyPhone" className="box-radio-checkbox"><input type="checkbox" id="shareMyPhone" value="true" checked={this.state.shareMyPhone} onChange={(event) => this.setState({ ...this.state, shareMyPhone: !this.state.shareMyPhone })} /><span className="radiobtn"></span><FormattedMessage id="GS.LabelShareMyPhoneWithInviter" /></label>
            </div>
          }
        </>
      );
    } else {
      return (<>
        {this.state.shareMyPhone &&
          <div className="field-content pd-top-1em">
            <label htmlFor="shareMyPhone" className="box-radio-checkbox"><input type="checkbox" id="shareMyPhone" value="true" readOnly checked /><span className="radiobtn"></span><FormattedMessage id="GS.LabelShareMyPhoneWithInviter" /></label>
          </div>
        }
      </>
      );
    }
  }

  getMergedCalendarOptions(game) {
    const selectedCalendarByDate = Object.values(this.state.calendarSelectedByIndex);
    let mergedCalendarOptions = game.calendarPreference;
    selectedCalendarByDate.forEach(element => {
      if (element.length !== 0) {
        mergedCalendarOptions = mergedCalendarOptions.concat(element);
      }
    });
    return mergedCalendarOptions;
  }

  isGameScheduled(game) {
    return game.status !== "CONFIRMED";
  }

  isInviteeStatusPending(game) {
    return game.currentInvitee && (game.currentInvitee.status === "PENDING" || game.currentInvitee.status === "SENT" || game.currentInvitee.status === "OPENED" || game.currentInvitee.status === "UNDELIVERABLE");
  }

  isInviteeStatusConfirmed(game) {
    return game.currentInvitee && game.currentInvitee.status === "CONFIRMED";
  }

  isInviteeStatusDeclined(game) {
    return game.currentInvitee && game.currentInvitee.status === "DECLINED";
  }

  isInviteeStatusAccepted(game) {
    return game.currentInvitee && game.currentInvitee.status === "ACCEPTED";
  }
  isValidationRequired(game) {
    return game.currentInvitee.secured;
  }
  isNotPartOfTheGame(game) {
    return game.action.length === 0;
  }
  getHeaderText(game) {

    const notPartOfGame = this.isNotPartOfTheGame(game);
    let isGameScheduled = this.isGameScheduled(game);
    let isInviteeStatusACCEPTED = this.isInviteeStatusAccepted(game);
    let isInviteeStatusDECLINED = this.isInviteeStatusDeclined(game);
    let isInviteeStatusCONFIRMED = this.isInviteeStatusConfirmed(game);
    let isInviteeStatusPENDING = this.isInviteeStatusPending(game);
    let validationHasToBeDone = this.isValidationRequired(game);

    if (validationHasToBeDone) {
      if (game.currentInvitee.user.type === 'CONTACT') {
        return <FormattedHTMLMessage id="WA.sharedGameURLContactAcceptanceValidation" values={game.summary} />;
      } else {
        return <FormattedHTMLMessage id="WA.sharedGameURLUserAcceptanceValidation" values={game.summary} />;
      }
    }
    return notPartOfGame ?
      <>
        <p><i className="fas fa-exclamation-circle error" style={{ paddingRight: '5px' }}></i>
          {isInviteeStatusDECLINED ?
            <FormattedHTMLMessage id="WA.gameContactDECLINED" values={game.summary} />
            :
            <FormattedHTMLMessage id="WA.UserNotInGame" values={game.summary} />}
        </p></>
      :
      <>
        {isGameScheduled ?
          <>
            {isInviteeStatusCONFIRMED ?
              <FormattedHTMLMessage id="WA.gameURLGameScheduledIntroText" values={game.summary} />
              :
              <FormattedHTMLMessage id="WA.UserNotInGame" values={game.summary} />}
          </>
          :
          <>
            {isInviteeStatusDECLINED && <FormattedHTMLMessage id="WA.gameContactDECLINED" values={game.summary} />}
            {isInviteeStatusACCEPTED && <FormattedHTMLMessage id="WA.gameContactACCEPTED" values={game.summary} />}
            {isInviteeStatusPENDING && <FormattedHTMLMessage id="WA.gameURLSharingIntroText" values={game.summary} />}
          </>}
      </>;
  }
}

GameSummary.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData || {},
    countryCode: state.config.countryCode,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GameSummary)
);