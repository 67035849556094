import { validate as validateEmail } from "email-validator";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import CountryData from "./comp/CountryData";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import InputText from "./comp/InputText";
import { forgottenPassword } from "./resource";

class ForgottenPassword extends Component {
  constructor(props) {
    const defaultContry = CountryData.filter((country, index) => {
      return props.countryCode === country.countryCode;
    });

    super(props);
    this.state = {
      email: "",
      prefix: defaultContry[0].prefix,
      phone: null,
      phonePasswordReminderSent: false,
      emailPasswordReminderSent: false,
      error: {
        email: true
      },
      style: {
        email: ''
      }
    };

    this.emailChanged = this.emailChanged.bind(this);
    this.phoneChanged = this.phoneChanged.bind(this);
    this.submit = this.submit.bind(this);
    this.isEnabled = this.isEnabled.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }
  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }
  render() {

    return (
      <>
        <HeaderOnBoarding />

        <div className="container content-login">
          <div className="row">


            <div className="col-xs-12 col-md-8 col-md-offset-2">

              <div className="wrapperOnboard text-center">
                <div className="boxTitle">
                  <h1 className="h3"><FormattedMessage id="WA.Landing.ForgotYourPassword?" /></h1>
                </div>

                <div className="text-left">
                  <p>
                    {this.state.phonePasswordReminderSent && <FormattedMessage id="WA.ForgottenPassword.Mobile.Success" />}
                    {this.state.emailPasswordReminderSent && <FormattedMessage id="WA.ForgottenPassword.Email.Success" />}

                    {!this.state.phonePasswordReminderSent && !this.state.emailPasswordReminderSent && <FormattedMessage id="WA.RecoverForgottenPassword" />}
                  </p>
                  {!this.props.forgottenPasswordSuccess &&
                    <form onSubmit={this.submit}>
                      <div>
                        <div className={"form-group has-feedback " + this.state.style.email}>
                          <label htmlFor="forgotpasswordEmail">
                            <FormattedMessage id="WA.Landing.EmailAddress" />
                          </label>
                          <input
                            id="forgotpasswordEmail"
                            type="email"
                            className="form-control"
                            onChange={this.emailChanged}
                            value={this.state.email}
                            placeholder={this.props.intl.formatMessage({ id: "WA.Landing.EmailAddressPlaceholder" })}
                            validator={validateEmail}
                            onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res }, valid: true }) }}
                            valid={() => { this.setState({ style: { ...this.state.style, email: 'has-success' } }) }}
                            notValid={() => { this.setState({ style: { ...this.state.style, email: 'has-error' } }) }}
  
                          />
                          <span
                            className={
                              "glyphicon glyphicon-" + (this.state.error.email ? "remove" : "ok") + " form-control-feedback"
                            }
                            aria-hidden="true"
                          />
                        </div>

                        <div className="o-divider">
                          <FormattedMessage id="WA.or" />
                        </div>


                        <div className="row">
                          <div className="col-sm-5">
                            <div className="form-group">
                              <label >
                                <FormattedMessage id="WA.Share.CountryCode" />
                              </label>
                              <select
                                name="prefix"
                                className="form-control"
                                onChange={(event) => this.updateValue(event.target.value, "prefix")}
                                value={this.state.prefix}
                              >
                                {
                                  CountryData.map((country, index) => {
                                    return <option value={`${country.prefix}`} key={index} selected={country.countryCode === this.props.countryCode}>{`${country.country} +${country.prefix}`}</option>
                                  }
                                  )
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <div className="form-group">
                              <label >
                                <FormattedMessage id="WA.Landing.Phone" /> - {this.props.countryCode}
                              </label>

                              <InputText
                                label="&nbsp;"
                                placeholder="WA.Landing.PhonePlaceholder"
                                name="phone"
                                value={this.state.phone}
                                className="form-control"
                                onChange={this.phoneChanged}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group has-feedback ">
                        <input
                          type="submit"
                          className="btn-gral verde form-control"
                          value={this.props.intl.formatMessage({ id: "WA.FB.Send" })}
                          disabled={this.state.loading || this.state.phonePasswordReminderSent || this.state.emailPasswordReminderSent || (!this.state.phone && !this.state.email)}
                          onClick={this.submit}
                        />
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
  isEnabled() {
    return !!this.state.phone || !!this.state.email;
  }
  emailChanged(event) {
    const email = event.target.value;
    this.setState({
      email,
      error: {
        email: !validateEmail(email)
      }
    });
  }
  phoneChanged(phone) {
    this.setState({
      ...this.state,
      phone
    });
  }

  submit(event) {
    event.preventDefault();
    let data = {...this.state};
    if(this.state.phone){
      data.phone = ('+'+this.state.prefix)+(''+this.state.phone);
    }
    this.setState({...this.state, loading:true});
    forgottenPassword (this.state.email, data.phone).then(
      this.setState({...this.state, phonePasswordReminderSent: !!this.state.phone, emailPasswordReminderSent: !this.state.phone, loading:false})
    ).catch(
      this.setState({...this.state, phonePasswordReminderSent: !!this.state.phone, emailPasswordReminderSent: !this.state.phone, loading:false})
    )
  }
}

ForgottenPassword.propTypes = {
  intl: intlShape.isRequired,
  forgottenPasswordSuccess: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  forgottenPasswordSuccess: state.forgottenPasswordSuccess,
  loading: state.loading,
  countryCode: state.config.countryCode,
  forgottenUserNotFound: state.forgottenUserNotFound
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  send: data => dispatch({ type: "FORGOTTEN_PASSWORD_REQUEST", data }),
  showToast: data => dispatch({ type: "SHOW_TOAST", data }),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgottenPassword)
);
