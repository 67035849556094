
/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import Header from "../../comp/Header";
import imageIpm2Universe from "../../images/icon-ipm2-universe.png";
import imagePersonalRating from "../../images/icon-personal-rating.png";
import imageIconPlayers from "../../images/icon-players.png";
import imageStar from "../../images/star.png";
import {
  getIPlayme2DoubleRank, getIPlayme2SingleRank, getPersonalCircleSkill,
  getPersonalIplayme2Skill, getPersonalSkill, getTechCircleSkills,
  getTechIplayme2Skills, getTechSkills
} from "../../resource";
import { renderStars } from "../../utils";
import CommonComponent from "../CommonComponent";
import RankingsBox from './RankingBox';




const renderNumber = rank => {
  if (!rank) {
    rank = 0;
  }

  return <FormattedNumber value={rank}  intlStyle="decimal" minimumFractionDigits="1" maximumFractionDigits="1" />;
};
const renderSkill = (label, data, skill) => {
  return (
    <tr>
      <td>
        <div class="skill">
          <FormattedMessage id={label} />
        </div>
      </td>
      <td>
        <table align="center">
          <tr>
            <td>
              <div class="box-stars">{renderStars(data.user[skill])}</div>
            </td>
          </tr>
        </table>
      </td>
      <td class="text-center">
        <table align="center">
          <tr align="center">
            <td class="score-others">{renderNumber(data.iplayme2[skill])}</td>
            <td>
              <img src={imageStar} class="star active" />
            </td>
          </tr>
        </table>
      </td>
      <td class="text-center">
        <table align="center">
          <tr>
            <td class="score-others">{renderNumber(data.circle[skill])}</td>
            <td>
              <img src={imageStar} class="star active" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  );
};

const PersonalRatingBox = ({ active, personal }) => {
  if (!personal) return null;
  return (
    <>
      <div role="tabpanel" className={`tab-pane ${active ? "active" : ""}`} id="personal">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th />
                <th class="text-center" style={{ width: "25%" }}>
                  <img src={imagePersonalRating} />
                  <p>
                    <strong>
                      <FormattedMessage id="WA.PersonalRating.MyPersonalRating" />
                    </strong>
                  </p>
                </th>
                <th class="text-center" style={{ width: "25%" }}>
                  <img src={imageIpm2Universe} />
                  <p>
                    <strong>
                      <FormattedMessage id="WA.PersonalRating.iPM2Universe" />
                    </strong>
                  </p>
                </th>
                <th class="text-center" style={{ width: "25%" }}>
                  <img src={imageIconPlayers} />
                  <p>
                    <strong>
                      <FormattedMessage id="WA.PersonalRating.MyPlayerCircles" />
                    </strong>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderSkill("WA.LabelRatingPlayAgainWith", personal, "playAgainWith")}
              {renderSkill("WA.LabelRatingPunctual", personal, "punctual")}
              {renderSkill("WA.LabelRatingFairPlay", personal, "fairplay")}
              {renderSkill("WA.LabelRatingPersonal", personal, "personal")}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const TechnicalSkillBox = ({ technical, active }) => {
  if (!technical) return null;
  return (
    <div role="tabpanel" className={`tab-pane ${active ? "active" : ""}`}>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr class="table-header">
              <th />
              <th class="text-center" style={{ width: "25%" }}>
                <img src={imagePersonalRating} />
                <p>
                  <strong>
                    <FormattedMessage id="WA.PersonalRating.MyPersonalRating" />
                  </strong>
                </p>
              </th>
              <th class="text-center" style={{ width: "25%" }}>
                <img src={imageIpm2Universe} />
                <p>
                  <strong>
                    <FormattedMessage id="WA.PersonalRating.iPM2Universe" />
                  </strong>
                </p>
              </th>
              <th class="text-center" style={{ width: "25%" }}>
                <img src={imageIconPlayers} />
                <p>
                  <strong>
                    <FormattedMessage id="WA.PersonalRating.MyPlayerCircles" />
                  </strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {renderSkill("WA.LabelRating1stServe", technical, "firstServe")}
            {renderSkill("WA.LabelRating2ndServe", technical, "secondServe")}
            {renderSkill("WA.LabelRatingForehand", technical, "forehand")}
            {renderSkill("WA.LabelRatingBackhand", technical, "backhand")}
            {renderSkill("WA.LabelRatingCourtCoverage", technical, "courtCoverage")}
            {renderSkill("WA.LabelRatingReturnService", technical, "returnOfService")}
            {renderSkill("WA.LabelRatingNetPlay", technical, "netPlay")}
            {renderSkill("WA.LabelRatingReturnPlay", technical, "returnPlay")}
          </tbody>
        </table>
      </div>
    </div>
  );
};


class RatingsAndRankings extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "personal",
      personalSkill: [],
      technicalSkill: []
    };
    this.fm = props.intl.formatMessage;
  }
  
  componentDidMount() {
    const personal = getPersonalSkill();
    const personalCircle = getPersonalCircleSkill();
    const personalIPlayme2 = getPersonalIplayme2Skill();
    const technical = getTechSkills();
    const technicalCircle = getTechCircleSkills();
    const technicalIPlayme2 = getTechIplayme2Skills();
    const iplayme2SingleRank = getIPlayme2SingleRank();
    const iplayme2DoubleRank = getIPlayme2DoubleRank();
    
    const allPromise = Promise.all([
      personal,
      personalCircle,
      personalIPlayme2,
      technical,
      technicalCircle,
      technicalIPlayme2,
      iplayme2SingleRank,
      iplayme2DoubleRank
    ]);
    allPromise.then(
      ([
        personal,
        personalCircle,
        personalIPlayme2,
        technical,
        technicalCircle,
        technicalIPlayme2,
        iplayme2SingleRank,
        iplayme2DoubleRank
      ]) => {

        let sameGenderSingles = iplayme2SingleRank.data.filter((item)=>{
          let result = item.team.players.filter((user)=>{
            // eslint-disable-next-line
            return user.gender == this.props.user.gender;
          });
          return result.length === item.team.players.length;
        })
        let sameGenderDoubles = iplayme2DoubleRank.data.filter((item)=>{
          return !item.team.mixed && item.team.players[0].gender === this.props.user.gender
        })
        this.setState({
          ...this,
          personal: {
            user: personal.data,
            circle: personalCircle.data,
            iplayme2: personalIPlayme2.data
          },
          technical: {
            user: technical.data,
            circle: technicalCircle.data,
            iplayme2: technicalIPlayme2.data
          },
          
          iplayme2SingleRankSameGender: sameGenderSingles,
          iplayme2SingleRankMixedGender: iplayme2SingleRank.data,
          iplayme2DoubleRankMixedGender: iplayme2DoubleRank.data,
          iplayme2DoubleRankSameGender: sameGenderDoubles
        })
      }
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div class="container">
          <h1 class="title-section">
            <FormattedMessage id="WA.Title.Rankings&Ratings" />
          </h1>
          <nav class="menu-title hidden">
            <ul>
              <li>
                <a class="btn-gral" href="/profile">
                  <FormattedMessage id="WA.Title.Profile" />
                </a>
              </li>
              <li>
                <a class="btn-gral verde" href="ranking-rating.php">
                  <FormattedMessage id="WA.Title.Rankings&Ratings" />
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="container content-profile">
          {/*<!-- Nav tabs -->*/}
          <ul class="nav nav-tabs" role="tablist">
            <li
              role="presentation"
              className={this.state.activeTab === "personal" ? "active" : ""}
              onClick={() => this.setState({ ...this.state, activeTab: "personal" })}>
              <a href="#rating" aria-controls="home" role="tab" data-toggle="tab">
                <FormattedMessage id="WA.Title.PersonalRating" />
              </a>
            </li>
            <li
              role="presentation"
              className={this.state.activeTab === "technical" ? "active" : ""}
              onClick={() => this.setState({ ...this.state, activeTab: "technical" })}>
              <a href="#skills" aria-controls="profile" role="tab" data-toggle="tab">
                <FormattedMessage id="WA.PersonalRating.TechnicalSkillsRatings" />
              </a>
            </li>
            <li
              role="presentation"
              className={this.state.activeTab === "rankings" ? "active" : ""}
              onClick={() => this.setState({ ...this.state, activeTab: "rankings" })}>
              <a href="#/" aria-controls="profile" role="tab" data-toggle="tab">
                <FormattedMessage id="WA.Title.Rankings" />
              </a>
            </li>
          </ul>

          {/*<!-- Tab panes -->*/}
          <div class="tab-content">
            <PersonalRatingBox active={this.state.activeTab === "personal"} personal={this.state.personal} />
            <TechnicalSkillBox active={this.state.activeTab === "technical"} technical={this.state.technical} />
            <RankingsBox
              active={this.state.activeTab === "rankings"}
              iplayme2SingleRankSameGender={this.state.iplayme2SingleRankSameGender}
              iplayme2SingleRankMixedGender={this.state.iplayme2SingleRankMixedGender}
              iplayme2DoubleRankSameGender={this.state.iplayme2DoubleRankSameGender}
              iplayme2DoubleRankMixedGender={this.state.iplayme2DoubleRankMixedGender}
              currentUser={this.props.user}
            />
          </div>
        </div>
      </div>
    );
  }
}

RatingsAndRankings.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
  config: state.config
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RatingsAndRankings)
);
