import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import iconAlert from "./images/on-boarding/icon-alert.svg";
import iconEyePass from "./images/on-boarding/icon-eye-pass.svg";
import iconNewAccount from "./images/on-boarding/icon-newAccount.svg";
import iconReturningPlayer from "./images/on-boarding/icon-ReturningPlayer.svg";
import { parseQueryStringToObject } from "./utils";


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      fieldError: false,
      bounce: false,
      trigger: '',
      requestSent: false
    };
    this.showPassword = this.showPassword.bind(this);

    this.emailChanged = this.emailChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.fm = props.intl.formatMessage;
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <HeaderOnBoarding />

        <div className="container content-login">
          <div className="row">

            <div className="col-xs-12 col-md-8 col-md-offset-2">

              <div className="wrapperOnboard text-center">
                <div className="boxTitle">
                  <img src={iconReturningPlayer} alt="Returning Player" />
                  <h1 className="h3"><FormattedMessage id="WA.Landing.LoginWithEmail"/></h1>
                  <p><FormattedMessage id="WA.ReturningPlayer"/></p>
                </div>
                {this.renderHeader()}
                <div className="login-info text-left">
                    <form onSubmit={this.loginClicked}>
                        <div className={"form-group " + (this.state.emailError ? "has-error" : null)}>
                            <label htmlFor="exampleInputEmail1">
                            <FormattedMessage
                                id="WA.Landing.EmailAddress"
                            />
                            </label>
                            <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            required
                            placeholder={this.props.intl.formatMessage({ id: 'WA.Landing.EmailAddressPlaceholder' })}
                            onChange={this.emailChanged}
                            />
                        </div>
                        <div className={"form-group " + (this.state.passwordError ? "has-error" : null)}>
                            <div className="showPass" onClick={this.showPassword}><img src={iconEyePass} alt="Show Password" /> <span ref="textchange"><FormattedMessage id="WA.ShowPassword" /></span></div>
                            <label htmlFor="password">
                                <FormattedMessage id="WA.Landing.Password" />
                            </label>
                            <input
                            type="password"
                            className="form-control"
                            id="password"
                            required
                            placeholder={this.props.intl.formatMessage({ id: 'WA.Landing.EnterYourPassword' })}
                            onChange={this.passwordChanged}
                            />
                            <p className="help-block">
                            <Link to="/forgottenpassword">
                                <FormattedMessage id="WA.Landing.ForgotYourPassword?" />
                            </Link>
                            </p>
                        </div>
                        <div className="form-group">
                            <input
                            type="submit"
                            className="btn-gral verde"
                            onClick={this.loginClicked}
                            value={this.props.intl.formatMessage({ id: 'WA.Landing.LoginButton' })}
                            />
                        </div>
                    </form>

                </div>
                
              </div>


            </div>

            
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <Link className="newAccountCTA text-center" to="/signupsocial">
                  <img class="" src={iconNewAccount} alt="Create New Account" />
                  <h2 className="h3">
                    <FormattedMessage
                    id="WA.Landing.DontHaveAnAccountYet"
                    />
                  </h2>
                  <p><FormattedMessage id="WA.StartPlayingNow"/></p>
                  <span className="itisfree"><FormattedMessage id="WA.ItsFreeForever"/></span>
              </Link>
            </div>


          </div>
        </div>
      </>
    );
  }

  renderHeader  = () => {
    if(!this.state.requestSent) return;
    
    let bounce = this.state.bounce;
    if (bounce) {
      setTimeout(() => {
        this.setState({ bounce: false });
      }, 2000);
    }
    let errorMEssage = null;
    let errorC = "help-block";
    let bounceC = "anim-fade";

    if (this.state.fieldError) {
      errorMEssage = 'WA.Error.LoginField';
    } else if (this.props.loginError) {
      errorMEssage = 'WA.EmailLogin.Error';
    }

    if (errorMEssage) {
      return (<div className="alertBox media">
        <div className="media-left media-middle">
          <img src={iconAlert} alt="Warning" />
        </div>
        <div className="media-body">
          <span className={[errorC, bounceC].join(" ")}>
            <FormattedMessage id={errorMEssage} />
          </span>
        </div>
      </div>)
    } else {
      return null;
    }

  }

  showPassword() {
    let inputPass = document.getElementById('password');
    if (inputPass.type === "password") {
      inputPass.type = "text";
      this.refs.textchange.innerHTML = this.fm({ id: "WA.HidePassword" });
    } else {
      inputPass.type = "password";
      this.refs.textchange.innerHTML = this.fm({ id: "WA.ShowPassword" });
    }
  }

  emailChanged(event) {
    this.setState({ email: event.target.value });
  }

  passwordChanged(event) {
    this.setState({ password: event.target.value });
  }

  loginClicked(event) {
    event.preventDefault();
    if (this.state.password && this.state.email) {
      this.setState({ requestSent:true, fieldError: false, bounce: true });
      this.props.loginWithPassword(this.state.email, this.state.password);
    } else {
      this.setState({ fieldError: true, bounce: true });
    }
  }
  componentDidMount(){
    if(this.props.location.search){
      const query = parseQueryStringToObject(this.props.location.search);
      if(query.at){
        let variables = parseQueryStringToObject(atob(query.at));
        this.props.loginWithApple(variables.id, variables.fn, variables.ln);
      }
    }
    window.AppleID.auth.init({
        clientId: this.props.appleClientId,
        scope: 'name email',
        redirectURI: window.document.location.href.substring(0, window.document.location.href.lastIndexOf("/"))+"/api/appleback",
        state: (Math.random()*10000).toString()
    });
  }

  headerText(error, textKey, bounce) {

    let errorC = error ? "help-block" : "";
    let bounceC = bounce ? "anim-fade" : "";
    return (
        <span className={[errorC, bounceC].join(" ")}>
            <FormattedMessage id={textKey} />
        </span>
    )
  }

};

Login.propTypes = {
  fbAppId: PropTypes.string,
  getServerToken: PropTypes.func.isRequired,
  googleClientId: PropTypes.string,
  intl: intlShape.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginError: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  fbAppId: state.config.fbAppId,
  googleClientId: state.config.googleClientId,
  appleClientId: state.config.appleClientId,
  loggedIn: state.loggedIn,
  loginError: state.loginError
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getServerToken: (response, provider) => {
    dispatch({ type: 'SOCIAL_LOGIN_SUCCEED', provider, response });
  },
  loginWithPassword: (username, password) => {
    dispatch({ type: 'LOGIN_WITH_PASSWORD', username, password });
    
  },
  loginWithApple: (token) => {
    dispatch({ type: 'LOGIN_WITH_APPLE', token });
    
  }
  
  // ,
  // getConfig: () => {
  //   dispatch({ type: 'GET_CONFIG' })
  // }
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));