import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { store } from '../store'
import logoImp2Footer from '../images/logoFooter-white.svg'
import facebookImg from '../images/facebook.png'
import twitterImg from '../images/twitter.png'
import youtubeImg from '../images/youtube.png'
import linkedinImg from '../images/linkedin.png'
import ipm2Img from '../images/web.png'
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    if (params.get('lang')) {
      this.changeLanguage(params.get('lang'));
    }
  }

  changeLanguage(lang) {
    store.dispatch({ type: "SET_LANGUAGE", data: lang })
  }
  render() {
    let showGuide = true;
    if (this.props.location && this.props.location.pathname.startsWith('/unsub')) {
      showGuide = false;
    }
    return (

      <Provider store={store}>
        <>
          <footer>
            {showGuide &&
              <div className="banner-quick-start">
                <h3><FormattedMessage id="WA.Footer.Promo" /></h3>
                <a className="btn-gral" href="http://www.iplayme2.com/quick-start.html" target="_blank" rel="noopener noreferrer"><FormattedMessage id="WA.Footer.QuickStart" /></a>
              </div>
            }

            <div className="middleFooter">
              <div className="text-center">
                <Link to="/">
                  <img className="logoFooter" src={logoImp2Footer} alt="logo iPlayMe2" />
                </Link>
                <p><a href="https://iplayme2.com">www.iPlayMe2.com</a></p>
              </div>
              <ul className="redes-footer">
                <li><a href="http://fb.me/iPlayMe2" target="_blank" rel="noopener noreferrer"><img src={facebookImg} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/iPlayMe2" target="_blank" rel="noopener noreferrer"><img src={twitterImg} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/watch?v=DtcPhlBWM4s&feature=youtu.be" target="_blank" rel="noopener noreferrer"><img src={youtubeImg} alt="YouTube" /></a></li>
                <li><a href="https://www.linkedin.com/company/iplayme2" target="_blank" rel="noopener noreferrer"><img src={linkedinImg} alt="LinkedIn" /></a></li>
                <li className="hidden"><a href="http://www.iplayme2.com/" target="_blank" rel="noopener noreferrer"><img src={ipm2Img} alt="iPlayMe2" /></a></li>
              </ul>
            </div>
            <div className="lang">
              <ul>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('en') }}><FormattedMessage id="WA.Label.TennisTalk.English" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('es') }}><FormattedMessage id="WA.Label.TennisTalk.Spanish" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('fr') }}><FormattedMessage id="WA.Label.TennisTalk.Francais" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('it') }}><FormattedMessage id="WA.Label.TennisTalk.Italiano" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('de') }}><FormattedMessage id="WA.Label.TennisTalk.German" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('nl') }}><FormattedMessage id="WA.Label.TennisTalk.Nederlands" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('pt') }}><FormattedMessage id="WA.Label.TennisTalk.Portuguese" /></a></li>
                <li><a href="#/" onClick={(event) => { event.preventDefault(); this.changeLanguage('hu') }}><FormattedMessage id="WA.Label.TennisTalk.Hungarian" /></a></li>
              </ul>
            </div>
            <p className="copy text-center"><FormattedMessage id="SC.FooterRightReserved" values={{date:new Date().getUTCFullYear()}}/></p>
          </footer>
        </>
      </Provider>
    );
  }
}

export default Footer;