import React, { Component } from "react";


class GameSummaryField extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      open: props.open
    };
    this.cs = this.cs.bind(this);
  };
  cs() {
    this.setState({ open: !this.state.open });
  }
  render() {
    return (
      <div className="col-sm-12">
        <div className="cardGS" >
          <div className={`game-summary-field ${this.props.children[1]?"has-children":""}`} onClick={this.cs}>
            {this.props.children[0]}
            <div className="field-action" >
              {this.props.children[1] && 
              <>
                {this.state.open ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-right"></i>}
              </>
              }
            </div>
          </div>
          {this.props.children[1] &&
            <div className="game-summary-detail" style={{ display: this.state.open ? 'block' : 'none' }}>
              {this.props.children[1]}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default GameSummaryField;
