import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import * as Sentry from "@sentry/browser";

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: null,
      config: {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        osition: "bottom-right",
        autoClose: 5000
      }
    };
    const startLoading = props.startLoading;
    const stopLoading = props.stopLoading;
    axios.interceptors.request.use(function (config) {
      console.log("request started:"+config);
      startLoading();
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
    axios.interceptors.response.use((response) => {
      stopLoading();
      return response;
    }, (error) => {
      if (!error.response || error.response.status === 500
      ) {
        let errorCode = 'WA.ErrorServer';
        // if (error.response.status === 409) {
        //   errorCode = 'WA.BusinessError';
        // }
        
        toast.error(props.intl.formatMessage({ id: errorCode }, error.response.data), {
          ...this.state.config
        });
        console.error(error);
        Sentry.withScope(scope => {
          Object.keys(error).forEach(key => {
            scope.setExtra(key, error[key]);
          });
          Sentry.captureException(error);
        });
      } else if(error.response.status === 401){
        toast.error(props.intl.formatMessage({ id: "WA.PermissionDenied" }, error.response.data), {
          ...this.state.config
        });
      }
      return Promise.reject(error);
    });

  }

  render() {
    return (
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick pauseOnVisibilityChange draggable pauseOnHover />
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.state || !this.props.toast) {
      return;
    }
    const { key, type, params, config } = this.props.toast.data;
    if (!type || type === 'info') {
      toast.info(this.props.intl.formatMessage({ id: key }, params), {
        ...this.state.config,
        ...config
      });
    } else if (type === 'error') {
      toast.error(this.props.intl.formatMessage({ id: key }, params), {
        ...this.state.config,
        ...config
      });
    }
  }
}


const mapStateToProps = (state, ownProps) => ({
  toast: state.toast
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logOut: () => dispatch({ type: "LOGOUT" }),
  startLoading: () => dispatch({ type: "START_LOADING" }),
  stopLoading: () => dispatch({ type: "STOP_LOADING" })
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Toast));
