import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props){
    super(props);
    this.state= { show: false};
  }
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: '2em',
      zIndex:1000
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: this.props.maxWidth || 600,      
      margin: '0 auto',
      padding: this.props.padding || '1em'
    };
    const contentTable = {
      display: "table",
      width: "100%",
      minHeight: this.props.minHeight || 200
    }
    const contentCell ={
      paddingTop: this.props.paddingTop || "1em",
      textAlign: "center",
      verticalAlign: "middle",
      display: "table-cell"
    }
    return (
      <>
      <div style={backdropStyle}>
        <div style={modalStyle}>
        <a href="#/" onClick={this.props.onClose} style={{float:'right', padding:"0.5em"}}>X Close</a>
        <div style={contentTable}>
        <div style={contentCell}>
        {this.props.children}
        </div>
        </div>
        </div>
        </div>
      </>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool
};

export default Modal;