import { all, call, put, putResolve, takeLatest } from "redux-saga/effects";
import { getApplicationPreference, getServerToken, getUser, setAuth, signUp } from "./resource";

function* getServerTokenCall(action) {
  yield put({ type: "SAVE_SOCIAL_DATA", data: action.response });
  yield login(null, action.response.accessToken, action.provider);
}

function* loginWithPassword({ username, password }) {
  yield login(username, password, "iplayme2");
}
function* loginWithApple({ token, firstName, lastName }) {
  yield login("", token, "apple", firstName, lastName);
}
function* login(username, password, provider, firstName, lastName) { 
  try {
    const loginResponse = yield call(getServerToken, {
      username,
      password,
      provider,
      firstName,
      lastName
    });
    setAuth(loginResponse.data.access_token);
    let userData = yield putResolve({ type: "GET_USERDATA", data: loginResponse.data });
    console.log("userData:" + userData.data);
    yield put({ type: "GET_SERVER_TOKEN_SUCCEEDED", data: loginResponse.data });
  } catch (e) {
    yield put({ type: "GET_SERVER_TOKEN_FAILED", error: e.response.data });
    return;
  }
}

function* getUserData() {
  try{
    const userData = yield call(getUser);
    yield put({ type: "GET_USERDATA_SUCCEEDED", data: userData.data });

    try {
      let appLicationPreference = yield getApplicationPreference(userData.data.id);
      console.log("appLicationPreference:" + appLicationPreference.data);
      yield putResolve({ type: "GET_APP_DATA_SUCCEEDED", data: appLicationPreference.data });
    } catch (e) {
      if (e.response.status === 404) {
        console.debug("no ApplicationDataPreference.");
        yield putResolve({ type: "SET_DEFAULT_APP_CONFIG" });
      } else {
        throw e;
      }
    }
    return userData.data;
  }catch(e){ // in case of error 401, the user has to login again
    yield put({ type: "LOGOUT" });
  }  
}

function* logout(action) {
  sessionStorage.removeItem("tokenData");
  yield putResolve({ type: "LOGOUT_SUCCEEDED" });
  yield put({ type: "SET_DEFAULT_APP_CONFIG"});
}

function* signUpRequest(action) {
  try {
    const resp = yield call(signUp, action.userData);
    yield put({ type: "SIGNUP_SUCCESS", data: resp.data });
  } catch (e) {
    console.log(JSON.stringify(e));
    yield put({ type: "SIGNUP_FAILED", error: e.response.status });
  }
}


function* getServerTokenSaga() {
  yield takeLatest("SOCIAL_LOGIN_SUCCEED", getServerTokenCall);
}

function* loginWithPasswordSaga() {
  yield takeLatest("LOGIN_WITH_PASSWORD", loginWithPassword);
}
function* loginWithAppleSaga() {
  yield takeLatest("LOGIN_WITH_APPLE", loginWithApple);
}


function* getUserDataSaga() {
  yield takeLatest("GET_USERDATA", getUserData);
}

function* logoutSaga() {
  yield takeLatest("LOGOUT", logout);
}

function* signUpSaga() {
  yield takeLatest("SIGNUP_REQUEST", signUpRequest);
}


export default function* rootSaga() {
  yield all([
    getServerTokenSaga(),
    getUserDataSaga(),
    loginWithPasswordSaga(),
    logoutSaga(),
    signUpSaga(),
    loginWithAppleSaga()
  ]);
}
