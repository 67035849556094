import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import ErrorModal from '../comp/ErrorModal';
import HeaderExt from "../comp/HeaderExt";
import StopInviteUser from "../images/stop-invite-user.png";
import StopInvite from "../images/stop-invite.png";
import { getUnsubscribeData, postUnsubscribe } from "../resource";
import { parseMessageId, parseQueryStringToObject } from "../utils";


class GameUnsubscribe extends Component {
  constructor(props) {
    super(props);
    const query = parseQueryStringToObject(this.props.location.search);
    this.state = {
      token: query.t,
      passwordRef: query.pr,
      type: 'BLOCK_USER',
      simple: query.s === "true",
      open: true,
      errorMessage: null,
      user: {}
    };
    this.fm = props.intl.formatMessage;
    this.selectType = this.selectType.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }
  getUserName(user) {
    let name = "";
    if (user.firstName) {
      name = user.firstName;
    }
    if (user.lastName) {
      name += ' '+user.lastName;
    }
    return name;
  }
  componentDidMount() {
    // get target user data
    getUnsubscribeData(this.state.type, this.state.passwordRef, this.state.token).then((resp) => {
      this.setState({ ...this.state, user: resp.data.sourceUser });
    })
  }
  unsubscribe() {
    postUnsubscribe(this.state.type, this.state.passwordRef, this.state.token).then((result) => {
      this.setState({ ...this.state, open: false });
    }).catch((error) => {
      if(_.get(error, "response.data.error")!=="E-C39"){
        let errorMessage = this.fm({ id: parseMessageId(error) });
        this.setState({ ...this.state, open: true, errorMessage });
      }else{
        this.setState({ ...this.state, open: false });
      }
    });
  }
  selectType(event) {
    this.setState({ ...this.state, type: event.target.value });
  }
  render() {
    return (
      <div>
        <HeaderExt />
        <ErrorModal show={!!this.state.errorMessage} errorMessage={this.state.errorMessage} onClose={(e) => this.setState({ ...this.state, errorMessage: null })} />
        <div className="container content game-summary">
          <div className="row">
            <div className="col-sm-12">
              <div className="wrapper-unsubscribe" >

                {this.state.open ?
                  <>
                    <p class="text-5xl hidden"><i class="far fa-envelope-open"></i></p>
                    <h1 class="text-center">
                      <FormattedMessage id="UNSUB.Title" />
                    </h1>
                    <p class="text-base hidden">
                      <FormattedMessage id="UNSUB.Body" />
                    </p>
                    {!this.state.simple &&

                      <div>
                        <div class="wrapper-op">
                          <div class="box-option">
                            <label for="op1">
                              <div class="op-radio">
                                <input type="radio" name="unsub_type" id="op1" value="BLOCK_USER" checked={this.state.type === "BLOCK_USER"} onClick={this.selectType} />
                                <span class="radiobtn"></span>
                              </div>
                              <div class="op-label">
                                <span class="title-op"><FormattedMessage id="UNSUB.BlockUserTitle" values={{"targetUsername": this.getUserName(this.state.user)}}/></span>
                                <div class="box-option-description">
                                  <div class="icon">
                                    <img src={StopInviteUser} class="img-responsive" alt="stop invite user"/>
                                  </div>
                                  <div class="description">
                                    <p><FormattedMessage id="UNSUB.BlockUserDescription" /></p>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="box-option">
                            <label for="op2">
                              <div class="op-radio">
                                <input type="radio" name="unsub_type" id="op2" value="BLOCK_ALL_USER" checked={this.state.type === "BLOCK_ALL_USER"} onClick={this.selectType} />
                                <span class="radiobtn"></span>
                              </div>
                              <div class="op-label">
                                <span class="title-op"><FormattedMessage id="UNSUB.BlockEmailInvitationTitle" /></span>
                                <div class="box-option-description">
                                  <div class="icon">
                                    <img src={StopInvite} class="img-responsive" alt="stop invite"/>
                                  </div>
                                  <div class="description">
                                    <p><FormattedMessage id="UNSUB.BlockEmailInvitationDescription" /></p>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        <div class="box-option turn-off">
                          <label for="op3">
                            <div class="op-radio">
                              <input type="radio" name="unsub_type" id="op3" value="CHANNEL" checked={this.state.type === "CHANNEL"} onClick={this.selectType} />
                              <span class="radiobtn"></span>
                            </div>
                            <div class="op-label">
                              <span class="title-op"><FormattedMessage id="UNSUB.BlockEmailTitle" /></span>
                              <div class="box-option-description">
                                <div class="description">
                                  <FormattedMessage id="UNSUB.BlockEmailDescription" />
                                </div>

                              </div>
                            </div>
                          </label>
                        </div>


                      </div>

                    }

                    <p class="text-center">
                      <button type="button" class="btn-gral verde form-control" onClick={this.unsubscribe}><FormattedMessage id="WA.Button.Unsubscribe" /></button>
                    </p>

                  </>
                  :
                  <>
                    <p class="text-center text-5xl"><i class="far fa-envelope"></i></p>
                    <p class="text-center text-lg">
                      <FormattedMessage id="UNSUB.Body.Confirm" />
                    </p>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GameUnsubscribe.propTypes = {
  intl: intlShape.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GameUnsubscribe)
);
