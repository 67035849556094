import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import iconMenuMobile from "../images/icon-menu-mobile.png";
import logoIpm2 from "../images/logo-ipm2.png";
import ProfilePic from "./ProfilePic";
import rocketsImg from "../images/icon-rackets-scores.png";
import { withRouter } from "react-router-dom";
class Header extends Component {
  constructor(props, context) {
    super(props);

    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
    this.logOut = this.logout.bind(this);
    this.state = { sidebar: false };
    //this.navigate = this.navigate.bind(this);
    this.context = context;
  }

  logout() {
    this.props.logOut();
  }

  openNav() {
    this.setState({ ...this.state, sidebar: true });
  }

  closeNav() {
    this.setState({ ...this.state, sidebar: false });
  }
  // navigate(event, path) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.setState({...this.state, sidebar:false});
  //   this.props.history.push(path);
  // }

  render() {
    let isScoreBar = false;
    return (
      <>
        <div id="mySidenav" className="sidenav" style={this.state.sidebar ? { width: "100%" } : { width: "0px" }}>
          <a href="#/" className="closebtn" onClick={this.closeNav}>
            &times;
          </a>
          <Link to="/">
            <FormattedMessage id="WA.Title.MyMatches" />
          </Link>
          <NavLink to="/ratingsAndRankings">
            <FormattedMessage id="WA.Title.Rankings&Ratings" />
          </NavLink>
          <NavLink to="/friendsAndBalls">
            <FormattedMessage id="WA.Title.Friends+Balls" />
          </NavLink>
          {/* <NavLink to="/upgradeAccount">
            <FormattedMessage id="WA.Title.UpgradeAccount" />
          </NavLink> */}
          <a className="logout" href="#/" onClick={this.logOut}>
            <FormattedMessage id="WA.Title.LogOut" /> <i className="fa fa-chevron-right" aria-hidden="true" />
          </a>
        </div>
        <header>
          <div className="container-fluid">
            <div className="icon-menu-mobile">
              <a href="#/" onClick={this.openNav}>
                <img className="img-responsive" src={iconMenuMobile} alt="menu" />
              </a>
            </div>
            <div className="logo">
              <Link to="/">
                <img className="img-responsive" src={logoIpm2} alt="logo" />
              </Link>
            </div>
            <nav>
              <ul className="menu-ppal">
                <li>
                  <Link to="/">
                    <FormattedMessage id="WA.Title.MyMatches" />
                  </Link>
                </li>

                <li>
                  <NavLink to="/ratingsAndRankings">
                    <FormattedMessage id="WA.Title.Rankings&Ratings" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/friendsAndBalls">
                    <FormattedMessage id="WA.Title.Friends+Balls" />
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/upgradeAccount">
                    <FormattedMessage id="WA.Title.UpgradeAccount" />
                  </NavLink>
                </li> */}
              </ul>
              <div className="box-profile-top">
                <div className="txt-profile-top">
                  <p>
                    <strong>
                      <Link to="/profile">
                        <FormattedMessage
                          id="WA.Title.Hi!name"
                          values={{
                            firstName: this.props.user.firstName,
                            lastName: this.props.user.lastName
                          }}
                        />
                      </Link>
                    </strong>
                  </p>
                  <p>
                    <a className="bt-view-profile hidden" href="/#">
                      View Profile
                    </a>
                    <a className="bt-view-profile" href="#/" onClick={this.logOut}>
                      <FormattedMessage id="WA.Title.LogOut" />
                      <i className="fa fa-chevron-right" aria-hidden="true" />
                    </a>
                  </p>
                </div>
                <div className="pic-profile"><Link to="/profile">
                    <ProfilePic url={this.props.user.profileImageUrl} />
                  </Link></div>
              </div>
            </nav>
          </div>
        </header>
        <div className="score-bar">
          {isScoreBar && (
            <>
              <div className="swiper-scores" id="swiperScore">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="box-slide">
                      <p>
                        <strong>S. Enblitse</strong> 6-4, 6-1, 7-5 <span className="loser">A. Jensen</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination" />
              </div>
              <div className="box-btns">
                <a className="btn-toggle" href="/#">
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </a>
                <div className="title">
                  <p>Other's Match Results</p>
                </div>
                <img className="img-responsive" src={rocketsImg} alt="rocket" />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
  componentDidMount(){
    if(!window.document.getElementById("root").classList.contains("bg-webaccess")){
      window.document.getElementById("root").classList.add("bg-webaccess");
    }
  }
}

Header.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData || {}
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserData: () => dispatch({ type: "GET_USERDATA" }),
  logOut: () => dispatch({ type: "LOGOUT" })
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Header))
);
