import React from 'react';
import imageStar from "./images/star.png";

export function generateYears() {
  const nowDate = new Date();
  const arr = [];
  for (let i = 1940; i < nowDate.getUTCFullYear() - 3; i++) {
    arr.push(i);
  }
  return arr;
}

//let formatterMonth = new Intl.DateTimeFormat("en-GB", { month: "2-digit"});
//let formatterDay = new Intl.DateTimeFormat("en-GB", { day: "2-digit"});
export function calendarPreferenceFix(calendar) {
  return calendar.map((c) => {
    c.local = {};
    c.local.from = new Date(c.date + 'T' + c.fromTime+'Z' );
    c.local.to = new Date(c.date + 'T' + c.toTime+'Z' );

    c.local.date = c.date;
    c.local.fromTime = c.fromTime;
    c.local.toTime = c.toTime;
    return c;
  });
}

export function renderStars(rank){
  let stars = [];
  for (let i = 1; i <= 6; i++) {
    stars.push(<img src={imageStar} alt="star" class={`star${i <= rank ? " active" : ""}`} />);
  }
  return stars;
};

export function getTimeByMinutes(minute){
  let h = parseInt(minute / 60);
  let m = minute % 60;
  h = (h+"").length===1?"0"+h:h;
  m = (m+"").length===1?"0"+m:m;
  return h+":"+m;
}
export function getMinutesByTime(time){
  let parts = time.split(":");
  return parseInt(parts[0]) * 60 + parseInt(parts[1]);
};
export function utcIsoDateTimeString(date, hour, minute){
  let m = date.getUTCMonth()+1;
  let d = date.getUTCDate();
  hour = hour || '00';
  minute = minute || '00';

  hour = (""+hour).length === 1? "0"+hour:hour;
  minute = (""+minute).length === 1? "0"+minute:minute;

  m = (""+m).length === 1? "0"+m:m;
  d = (""+d).length === 1? "0"+d:d;
  return date.getUTCFullYear()+"-"+m+"-"+d+"T"+hour+":"+minute+"Z";
}
export function utcDateString(date){
  let m = date.getUTCMonth()+1;
  let d = date.getUTCDate();
  m = (""+m).length === 1? "0"+m:m;
  d = (""+d).length === 1? "0"+d:d;
  return date.getUTCFullYear()+"-"+m+"-"+d;
}
export function utcTimeString(date){
  let hour = date.getUTCHours();
  let minute = date.getUTCMinutes();
  hour = (""+hour).length === 1? "0"+hour:hour;
  minute = (""+minute).length === 1? "0"+minute:minute;
  return hour+":"+minute;
}
export function parseDate(date){
  var d = new Date('2020-01-10');
  d.setYear(parseInt(date.substring(0,4)));
  d.setMonth(parseInt(date.substring(5,7))-1);
  d.setDate(parseInt(date.substring(8,10)));
  return d;
}
export function timeToHStyle(time, isAMPM){
  const timeArray = time.split(":");
  let ampm = '';
  let hour = timeArray[0];
  if(isAMPM){
    ampm = timeArray[0]>12?' PM':' AM';
    hour = timeArray[0]>12?timeArray[0]-12:timeArray[0];
  }
  return parseInt(hour)+":"+timeArray[1]+ampm;
}
export function formatUserName(user){
  if (user) {
    let name = user.firstName;
    if (user.lastName) {
      name += ' '+user.lastName;
    }
    return name;
  }
  return '';
}
export function parseQueryString(query){
  let data;
  if(query){
    if(query.startsWith("?")){
      query = query.substring(1);
    }
    data = query.split("&");
    data = data.map( i => {
      let v = i.split("=");
      return {key: v[0], value: v[1]};
    });
  }
  return data;
}
export function parseQueryStringToObject(query){
  const data = parseQueryString(query);
  const res = {};
  data.forEach(element => {
    res[element.key] = element.value;
  });
  return res;
}
export function parseMessageId(error){
  let messageId = "E.gen.serverError";
  if (error.response && error.response.data && error.response.data.messageId) {
    messageId = error.response.data.messageId;
  }
  return messageId;
}

export function getLocale(intl){
  let language = intl.locale;
  if(window.navigator.language.length===5){
    language = language+"-"+window.navigator.language.substring(3,5);
  }
  return language;
}