import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React from "react";
import Header from "../comp/Header";
import checkSubscription from "../images/check-suscription.png";
import iconPlayIt from "../images/icon-features-play-it.png";
import checkForever from "../images/check-forever.png";
import iconLoveIt from "../images/icon-features-love-it.png";
import iconLiveIt from "../images/icon-features-live-it.png";
import iconOwnIt from "../images/icon-features-own-it.png";
import pelotaBalls from "../images/icon-pelota-balls.png";
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";
import { getPlans, requestMembershipUpgrade, requestMembershipConfirm } from "../resource";
import CommonComponent from "./CommonComponent";
import PaymentModal from "../comp/PaymentModal";
import _ from "lodash";
import Modal from '../comp/Modal';

// TODO 1. the next step is to finish the sending of the confirmationm code back to the backend. 
// TODO 2. diplaying and validating the list of available payment options based on the query from the backend, disable trial if there are already used 
// TODO 3. displaying bonus days

class UpgradeAccount extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      selectedOption: null,
      selectedPaymentMode: null,
      giveOneTimePassword: false
    };
    this.fm = props.intl.formatMessage;
    this.otp = React.createRef();
  }

  playItFeature(key, count, icon) {
    let item = [];
    for (let i = 1; i <= count; i++) {
      item.push(i);
    }
    return item.map(i => {
      let id = key + i;
      return (
        <li>
          <div className="icon-feature">
            <img src={icon} alt="Icon play it" />
          </div>
          <div className="copy-feature">
            <FormattedHTMLMessage id={id} tagName="p" />
          </div>
        </li>
      );
    });
  }
  paymentOption(planName) {
    let planArray = this.state.plans[planName];
    if (planArray) {
      let p = _.groupBy(planArray, item => item.period);
      let hiddenPaymentMode = true;
      let currency = null;
      let planOptions = Object.keys(p).map(intervall => {
        let periodLabel = "";
        let period = p[intervall][0].period;
        currency = p[intervall][0].currency;
        let amount = p[intervall][0].amount;
        let balls = p[intervall][0].balls;
        let optionId = p[intervall][0].id;

        if (period === 1) {
          periodLabel = this.fm({ id: "WA.UP.LabelMonthTrial" });
        } else if (period === 3) {
          periodLabel = this.fm({ id: "WA.UP.LabelQuarter" });
        } else {
          periodLabel = this.fm({ id: "WA.UP.LabelYear" });
        }

        let cn = "box-price";
        let clickEvent = () => {};
        if (this.props.user.membershipLevel === planName && this.props.user.membershipPaymentPeriod === period) {
          cn += " current-price";
        } else if (this.state.selectedOption === optionId) {
          cn += " selected";
          hiddenPaymentMode = false;
        } else {
          clickEvent = e => this.setState({ ...this.state, selectedOption: optionId });
        }

        return (
          <div className={cn} onClick={clickEvent}>
            <p className="price">
              <b>
                <span>{currency}</span>
                {amount}
              </b>
            </p>
            <small>{periodLabel}</small>
            <p className="price-balls">
              <b>
                <FormattedMessage id="WA.UP.Label.orBalls" values={{ balls: balls }} />
              </b>
            </p>
          </div>
        );
      });

      return (
        <>
          <div className="wrapper-price ">{planOptions}</div>
          <div className={'pay-method'+(hiddenPaymentMode?' hidden':'')}>
            <h2>
              <FormattedMessage id="WA.UP.Label.HowDoYouWantToPay?" />
            </h2>
            <div className="wrapper-bt-group">
              <div className="bt-group">
                <a
                  className="btn-gral azul"
                  href="/#"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ ...this.state, selectedPaymentMode: "MONEY" });
                    this.postUpgrade(this.state.selectedOption, currency);
                  }}>
                  <FormattedMessage id="WA.UP.ButtonMoney" />
                </a>
              </div>
              <div className="bt-group">
                <a
                  className="btn-gral azul"
                  href="/#"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ ...this.state, selectedPaymentMode: "BALLS" });
                    this.postUpgrade(this.state.selectedOption, 'IPB');
                  }}>
                  <FormattedMessage id="WA.UP.ButtonBalls" />
                </a>
                <p>
                  <a className="to-ancla" href="#ballsUpgrade">
                    <FormattedMessage id="WA.UP.Label.BallsWhatIsThis?" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  selectMethod(event) {
    event.preventDefault();
    let plan = event.target.id;

    if (plan && plan.startsWith("plan_")) {
      this.setState({ ...this.state, selectedMethod: plan.substring(5) });
    }
  }
  currentSubscription(plan) {
    if (this.props.user.membershipLevel === plan) {
      return (
        <div className="current">
          <FormattedMessage id="WA.Upgrade.CurrentSubscription" />
          <img src={checkSubscription} alt="check subscription" />
        </div>
      );
    }
  }
  currentOptionSubscription(plan, period) {
    if (this.props.user.membershipLevel === plan) {
      return (
        <div className="current">
          <FormattedMessage id="WA.Upgrade.CurrentSubscription" />
          <img src={checkSubscription} alt="check subscription" />
        </div>
      );
    }
  }
  postUpgrade(planId, currency){
    requestMembershipUpgrade(planId, currency).then().catch((error)=>{
      if(error.response && error.response.status===409){
        alert(this.fm({ id: "WA.Upgrade.WithBallsError"},{...error.response.data }));
      }
      return false;
    });
  }
  postUpgradeConfirm(){
    requestMembershipConfirm(this.otp).then((result)=>{

    })
  }
  componentDidMount() {
    getPlans().then(result => {
      let groupedByPlan = _.groupBy(result.data, item => item.membershipLevel);
      this.setState({ plans: groupedByPlan });
    });
  }

  render() {
    return (
      <div>
        <Header />
        {this.state.giveOneTimePassword && 
        <Modal>
        <FormattedMessage id="WA.Upgrade.GiveTheOneTimePasswordFromYourEmail" />
        <input type="text" ref={this.otp}/><button onClink={this.confirmUpgrade()}><FormattedMessage id="WA.Upgrade.ConfirmUpgrade" /></button>
        </Modal>
        }
        {this.state.selectedOption && 
        <PaymentModal
          show={this.state.selectedOption != null && this.state.selectedPaymentMode === "MONEY"}
          plan={this.state.selectedOption}
          onClose={() => this.setState({ ...this.state, selectedPaymentMode: null })}
        />
        }
        <div className="container content">
          <div className="title-upgrade text-center">
            <p>
              <FormattedMessage
                id="WA.Upgrade.YourCurrentSubscriptionIs"
                values={{ level: "Live IT", expiration: "12-Oct-2017" }}
              />
            </p>
            <h1>
              <FormattedMessage id="WA.Upgrade.UpgradeAccount" />
            </h1>
          </div>

          <div className="row-col-upgrade">
            <div className="col-upgrade">
              {this.currentSubscription(null)}
              <div className="box-upgrade">
                <div className="pleno play-it">
                  <div className="icon-upgrade playit" />
                </div>

                <div className="wrapper-info-upgrade">
                  <h2>Play It</h2>
                  <ul className="features">{this.playItFeature("WA.MEM.PlayItFeature", 3, iconPlayIt)}</ul>
                </div>
                <div className="free text-center">
                  <FormattedMessage id="WA.MEM.ButtonPlayIt1" />
                  <span>
                    <FormattedMessage id="WA.MEM.ButtonPlayIt2" tagName="p" />
                  </span>
                  <img src={checkForever} alt="Check forever" />
                </div>
              </div>
            </div>

            <div className="col-upgrade">
              {this.currentSubscription("LOVEIT")}
              <div className="box-upgrade">
                <div className="pleno love-it">
                  <div className="icon-upgrade loveit" />
                </div>
                <div className="wrapper-info-upgrade">
                  <h2>Love It</h2>
                  <ul className="features">{this.playItFeature("WA.MEM.LoveItFeature", 4, iconLoveIt)}</ul>
                </div>
                {this.paymentOption("LOVEIT")}
              </div>
            </div>

            <div className="col-upgrade">
              {this.currentSubscription("LIVEIT")}
              <div className="box-upgrade">
                <div className="pleno live-it">
                  <div className="icon-upgrade liveit" />
                </div>

                <div className="wrapper-info-upgrade">
                  <h2>Live It</h2>
                  <ul className="features">{this.playItFeature("WA.MEM.LiveItFeature", 4, iconLiveIt)}</ul>
                </div>
                {this.paymentOption("LIVEIT")}
              </div>
            </div>
            <div className="col-upgrade">
              {this.currentSubscription("OWNIT")}
              <div className="box-upgrade">
                <div className="pleno own-it">
                  <div className="icon-upgrade ownit" />
                </div>

                <div className="wrapper-info-upgrade">
                  <h2>Own It</h2>
                  <ul className="features">{this.playItFeature("WA.MEM.OwnItFeature", 4, iconOwnIt)}</ul>
                </div>
                {this.paymentOption("OWNIT")}
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-balls-upgrade" id="ballsUpgrade">
          <div className="foto" />
          <div className="info-ball-upgrade">
            <h3>
              <FormattedMessage id="WA.FB.EarnBalls!" /> <img src={pelotaBalls} alt="Pelota balls" />
            </h3>
            <p>
              <FormattedMessage id="WA.FB.Text.ExplanationOfBalls" />
            </p>
            <Link to="/friendsAndBalls" className="btn-gral">
              <FormattedMessage id="WA.UP.Button.StartNow" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

UpgradeAccount.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
  config: state.config
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpgradeAccount)
);
