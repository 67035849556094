import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";


export default () => (
    <div className="carousel-banner">
        <Carousel 
            autoPlay
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            useKeyboardArrows={false}
            stopOnHover={false}
            infiniteLoop={true}
            transitionTime={0}
            interval={800}
        >
            <div style={{color: "#F7BCFF"}}>Tennis</div>
            <div style={{color: "#FCA6A6"}}>Pickleball</div>
            <div style={{color: "#FCFDBA"}}>Padel</div>
            <div style={{color: "#BBFFC6"}}>Squash</div>
            <div style={{color: "#6FEEFF"}}>Badminton</div>
            <div style={{color: "#F7BCFF"}}>Racquetball</div>
            <div style={{color: "#FCA6A6"}}>Paddle Ball</div>
            <div style={{color: "#FCFDBA"}}>Court Tennis</div>
            <div style={{color: "#BBFFC6"}}>Table Tennis</div>
            <div style={{color: "#6FEEFF"}}>Platform tennis</div>
            <div style={{color: "#FFDC97"}}>Paddle Tennis</div>
        </Carousel>
    </div>
  );

