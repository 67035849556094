import createSagaMiddleware from "redux-saga";
import { compose, createStore, applyMiddleware } from "redux";
import reducer from "./reducer";
import saga from "./saga";
import {setAuth} from "./resource";

export const initialState = Object.freeze({
  loggedIn: false,
  config: {},
  signUpSuccess: false,
  forgottenPasswordSuccess: null
});

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let initState = getInitialState();
export const store = createStore(reducer, initState, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(saga);

if (initState.loggedIn) {
  store.dispatch({ type: "GET_USERDATA" });
} else {
  store.dispatch({ type: "SET_DEFAULT_APP_CONFIG" });
}

function getInitialState() {
  const stored = sessionStorage.getItem("tokenData");
  const ret = { ...initialState };
  if (stored) {
    ret.loggedIn = true;
    ret.serverToken = JSON.parse(stored);
    setAuth(ret.serverToken.access_token);
  }
  return ret;
}
