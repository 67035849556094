import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect} from "react-router-dom";
import { saveProfile } from "../resource";
import Header from '../comp/Header';
import ProfileBox from '../comp/ProfileBox';
import InputText from '../comp/InputText.js';
import ProfileMainEmailBox from '../comp/ProfileMainEmailBox';
import PasswordInputs from '../comp/PasswordInputs';
import InputRadio from '../comp/InputRadio';
import InputSelect from '../comp/InputSelect';
import { generateYears } from '../utils';
import InputCheckbox from '../comp/InputCheckbox';
import ErrorModal from '../comp/ErrorModal';

class ProfileEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editSuccess: false,
      firstName: "",
      lastName: "",
      emails: null,
      password: null,
      gender: null,
      yearOfBirth: null,
      forehand: null,
      backhand: null,
      location: null,
      tennisTalks: [],
      errorMessage: null
    }

    this.updateValue = this.updateValue.bind(this);
    this.save = this.save.bind(this);
    this.fm = props.intl.formatMessage;
  }

  componentDidMount() {
    if (!this.props.user) {
      this.props.getUserData();
    }
  }

  render() {
    if (!this.props.user) {
      return null;
    }
    if (this.state.editSuccess) {
      return <Redirect to="/profile/success" />
    }
    let genderKey = "WA.UnknownProfile";
    if (this.props.user.gender) {
      genderKey = `WA.EditProfile.Gender.${this.props.user.gender}`;
    }
    let forehandKey = "WA.UnknownProfile"
    if (this.props.user.forehand) {
      forehandKey = `WA.EditProfile.ForehandStyle.${this.props.user.forehand}`;
    }
    let backhandKey = "WA.UnknownProfile"
    if (this.props.user.backhand) {
      backhandKey = `WA.EditProfile.BackhandStyle.${this.props.user.backhand}`;
    }
    return (
      <div>
        <Header />
        <div className="container">
          <h1 className="title-section">
            <FormattedMessage id="WA.EditProfile.EditMyProfile.Header" />
          </h1>
        </div>
        <ErrorModal show={this.state.errorMessage} errorMessage={this.state.errorMessage} onClose={(e) => this.setState({ ...this.state, errorMessage: null })} />
        <div className="container content">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="wrapper-info-profile">
                <div className="row">
                  <form>
                    <div className="col-xs-12 col-md-6 col-boxes-info">

                      <ProfileBox titleKey="WA.EditProfile.FirstName" value={this.props.user.firstName}>
                        <div className="wrapper-radio-check">
                          <div className="form-group">
                            <InputText
                              placeholder="WA.SignUpFirstNamePlaceholder"
                              name="firstName"
                              className="form-control"
                              validator={this.noop}
                              onValidated={this.noop}
                              valid={this.noop}
                              notValid={this.noop}
                              onChange={this.updateValue}
                            />
                          </div>
                        </div>
                      </ProfileBox>

                      <ProfileBox titleKey="WA.EditProfile.LastName" value={this.props.user.lastName}>
                        <div className="wrapper-radio-check">
                          <div className="form-group">
                            <InputText
                              placeholder="WA.SignUpLastNamePlaceholder"
                              name="lastName"
                              className="form-control"
                              validator={this.noop}
                              onValidated={this.noop}
                              valid={this.noop}
                              notValid={this.noop}
                              onChange={this.updateValue}
                            />
                          </div>
                        </div>
                      </ProfileBox>

                      <ProfileMainEmailBox
                        name="emails"
                        user={this.props.user}
                        onChange={this.updateValue}
                      />

                      <ProfileBox titleKey="WA.Landing.Password" value="********">
                        <div className="wrapper-radio-check">
                          <PasswordInputs name="password" onChange={this.updateValue} withoutLabel />
                        </div>
                      </ProfileBox>

                      <ProfileBox titleKey="WA.Title.Location" value={this.props.user.location} valueKey="WA.UnknownProfile">
                        <div className="wrapper-radio-check">
                          <div className="form-group">
                            <InputText
                              placeholder="WA.EditProfile.HomeCity"
                              name="location"
                              className="form-control"
                              validator={this.noop}
                              onValidated={this.noop}
                              valid={this.noop}
                              notValid={this.noop}
                              onChange={this.updateValue}
                            />
                          </div>
                        </div>
                      </ProfileBox>
                      
                      <ProfileBox titleKey="WA.EditProfile.Gender" valueKey={genderKey}>
                        <InputRadio
                          name="gender"
                          onChange={this.updateValue}
                          value={this.state.gender}
                          options={[
                            {
                              value: 'MALE',
                              labelKey: 'WA.EditProfile.Gender.MALE'
                            },
                            {
                              value: 'FEMALE',
                              labelKey: 'WA.EditProfile.Gender.FEMALE'
                            }
                          ]}
                        />
                      </ProfileBox>

                      <ProfileBox titleKey="WA.EditProfile.YearOfBirth" value={this.props.user.yearOfBirth} valueKey="WA.UnknownProfile">
                        <div className="wrapper-radio-check">
                          <InputSelect
                            name="yearOfBirth"
                            onChange={this.updateValue}
                            value={this.state.yearOfBirth}
                            options={generateYears().map(year => ({
                              value: year,
                              label: year.toString()
                            }))}
                          />
                        </div>
                      </ProfileBox>
                    </div>

                    <div className="col-xs-12 col-md-6 col-boxes-info">

                      <ProfileBox titleKey="WA.EditProfile.ForehandStyle" valueKey={forehandKey}>
                        <InputRadio
                          name="forehand"
                          onChange={this.updateValue}
                          value={this.state.forehand}
                          options={[
                            {
                              value: 'RIGHTHANDED',
                              labelKey: 'WA.EditProfile.ForehandStyle.RIGHTHANDED'
                            },
                            {
                              value: 'LEFTHANDED',
                              labelKey: 'WA.EditProfile.ForehandStyle.LEFTHANDED'
                            }
                          ]}
                        />
                      </ProfileBox>

                      <ProfileBox titleKey="WA.EditProfile.BackhandStyle" valueKey={backhandKey}>
                        <InputRadio
                          name="backhand"
                          onChange={this.updateValue}
                          value={this.state.backhand}
                          options={[
                            {
                              value: 'ONEHANDED',
                              labelKey: 'WA.EditProfile.BackhandStyle.ONEHANDED'
                            },
                            {
                              value: 'TWOHANDED',
                              labelKey: 'WA.EditProfile.BackhandStyle.TWOHANDED'
                            }
                          ]}
                        />
                      </ProfileBox>
                          
                      <ProfileBox titleKey="WA.EditProfile.TennisTalk" value={this.props.user.tennisTalks.join(' - ')} valueKey="WA.UnknownProfile">
                        <div className="row">
                          <InputCheckbox
                            name="tennisTalks"
                            onChange={this.updateValue}
                            values={this.state.tennisTalks.length>0 ? this.state.tennisTalks: this.props.user.tennisTalks}
                            options={['EN', 'ES', 'FR', 'DE', 'IT', 'NL', 'PT', 'HU'].map(lang => ({
                              value: lang,
                              labelKey: `profileedit.${lang}`
                            }))}
                          />
                        </div>
                      </ProfileBox>

                      <div className="edit">
                        <a className="btn-gral verde" onClick={this.save} href="/#" >
                          <i className="fa fa-floppy-o" aria-hidden="true"/>
                          &nbsp;Save
                        </a>
                        <Link className="btn-gral" to="/profile">
                          <FormattedMessage id="WA.EditProfile.CancelChanges" />
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  noop() {
    return true;
  }

  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }

  save(e) {
    e.preventDefault();
    let promoise = saveProfile({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emails: this.state.emails,
      password: this.state.password,
      gender: this.state.gender,
      yearOfBirth: this.state.yearOfBirth,
      forehand: this.state.forehand,
      backhand: this.state.backhand,
      location: this.state.location,
      tennisTalks: this.state.tennisTalks
    });

    promoise.catch(error => {
      this.setState({...this.state, errorMessage: this.fm({id: error.response.data.message})});
      console.log(error);
    })
    promoise.then(result =>{
      this.props.getUserData();
      this.setState({
        editSuccess: true
      });
    });
  }
}

ProfileEdit.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserData: () => dispatch({ type: "GET_USERDATA" })
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProfileEdit));
