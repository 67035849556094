import React from "react";

export function opponentScore(type, score) {
  switch (type) {
    case "NORMAL":
      if (score < 5) {
        return [6];
      } else if (score === 5) {
        return [7];
      } else if (score === 6) {
        return [0, 1, 2, 3, 4, 7];
      } else if (score === 7) {
        return [5, 6, 9];
      } else if (score > 7 && score < 10) {
        return [score + 2, score - 2];
      } else if (score === 10) {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 12];
      } else {
        return [score - 2, score + 2];
      }
    case "TB":
      if (score < 6) {
        return [7, 10];
      } else if (score === 6) {
        return [10];
      } else if (score === 7) {
        return [0, 1, 2, 3, 4, 5, 9, 10];
      } else if (score > 7 && score < 10) {
        return [score + 2, score - 2];
      } else if (score === 10) {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 12];
      } else {
        return [score - 2, score + 2];
      }
    case "P10":
      if (score < 10) {
        return [10];
      } else {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      }
    case "P8":
      if (score < 8) {
        return [8];
      } else {
        return [0, 1, 2, 3, 4, 5, 6, 7];
      }
    default:
      break;
  }
  return null;
}


export function validateScore(
    validScoreList,
    inGame,
    iSet,
    currentSide,
    otherSide,
    setNr
) {
    if (iSet && parseInt(iSet[otherSide]) >= 0) {

        let possibleValues = opponentScore(iSet.type, iSet[otherSide]);
        console.log("type:" +iSet.type + " i:" + setNr + " gamSet:" + JSON.stringify(iSet) + " otherSide:" + otherSide + " = " + possibleValues );
        if (possibleValues) {
            let foundValue = possibleValues.find((item) => {
                return item === parseInt(iSet[currentSide]);
            });
            console.log("current side: "+currentSide+"="+iSet[currentSide]+" > foundValue="+foundValue);
            if (foundValue >= 0 || inGame.playedStatus==="TIMEEXPIRED") {
                console.log("foundValue:" + foundValue + " i:" + setNr + " currentSide:" + currentSide + " true");
                validScoreList[inGame.id][setNr][currentSide] = [];
                return true;
            } else {
                console.log("foundValue:" + foundValue + " i:" + setNr + " currentSide:" + currentSide + " false");
                validScoreList[inGame.id][setNr][currentSide] = possibleValues;
                return false;
            }
        }
    }
}



export function onChangeScore(
    state,
    inGame,
    currentSide,
    otherSide,
    target,
    setIndex
) {
    let set = inGame.gameSet[setIndex];
    let validScoreList = state.validScoreList;
    console.log("on change target.value:"+target.value);
    if (parseInt(target.value) < 50 || target.value === "") {
        if (target.value !== "") {
            set[currentSide] = parseInt(target.value);
        } else {
            set[currentSide] = "";
        }

        let valid = true;
        // set type of sets for editables
        let points = { initiatorScore: 0, opponentScore: 0 };

        for (let setNr = 0; setNr < inGame.gameSet.length; setNr++) {
            let iSet = inGame.gameSet[setNr];
            let iPrevSet = inGame.gameSet[setNr - 1];

            let type = "NORMAL";
            if (
                (setNr !== 0 &&
                    Math.abs(iPrevSet[currentSide] - iPrevSet[otherSide]) === 1) ||
                iSet[currentSide] > 7 ||
                iSet[otherSide] > 7
            ) {
                // define the type of this set
                type = "TB";
            }

            // calculate winner
            if (!isNaN(iSet[otherSide]) && !isNaN(iSet[currentSide])) {
                // in case of TB we don't count the current set
                if (Math.abs(iSet[currentSide] - iSet[otherSide]) === 1) {
                    console.log("pass " + setNr + " set from winner calculation");
                } else {
                    if (iSet[currentSide] > iSet[otherSide]) {
                        points[currentSide] += 1;
                    } else {
                        points[otherSide] += 1;
                    }
                }
            }

            iSet.type = type;
            iSet.ord = setNr + 1;
            valid = valid && validateScore(
                validScoreList,
                inGame,
                iSet,
                currentSide,
                otherSide,
                setNr
            );
        }
        if (points.initiatorScore === points.opponentScore) {
            inGame.winner = "UNKNOWN";
        } else {
            inGame.winner =
                points.initiatorScore > points.opponentScore ? "INITIATOR" : "OPPONENT";
        }
        console.log("score valid:"+valid);
        if (valid) {
            // if both sides has value then we add a new set
            if (
                setIndex === inGame.gameSet.length - 1 &&
                parseInt(set[currentSide]) >= 0 &&
                parseInt(set[otherSide]) >= 0
            ) {
                let type = "NORMAL";
                if (
                    setIndex !== 0 &&
                    Math.abs(set[currentSide] - set[otherSide]) === 1
                ) {
                    type = "TB";
                }
                inGame.gameSet.push({
                    initiatorScore: "?",
                    opponentScore: "?",
                    type: type,
                });
                validScoreList[inGame.id].push({
                    initiatorScore: [],
                    opponentScore: [],
                });
            }
        }
        let returnState = { ...state, validScoreList };
        return returnState;
    }
    return state;
}


export function onFocusScore (
    state,
    inGame,
    currentSide,
    otherSide,
    target,
    setIndex,
    change
) {
    let set = inGame.gameSet[setIndex];
    let prevSet = inGame.gameSet[setIndex - 1];
    if (target.value === "?") {
        target.value = "";
    }
    // calcualte possible values
    // set Type
    let type = "NORMAL";
    if (
        setIndex !== 0 &&
        set &&
        Math.abs(prevSet[currentSide] - prevSet[otherSide]) === 1
    ) {
        // define the type of this set
        type = "TB";
    }
    validateScore(
        state.validScoreList,
        inGame,
        set,
        currentSide,
        otherSide,
        setIndex
    );
    if (set && parseInt(set[otherSide]) > 0) {
        let pv = opponentScore(type, set[otherSide]);
        if (pv && pv.length === 1) {
            // if there is just one possible value we set it
            target.value = pv[0];
            change = true;
        } else if (pv && pv.length > 1) {
            console.log(pv);
        }
    }
    if (change) {
        return onChangeScore(
            state,
            inGame,
            currentSide,
            otherSide,
            target,
            setIndex
        );
    }
}

export function renderScore(state, changeState, inGame, currentSide, me) {
    let list = inGame.gameSet;
    if (list === null || list.length === 0) return null;
    let otherSide =
      currentSide === "initiatorScore" ? "opponentScore" : "initiatorScore";
    let content = [];
  
    for (var nrOfSet = 0; nrOfSet < list.length;) {
      let currentSetIndex = nrOfSet;
      let className = "set-circle";
      if (list[nrOfSet][currentSide] > list[nrOfSet][otherSide]) {
        // we check whether the current side is the winner based on the score, for TB
        className += " win";
      }
  
      // if we are in editing mode
      if (state.validScoreList[inGame.id]) {
        let tb = list[nrOfSet]["type"] === "TB";
        let validValues = state.validScoreList[inGame.id][nrOfSet];
        console.log(
          "validValues [" + nrOfSet + "]:" + JSON.stringify(validValues)
        );
  
        let sideValidValues = validValues[currentSide];
        content.push(
          <>
            {sideValidValues.length > 0 && (
              <span className="possibles">
                {sideValidValues.map((vi) => vi + " ")}
              </span>
            )}
            <input
              type="text"
              className={
                "set-score-input " +
                (tb ? "tb " : " ") +
                (sideValidValues.length === 0 ? "" : "error")
              }
              tabIndex={(me === 1 ? -1 : 0) + (nrOfSet + 1) * 2}
              maxLength={2}
              onKeyPress={(event) => {
                if (!(parseInt(event.key) < 50)) {
                  event.preventDefault();
                  return false;
                }
              }}
              onChange={(event) => {
                event.preventDefault();
                changeState(
                  onChangeScore(
                    state,
                    inGame,
                    currentSide,
                    otherSide,
                    event.target,
                    currentSetIndex
                  )
                );
              }}
              onFocus={(event) => {
                event.preventDefault();
  
                onFocusScore(
                  state,
                  inGame,
                  currentSide,
                  otherSide,
                  event.target,
                  currentSetIndex,
                  false
                );
              }}
              onBlur={(event) => {
                event.preventDefault();
                let newState = onFocusScore(
                  state,
                  inGame,
                  currentSide,
                  otherSide,
                  event.target,
                  currentSetIndex,
                  true
                );
                if (newState) {
                  changeState(newState);
                }
              }}
              value={list[nrOfSet][currentSide]}
            />
          </>
        );
        nrOfSet++;
      } else {
        // eslint-disable-next-line no-loop-func
        let circle = function (tp, idx) {
          return (
            <div className={className} key={`c-${idx}`}>
              {list[nrOfSet][currentSide]}
              {tp}
            </div>
          );
        };
        if (list[nrOfSet + 1] && list[nrOfSet + 1]["type"] === "TB") {
          // we check whether the next set is a TB. the format is different and the winner is based on the TB score
          content.push(
            circle(<span>{list[nrOfSet + 1][currentSide]}</span>, nrOfSet)
          );
          nrOfSet += 2;
        } else {
          content.push(circle("", nrOfSet));
          nrOfSet++;
        }
      }
    }
  
    return content;
  }