import PropTypes from "prop-types";
import React, { createRef } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import ErrorModal from '../comp/ErrorModal';
import Header from "../comp/Header";
import FotoFriendAndBalls from "../images/foto-friends-balls.jpg";
import IconPelotaBalls from "../images/icon-pelota-balls.png";
import { appEmailShare, ballsStatistics, uploadContactFile } from "../resource";
import { parseMessageId } from '../utils';
import CommonComponent from "./CommonComponent";

class FriendsAndBalls extends CommonComponent {
  emailInput = null;
  dropzoneRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      formDisabled: false,
      ballsStatistics: {},
      listOfEmails: null,
      errorMessage: null,
      loading: false
    };
    this.fm = props.intl.formatMessage;
  }
  validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleShare(event) {
    event.preventDefault();
    let emails = this.emailInput.value.split(",");
    for (let i = 0; i < emails.length; i++) {
      if (!this.validateEmail(emails[i].trim())) {
        alert(this.fm({ id: "WA.FB.NotValidEmail" }, { email: emails[i] }));
        return;
      }
    }
    this.setState({ ...this.state, formDisabled: true });
    this.emailShare(this.emailInput.value);
  }
  emailShare(emails) {
    this.setState({...this.state,loading:true});
    appEmailShare(emails).then(result => {
      let response = result.data;
      this.props.showToast({ key: "WA.EmailShare.Success", params: response, config: { autoClose: false } });
      this.setState({ ...this.state, formDisabled: false, ballsStatistics: response.statistics, listOfEmails:null });
      this.emailInput.value = "";
    }).catch( error =>{
      const message = this.fm({id: parseMessageId(error)});
      this.setState({...this.state, errorMessage: message});
    });
  }
  onFileDrop(file) {
    if (file.length > 0) {
      if (file[0].size > 10000000) {
        alert("Please select a file with size lower than 10Mb!");
      } else {
        if (
          window.confirm(this.fm({ id: "WA.ContactFileShare.Confirm" }, { name: file[0].name, size: file[0].size }))
        ) {
          uploadContactFile(file[0])
            .then(response => {
              this.setState({ listOfEmails: response.data });
            })
            .catch(error => this.props.showToast({ key: "WA.ContactFileShare.Error", config: { autoClose: false } }));
        }
      }
    }
  }
  componentDidMount() {
    ballsStatistics().then(result => {
      this.setState({ ...this.state, ballsStatistics: result.data });
    });
  }

  swipperBalls(number) {
    if (!number) {
      number = 0;
    }
    let numbers = "000";
    numbers = numbers.substr(0, 3 - number.toString().length) + number.toString()
    let digits = numbers.split("");
    return digits.map(d => {
      return (
        <div class="swiper-balls">
          <div class="swiper-wrapper ">
            <div class="swiper-slide">
              <div class="number">{d}</div>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {

    let emailsModalHeight = "80px";
    let emailsAddress = [];
    if(this.state.listOfEmails){
      emailsAddress = this.state.listOfEmails.split(",");
      emailsModalHeight = emailsAddress.length>50?"200px":"80px";
    }

    return (
      <div>
        <Header />
        <ErrorModal show={!!this.state.listOfEmails} errorMessage={this.state.listOfEmails} 
        loading={this.state.loading}
        confirmAnchor="WA.Button.Confirm"
        cancelAnchor="WA.Button.Cancel"
        onClose={(e) => this.setState({ ...this.state, listOfEmails: null })} onConfirm={(e) => {
          this.emailShare(this.state.listOfEmails);
        }}>
          {this.state.listOfEmails &&
            <>
              <FormattedMessage id="WA.FriendAndBalls.ListOfEmails" values={{count:emailsAddress.length}}/>
              <br/><br/>
              <div style={{overflowY:"scroll", height:emailsModalHeight}}>
              <ul>
              {emailsAddress.map(em => {
                return <li>{em}</li>
              })}
              </ul>
              </div>
              <br/>
            </>
          }
        </ErrorModal>
        <ErrorModal show={!!this.state.errorMessage} errorMessage={this.state.errorMessage} onClose={(e) => this.setState({ ...this.state, errorMessage: null })}/>

        <div class="container">
          <h1 class="title-section">
            <FormattedMessage id="WA.Title.Friends+Balls" />
          </h1>
        </div>
        <div className="container content">
          <div class="col-md-5">
            <div class="col-balls">
              <div class="ball-top">
                <h2>
                  <FormattedMessage id="WA.FB.Title.YourBalls" />
                </h2>
                <p class="prior">
                  <FormattedMessage id="WA.FB.Title.BalanceOfBalls" />
                </p>
                <div class="wrapper-counters">
                  <div class="counter">
                    <div class="box-counter">
                      {this.swipperBalls(this.state.ballsStatistics.pendingCount)}
                    </div>
                    <p>
                      <FormattedMessage id="WA.FB.Title.Pending" />
                    </p>
                  </div>
                  {/* <!-- /counter pending--> */}

                  <i class="fa fa-angle-right" aria-hidden="true" />

                  <div class="counter">
                    <div class="box-counter">
                      <div class="swiper-balls">
                        {this.swipperBalls(this.state.ballsStatistics.currentCount)}
                      </div>
                    </div>
                    <p>
                      <FormattedMessage id="WA.FB.Title.Earned" />
                    </p>
                  </div>
                  {/* <!-- /counter earned--> */}
                </div>
                {/* <!-- /wrapper-counters--> */}

                <h2>
                  <FormattedMessage id="WA.FriendsAndBalls.HowDoesItWork" />
                </h2>
                <p class="copy-txt">
                  <FormattedMessage id="WA.FB.Text.ExplanationOfBalls" />
                </p>
              </div>
              <img class="img-responsive" src={FotoFriendAndBalls} alt="Friends" />
            </div>
            {/* <!-- / col-balls --> */}
          </div>

          <div class="col-md-7">
            <div class="col-invite">
              <h2>
                <FormattedMessage id="WA.FB.EarnBalls!" />
                <img src={IconPelotaBalls} alt="balls" />
              </h2>

              <div class="invite-via via-mail">
                <h3>
                  <FormattedMessage id="WA.FB.InviteViaE-mail" />
                </h3>
                <p>
                  <FormattedMessage id="WA.FB.SendInvitationToDownload" />
                </p>
                <form onSubmit={e => this.handleShare(e)}>
                  <div class="form-group">
                    <label class="hidden" for="exampleInputEmail1">
                      <FormattedMessage id="WA.Landing.EmailAddress" />
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      ref={input => (this.emailInput = input)}
                      placeholder={this.fm({ id: "WA.FB.EnterYourFriendsEmailHere" })}
                    />
                    <p class="help-block">
                      <FormattedMessage id="WA.FB.EnterMultipleEmails" />
                    </p>
                  </div>
                  <input
                    type="submit"
                    class="btn-gral verde"
                    value={this.fm({ id: "WA.FB.Send" })}
                    disabled={this.state.formDisabled}
                  />
                </form>
              </div>

              <div class="invite-via via-file">
                <h3>
                  <FormattedMessage id="WA.FB.ImportContactsFile" />
                </h3>
                <p>
                  <FormattedMessage id="WA.FB.BeEfficient" />
                </p>
                <form onSubmit={e => this.handleFileShare(e)}>
                  <Dropzone
                    ref={this.dropzoneRef}
                    multiple={false}
                    accept="text/csv,text/x-vcard, .csv, .vcf, .txt"
                    onDrop={this.onFileDrop.bind(this)}
                    className="valami">
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps}>
                        <input {...getInputProps} class="form-control" />
                        <p><FormattedMessage id="WA.FB.Label.DragNDrop"/></p>
                      </div>
                    )}
                  </Dropzone>
                  {/* <input
                    type="submit"
                    class="btn-gral verde"
                    value={this.fm({ id: "WA.FB.ContactImport" })}
                    disabled={this.state.formDisabled}
                  /> */}
                  <p class="help-block">
                    <FormattedMessage id="WA.FB.ContactFormats" />
                  </p>
                </form>
              </div>
            </div>
            {/* <!-- / col-balls --> */}
          </div>
        </div>
      </div>
    );
  }
}

FriendsAndBalls.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
  config: state.config
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showToast: data => dispatch({ type: "SHOW_TOAST", data })
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FriendsAndBalls)
);
