import { connect } from "react-redux";
import PropTypes from "prop-types";
import CommonComponent from "../CommonComponent";
import React from "react";
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import { getRatingHistory } from "../../resource";
import imageDoublesMixed from "../../images/icon-dubles-mixed.png";
import imageDoubles from "../../images/icon-dobles.png";
import iconWin from "../../images/icon-win.png";
import iconLose from "../../images/icon-lose.png";
import _ from "lodash";

const renderNumber = rank => {
  if (!rank) {
    //rank = 0;
  }

  return <FormattedNumber value={rank} minimumFractionDigits="1" maximumFractionDigits="1" />;
};
const renderIPlayme2Rating = (ratings, currentUser, getHistory) => {
  ratings = _.orderBy(ratings, "rating", "desc");

  let rankings = ratings.map((rating, index) => {
    let name = _.join(rating.team.players.map(p => p.firstName + " " + p.lastName), " & ");
    let self = rating.team.players.filter(p => p.id === currentUser.id);
    return (
      <tr align="center" class={self.length > 0 ? "success" : ""}>
        <td width="5%">{index + 1}</td>
        <td width="80%" align="left">
          {name}
        </td>
        <td width="10%">{renderNumber(rating.rating)}</td>
        <td width="5%">
          {" "}
          {self.length > 0 ? (
            <button onClick={event => getHistory(rating.team.id)}>
              <i class="fas fa-history" />
            </button>
          ) : (
              ""
            )}
        </td>
      </tr>
    );
  });
  return rankings;
};
const RankingSingles = ({
  active,
  iplayme2SingleRankMixedGender,
  iplayme2SingleRankSameGender,
  currentUser,
  rankingHistory,
  getHistory
}) => {
  return (
    <div role="tabpanel" className={`tab-pane ${active ? 'active' : ''}`} id="singles">
      <div class="row">
        <div class="col-md-6 col-rank">
          <img class="icon-rank" src={imageDoubles} alt="icon-rank" />
          <h3>
            <FormattedMessage id="WA.Title.SameGender" />
          </h3>
          <div class="table-responsive">
            <table class="table table-striped">
              <tbody>
                <tr />
                <tr align="center" class="table-header">
                  <th style={{ width: "25%" }}>
                    <p class="text-center">
                      <strong>
                        <FormattedMessage id="WA.Title.Rank" />
                      </strong>
                    </p>
                  </th>
                  <th align="left" style={{ width: "25%" }}>
                    <p>
                      <strong>
                        <FormattedMessage id="WA.Title.Name" />
                      </strong>
                    </p>
                  </th>
                  <th style={{ width: "25%" }}>
                    <p class="text-center">
                      <strong>
                        <FormattedMessage id="WA.Title.Rating" />
                      </strong>
                    </p>
                  </th>
                </tr>
                {renderIPlayme2Rating(iplayme2SingleRankSameGender, currentUser, getHistory)}
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-6 col-rank">
          <img class="icon-rank" src={imageDoublesMixed} alt="icon-rank" />
          <h3>
            <FormattedMessage id="WA.Title.MixedGender" />
          </h3>
          <div class="table-responsive">
            <table class="table table-striped">
              <tbody>
                <tr />
                <tr align="center" class="table-header">
                  <th style={{ width: "25%" }}>
                    <p class="text-center">
                      <strong>
                        <FormattedMessage id="WA.Title.Rank" />
                      </strong>
                    </p>
                  </th>
                  <th align="left" style={{ width: "25%" }}>
                    <p>
                      <strong>
                        <FormattedMessage id="WA.Title.Name" />
                      </strong>
                    </p>
                  </th>
                  <th style={{ width: "25%" }}>
                    <p class="text-center">
                      <strong>
                        <FormattedMessage id="WA.Title.Rating" />
                      </strong>
                    </p>
                  </th>
                </tr>
                {renderIPlayme2Rating(iplayme2SingleRankMixedGender, currentUser)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-rank">
          {rankingHistory && rankingHistory.length > 0 && (
            <>
              <h3>History</h3>
              <div class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                    <tr />
                    <tr align="center" class="table-header">
                      <th style={{ width: "20%" }}>
                        <p class="text-center">
                          <strong>
                            <FormattedMessage id="WA.Title.Rating" />
                          </strong>
                        </p>
                      </th>
                      <th align="left" style={{ width: "80%" }}>
                        <p>
                          <strong>Match</strong>
                        </p>
                      </th>
                    </tr>
                    {rankingHistory.map(rank => {
                      let initator = rank.teamId === rank.gameDTO.initiatorTeam.id;
                      let vsname = '';
                      let winner = true;
                      if (initator) {
                        winner = rank.gameDTO.winner === 'INITIATOR';
                        vsname = _.join(rank.gameDTO.opponentTeam.players.map(p => p.firstName + " " + p.lastName), " & ");
                      } else {
                        winner = rank.gameDTO.winner === 'OPPONENT';
                        vsname = _.join(rank.gameDTO.initiatorTeam.players.map(p => p.firstName + " " + p.lastName), " & ");
                      }

                      return (
                        <tr align="center">
                          <td>{renderNumber(rank.rating)}</td>
                          <td width="80%" align="left">
                            {winner ? <img src={iconWin} alt="win" style={{ width: "25px" }} /> : <img src={iconLose} style={{ width: "25px" }} alt="win" />} v. {vsname}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const renderDoubles = ({ data, currentUser }) => {

  return (
    <>
      {data.length > 0 &&
        <table class="table table-striped">
          <tbody>
            <tr />
            <tr align="center" class="table-header">
              <th style={{ width: "25%" }}>
                <p class="text-center">
                  <strong>
                    <FormattedMessage id="WA.Title.Rank" />
                  </strong>
                </p>
              </th>
              <th align="left" style={{ width: "25%" }}>
                <p>
                  <strong>
                    <FormattedMessage id="WA.Title.Name" />
                  </strong>
                </p>
              </th>
              <th style={{ width: "25%" }}>
                <p class="text-center">
                  <strong>
                    <FormattedMessage id="WA.Title.Rating" />
                  </strong>
                </p>
              </th>
            </tr>
            {data.map((item, index) => {
              let user1 = item.team.players[0] || {};
              let user2 = item.team.players[1] || {};
              let me = false;
              if (user1.id === currentUser.id || user2.id === currentUser.id) {
                me = true;
              }
              return (<tr align="center" className={me ? 'success' : ''}>
                <td>{index + 1}</td>
                <td align="left">{`${user1.firstName} ${user1.lastName} & ${user2.firstName || ''} ${user2.lastName || ''}`}</td>
                <td>{renderNumber(item.rating)}</td>
              </tr>)

            })}
          </tbody>
        </table>
      }
    </>
  )
}

const RankingOpenDoubles = ({ active, iplayme2DoubleRankSameGender, iplayme2DoubleRankMixedGender, currentUser }) => {
  console.log(JSON.stringify(iplayme2DoubleRankSameGender));
  if (!iplayme2DoubleRankSameGender || !iplayme2DoubleRankMixedGender) return null;
  return (
    <div role="tabpanel" className={`tab-pane ${active ? 'active' : ''}`} id="open">
      <div class="row">
        <div class="col-md-6 col-rank">
          <img class="icon-rank" src={imageDoubles} alt="icon-rank" />
          <h3>
            <FormattedMessage id="WA.Title.SameGender" />
          </h3>
          <div class="table-responsive">
            {iplayme2DoubleRankSameGender.length > 0
              ? renderDoubles({ data: iplayme2DoubleRankSameGender, currentUser })
              : <FormattedMessage id="WA.Ranking.NoMatchInCategory" />
            }
          </div>
        </div>

        <div class="col-md-6 col-rank">
          <img class="icon-rank" src={imageDoublesMixed} alt="icon-rank" />
          <h3>
            <FormattedMessage id="WA.Title.MixedGender" />
          </h3>
          <div class="table-responsive">
            {iplayme2DoubleRankMixedGender.length > 0
              ? renderDoubles({ data: iplayme2DoubleRankMixedGender, currentUser })
              : <FormattedMessage id="WA.Ranking.NoMatchInCategory" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

class RankingsBox extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      rankingHistory: [],
      ranking: 'single'
    };
    this.queryRatingHistory = this.queryRatingHistory.bind(this);
  }
  queryRatingHistory = teamId => {
    getRatingHistory(teamId).then(history => {
      this.setState({ ...this.state, rankingHistory: history.data });
    });
  };
  render() {
    let p = this.props;
    return (
      <div role="tabpanel" className={`tab-pane ${p.active ? "active" : ""}`} id="rankings">
        <ul class="nav nav-pills">
          <li role="presentation" className={this.state.ranking === 'single' ? 'active' : ''}>
            <a href="#singles" data-toggle="pill" onClick={(e) => this.setState({ ...this.state, ranking: 'single' })}>
              <FormattedMessage id="WA.Title.Singles" />
            </a>
          </li>
          <li role="presentation" className={this.state.ranking === 'double' ? 'active' : ''}>
            <a href="#open" data-toggle="pill" onClick={(e) => this.setState({ ...this.state, ranking: 'double' })}>
              <FormattedMessage id="WA.Title.Doubles" />
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <RankingSingles
            active={this.state.ranking === 'single'}
            iplayme2SingleRankSameGender={p.iplayme2SingleRankSameGender}
            iplayme2SingleRankMixedGender={p.iplayme2SingleRankMixedGender}
            currentUser={p.currentUser}
            rankingHistory={this.state.rankingHistory}
            getHistory={this.queryRatingHistory}
          />
          <RankingOpenDoubles
            iplayme2DoubleRankSameGender={p.iplayme2DoubleRankSameGender}
            iplayme2DoubleRankMixedGender={p.iplayme2DoubleRankMixedGender}
            active={this.state.ranking === 'double'}
            currentUser={p.currentUser}
          />
        </div>

        <div class="row">
          <div class="col-xs-12">
            <p class="note">
              <FormattedMessage id="WA.Text.Rankings" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

RankingsBox.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
  config: state.config
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RankingsBox)
);
