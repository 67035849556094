import { connect } from "react-redux";
import PropTypes from "prop-types";

import React from "react";
import Header from "../comp/Header";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import CommonComponent from "./CommonComponent";
class PaymentSuccess extends CommonComponent {
  render() {
    return (
      <div>
        <Header />
        <div className="container content">
          <div className="title-upgrade text-center">
            <h1>
              <FormattedMessage id="WA.Upgrade.UpgradeSuccess" />
            </h1>
            <p>
              <FormattedMessage
                id="WA.Upgrade.UpgradeSuccessDetails"
                values={{ level: "Live IT", expiration: "12-Oct-2017" }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

PaymentSuccess.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentSuccess)
);
