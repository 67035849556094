import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class Authenticated extends Component {
  render() {
    let {component: C, ...rest} = this.props;
    return (
    <Route {...rest} render={(props) => (
      this.props.loggedIn === true
        ? <C {...props} {...rest} />
        : <Redirect to='/login' />
    )} />);
  }
}
const mapStateToProps = (state, ownProps) => ({
  loggedIn: state.loggedIn
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authenticated);
