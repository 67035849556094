import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
class DateFormat extends Component {
  constructor(props){
    super(props);
    let local = 'en';
    if(props.dateFormat==='DD_MM_YYYY'){
      local = 'fr';
    }
    this.formatter = new Intl.DateTimeFormat(local,{month:'2-digit', year:'numeric', day:"2-digit"});
  }
  render() {
    let d = this.props.date;
    if(d){
      let d = this.props.date.split('-');
      if(d.length===3){
        d = this.formatter.format(Date.UTC(d[0],d[1],[2]));
      }
    }
    return 'N/A';
  }
}

DateFormat.propTypes = {
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  dateFormat: state.appConfig.dateFormat,
  timeFormat: state.appConfig.timeFormat
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFormat);