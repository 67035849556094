import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import iconLose from "../images/icon-lose.png";
import iconWhere from "../images/icon-where.png";
import iconWin from "../images/icon-win.png";
import { formatUserName } from "../utils.js";
import GameTitle from './GameTitle';
import ProfilePic from "./ProfilePic";
import { onChangeScore, onFocusScore } from "./ScoreUtils.js";



function renderScore (state, changeState, inGame, currentSide, me) {
  let list = inGame.gameSet;
  if (list === null || list.length === 0) return null;
  let otherSide = currentSide === "initiatorScore" ? "opponentScore" : "initiatorScore";
  let content = [];

  for (var i = 0; i < list.length;) {
    let index = i;

    let className = "set-circle";
    if (list[i][currentSide] > list[i][otherSide]) {
      // we check whether the current side is the winner based on the score, for TB
      className += " win";
    }

    // if we are in editing mode
    if (state.validScoreList[inGame.id]) {
      let tb = list[i]["type"] === "TB";
      let validity = state.validScoreList[inGame.id][i];
      console.log("validity:" + JSON.stringify(validity));

      let v = validity[currentSide];

      content.push(
        <>
          {v.length > 0 && <span className="possibles">{v.map(vi => vi + " ")}</span>}
          <input
            type="text"
            className={"set-score-input " + (tb ? "tb " : " ") + (v.length === 0 ? "" : "error")}
            tabIndex={(me === 1 ? -1 : 0) + (i + 1) * 2}
            maxLength="2"
            onKeyPress={event => {
              if (!(parseInt(event.key) < 50)) {
                event.preventDefault();
                return false;
              }
            }}
            onChange={event => {
              event.preventDefault();
              changeState(onChangeScore(state, inGame, currentSide, otherSide, event.target, index));
            }}
            onFocus={event => {
              event.preventDefault();
              let newState = onFocusScore(state, inGame, currentSide, otherSide, event.target, index);
              if(newState){
                changeState(newState);
              }

            }}
            value={list[index][currentSide]}
          />
        </>
      );
      i++;
    } else {
      // eslint-disable-next-line no-loop-func
      let circle = function (tp, idx) {
        return (
          <div className={className} key={`c-${idx}`}>
            {list[i][currentSide]}
            {tp}
          </div>
        );
      };
      if (list[i + 1] && list[i + 1]["type"] === "TB") {
        // we check whether the next set is a TB. the format is different and the winner is based on the TB score
        content.push(circle(<span>{list[i + 1][currentSide]}</span>, i));
        i += 2;
      } else {
        content.push(circle('', i));
        i++;
      }
    }
  }
  return content;
}




class GameWithScore extends Component {
  constructor(props) {
    super(props);
    this.fm = props.intl.formatMessage;
    this.orderForMe = this.orderForMe.bind(this);    
  }
  orderForMe(players) {
    let user = this.props.user;
    if (players.length === 1) {
      return players;
    }
    return players[0].id === user.id ? [players[0], players[1]] : [players[1], players[0]];
  }
  startSetScore(state, game) {
    game.gameSet = [{ initiatorScore: "?", opponentScore: "?" }];
    let validScoreList = state.validScoreList;
    validScoreList[game.id] = [{ initiatorScore: [], opponentScore: [] }];
    return { ...state, ...validScoreList };
  };
  playerNames(players, user, sep, selected) {
    if (!sep) {
      sep = ' &';
    }
    let pn = players.map((item, index) => {
      let s = '';
      if (index !== players.length - 1) {
        s = sep;
      }
      if (user && item.id === user.id) {
        return <span key={user.id}>{this.fm({ id: "WA.Title.Me" })}{s}</span>;
      } else {
  
        return <span className={item.id === selected ? 'selected' : ''}>{formatUserName(item)}{s}</span>;
      }
    });
  
    return pn;
  }
  
  render() {
    let game = this.props.game;
    let changeState = this.props.changeState;
    let state = this.props.state;

    let hasScore = game.gameSet != null && game.gameSet.length > 0;

    let otherTeam;

    let scoreKeyMe;
    let scoreKeyOpponent;
    let meWin = false;

    // set based on teams
    let meTeam;
    let setPartner = null;
    game.summary.meAsOwner = game.owner.id === this.props.user.id;

    if (game.initiatorTeam) {
      let me = game.initiatorTeam.players.filter(i => i && i.id === this.props.user.id);
      if (me.length > 0) {
        meWin = game.winner === "INITIATOR";
        meTeam = this.orderForMe(game.initiatorTeam.players);
        otherTeam = game.opponentTeam.players;

        scoreKeyMe = "initiatorScore";
        scoreKeyOpponent = "opponentScore";
      } else {
        meWin = game.winner === "OPPONENT";
        meTeam = this.orderForMe(game.opponentTeam.players);
        otherTeam = game.initiatorTeam.players;

        scoreKeyMe = "opponentScore";
        scoreKeyOpponent = "initiatorScore";
      }
    } else {
      if (game.summary.meAsOwner) {
        meWin = game.winner === "INITIATOR";
        scoreKeyMe = "initiatorScore";
        meTeam = [game.owner];
        scoreKeyOpponent = "opponentScore";
        otherTeam = game.invitee.map(inv => inv.user);
        if (otherTeam.length > 1 && game.action.indexOf("PARTNER_SELECTION") > -1) {

          setPartner = (user) => {
            let p = state.gamePartnerSelection;
            p[game.id] = user.id;
            changeState({ ...state, gamePartnerSelection: { ...p } })
          }
        }
      } else {
        meWin = game.winner === "OPPONENT";
        meTeam = game.invitee.map(inv => inv.user);
        otherTeam = [game.owner];
        scoreKeyMe = "opponentScore";
        scoreKeyOpponent = "initiatorScore";
      }
    }
    if (!otherTeam) {
      console.log("other player is null");
    }
    let scoreMe = renderScore(state, (s) => changeState(s), game, scoreKeyMe, 1);

    let scoreOpponent = renderScore(state, (s) => changeState(s), game, scoreKeyOpponent, 2);
    let isWinner = game.winner !== "UNKNOWN" && game.winner;
    let isScoreSet = state.validScoreList[game.id];

    // will do the merge

    return (
      <div className="table-row" key={game.id}>
        <GameTitle game={game}/>
        <div className="table-cell cell-score">
          <div style={{ display: "table", width: "100%" }}>
            <div className="player-box">
              <div className="player-line">
                <div className={`player ${isWinner ? (meWin ? 'winner' : 'loser') : ''}`}>
                  {meTeam.map((user) =>
                    <><ProfilePic url={user.profileImageId} />&nbsp;</>
                  )}
                </div>
                <div className="name">
                  {this.playerNames(meTeam, this.props.user)}
                </div>
              </div>
              <div className="set-count-line">
                <div className="line" />
              </div>

              <div className="player-line">

                <div className={`player ${setPartner ? 'partner' : ''}`}>
                  {otherTeam.map((user) =>
                    <><ProfilePic url={user.profileImageId} onClick={(e) => { if (setPartner) setPartner(user) }} selected={state.gamePartnerSelection[game.id] === user.id} />&nbsp;</>
                  )}
                </div>
                <div className="name">
                  {this.playerNames(otherTeam, {}, ', ', state.gamePartnerSelection[game.id])}
                </div>
              </div>
            </div>
            {hasScore ? (
              <div className="score-box hidden-xs">
                <div className="score-line">
                  <div className="set" style={!isWinner ? { paddingLeft: "30px" } : {}}>
                    {isWinner && (meWin ? <img src={iconWin} alt="win" /> : <img src={iconLose} alt="win" />)}
                    {scoreMe}
                  </div>
                </div>
                <div className="set-count-line">
                  <div className="line" />
                  <div className="count">
                    {scoreMe.map((item, index) => {
                      return <div className="set" key={`set-${index}`}>{index + 1}</div>;
                    })}
                    {isWinner && isScoreSet && scoreMe.length > 0 && (
                      <div className="set done">
                        <a href="/#" onClick={event => this.props.saveScore(game)}>
                          <i className="fas fa-check-double" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="score-line">
                  <div className="set">
                    {scoreOpponent}
                  </div>
                </div>
              </div>
            ) : (
                <>
                  {game.action.indexOf("SET_SCORE") > -1 && (
                    <div className="set-score-box hidden-xs">
                      <button className="smallaction confirm " onClick={event => { event.preventDefault(); changeState(this.startSetScore(state, game)); }}><FormattedMessage id="WA.SetScore" /></button>
                    </div>
                  )}
                  {setPartner &&
                    (<div className="set-score-box hidden-xs">
                      <button className="smallaction confirm " disabled={!!!state.gamePartnerSelection[game.id]} onClick={event => { event.preventDefault(); this.partnerSelection(game) }}><FormattedMessage id="WA.SetPartner" /></button>
                    </div>)
                  }
                </>
              )}
          </div>
        </div>
        <div className="table-cell cell-sides">
          <div className="content-table-cell">
            <img src={iconWhere} alt="where" />
            {game.summary.locationString ? game.summary.locationString : this.fm({ id: "WA.UnknownLocation" })}
          </div>
        </div>
        {/* table-row */}
      </div>
    );
  }
}

GameWithScore.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GameWithScore)
);
