import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import profilePic from "../images/icon-no-pic.png";
import ProfileBox from "../comp/ProfileBox";
import { uploadProfilePicture } from "../resource";
import "croppie/croppie.css";
import Croppie from "croppie";
import { getPublicProfile } from "../resource";
import HeaderExt from "../comp/HeaderExt";
import { renderStars } from "../utils";


const renderSkill = (label, data, skill) => {
  return (
    <>
      <div className="hidden-xs col-sm-2 skill-body-name">&nbsp;</div>
      <div className="col-xs-5 col-sm-5 skill-body-name"><FormattedMessage id={label} /></div>
      <div className="col-xs-7 col-sm-5 skill-body-star">{renderStars(data[skill])}</div>
    </>
  )
}

class PublicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSuccess: false,
      uploadedFile: {},
      techSkill: {}
    };
    this.onImageDrop = this.onImageDrop.bind(this);
    this.clearTempPicture = this.clearTempPicture.bind(this);
    this.finishUpload = this.finishUpload.bind(this);
    this.croppie = null;
  }
  componentDidMount() {
    if (this.props.match.params.code) {
      getPublicProfile(this.props.match.params.code).then(resp => {
        this.setState({ ...this.state, ...resp.data });
      });
    }
  }
  onImageDrop(files) {
    this.setState({
      ...this.state,
      uploadedFile: {
        file: files[0]
      }
    });
    setTimeout(() => {
      let pi = document.getElementById("croppie-panel");
      if (!pi) return;
      if (Croppie.bind) { };
      this.croppie = new window.Croppie(pi, {
        viewport: { width: 200, height: 200, type: "circle" },
        boundary: { width: 200, height: 200 },
        showZoomer: true,
        enableOrientation: true
      });
      console.debug("croppie set");
      this.croppie.bind({
        url: URL.createObjectURL(files[0]),
        orientation: 1
      });
    }, 0);
  }

  clearTempPicture() {
    this.setState({
      ...this.state,
      uploadedFile: {}
    });
    this.croppie = null;
  }

  finishUpload() {
    this.croppie.result("blob").then(blob => {
      if (blob) {
        let promise = uploadProfilePicture(blob);
        promise
          .then(result => {
            console.log("image uploaded");
            this.props.getUserData();
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  }


  componentWillUpdate() {
    console.debug("component will update");
    if (this.croppie != null) {
      this.clearTempPicture();
    }
  }

  render() {
    let user = this.state.user;
    let technicalSkill = this.state.technicalSkill;
    let personalSkill = this.state.personalSkill;
    let noheader = this.props.location.search.indexOf("noheader")!==-1;

    if (!user) {
      return null;
    }
    let forehandKey = "WA.UnknownProfile";
    if (user.forehand) {
      forehandKey = `WA.EditProfile.ForehandStyle.${user.forehand}`;
    }
    let backhandKey = "WA.UnknownProfile";
    if (user.backhand) {
      backhandKey = `WA.EditProfile.BackhandStyle.${user.backhand}`;
    }
    return (
      <div>
        {!noheader &&
        <HeaderExt />
        }
        <div className="container content">
          <div className="row">
            <div className="col-xs-12 col-md-5">
              <div className="box-profile">
                <div className="top-box-profile">
                  <div className="profile-pic-big">
                    <img
                      className="img-responsive"
                      src={user.profileImageUrl || profilePic}
                      alt="profile"
                      style={{ width: "196px" }}
                    />
                  </div>
                  <h1>
                    {user.firstName} {user.lastName}
                  </h1>
                  <p>{user.location}</p>
                </div>
                <div className="row items-profile">
                  <div className="col-xs-6 item-profile">
                    {technicalSkill.iplayme2Rating &&
                      <div >
                        <p><FormattedNumber value={technicalSkill.iplayme2Rating} intlStyle="decimal" minimumFractionDigits="1" maximumFractionDigits="1" /></p>
                        <p>
                          <span class="header">
                            <FormattedMessage id="WA.TechSkill.Iplayme2Rating" />
                          </span>
                        </p>
                      </div>
                    }
                  </div>
                  <div className="col-xs-6 item-profile">
                    <div >
                      <p>{technicalSkill.wins} - {technicalSkill.loses}</p>
                      <p>
                        <span class="header">
                          <FormattedMessage id="WA.TechSkill.MatchRecord" />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-6 item-profile">
                    <ProfileBox titleKey="WA.EditProfile.ForehandStyle" valueKey={forehandKey} />
                  </div>
                  <div className="col-xs-6 item-profile">
                    <ProfileBox titleKey="WA.EditProfile.BackhandStyle" valueKey={backhandKey} />
                  </div>
                  <div className="col-xs-6 item-profile">
                    <ProfileBox
                      titleKey="WA.EditProfile.TennisTalk"
                      value={user.tennisTalks.join(" - ")}
                      valueKey="WA.UnknownProfile"
                    />
                  </div>
                  <div className="col-xs-6 item-profile">
                    <ProfileBox
                      titleKey="WA.EditProfile.YearOfBirth"
                      value={user.yearOfBirth}
                      valueKey="WA.UnknownProfile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-7">
              <div className="wrapper-skills">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="skills-header">
                      <FormattedMessage id="WA.Title.PersonalRating" />
                    </div>
                  </div>
                  {renderSkill("WA.LabelRatingPlayAgainWith", personalSkill, "playAgainWith")}
                  {renderSkill("WA.LabelRatingPunctual", personalSkill, "punctual")}
                  {renderSkill("WA.LabelRatingFairPlay", personalSkill, "fairplay")}
                  {renderSkill("WA.LabelRatingPersonal", personalSkill, "personal")}
                  <div className="col-xs-12">
                  &nbsp;
                  </div>
                  <div className="col-xs-12">
                    <div className="skills-header">
                      <FormattedMessage id="WA.PersonalRating.TechnicalSkillsRatings" />
                    </div>
                  </div>

                  {renderSkill("WA.LabelRating1stServe", technicalSkill, "firstServe")}
                  {renderSkill("WA.LabelRating2ndServe", technicalSkill, "secondServe")}
                  {renderSkill("WA.LabelRatingForehand", technicalSkill, "forehand")}
                  {renderSkill("WA.LabelRatingBackhand", technicalSkill, "backhand")}
                  {renderSkill("WA.LabelRatingCourtCoverage", technicalSkill, "courtCoverage")}
                  {renderSkill("WA.LabelRatingReturnService", technicalSkill, "returnOfService")}
                  {renderSkill("WA.LabelRatingNetPlay", technicalSkill, "netPlay")}
                  {renderSkill("WA.LabelRatingReturnPlay", technicalSkill, "returnPlay")}
                  <div className="col-xs-12">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className="col-xs-12 col-boxes-info">
        <div className="alert alert-success fade in" role="alert">
          <Link to="/profile" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Link>
          <strong>
            <FormattedMessage id="WA.EditProfile.Msg.WellDone" />
          </strong>
        </div>
      </div>
    );
  }
}

PublicProfile.propTypes = {
  intl: intlShape.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserData: () => dispatch({ type: "GET_USERDATA" })
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicProfile)
);
