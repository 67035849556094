
/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { getBlockedUsers, getInvitationStatus, unblockUser, updateEmailChannelStatus, updateInvitationStatus } from "../resource";

class DeblockUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "personal",
      blockedUsers: [],
      invitationBlockStatus: null,
      emailChannel: null
    };
    this.fm = props.intl.formatMessage;
    this.renderBlockedUser = this.renderIPlayme2Rating.bind(this);
    this.unblock = this.unblock.bind(this);
    this.updateData = this.updateData.bind(this);
    this.changedInvitationBlock = this.changedInvitationBlock.bind(this);
    this.changedEmailBlockStatus = this.changedEmailBlockStatus.bind(this);
  }
  getUserName(user) {
    let name = "";
    if (user.firstName) {
      name = user.firstName;
    }
    if (user.lastName) {
      name += user.lastName;
    }
    return name;
  }

  renderIPlayme2Rating(blockedUsers) {

    let rankings = blockedUsers.map((user, index) => {

      let name = this.getUserName(user);
      return (
        <tr align="center">
          <td width="70%" align="left">
            <i class="fas fa-ban"></i> {name}
          </td>
          <td width="30%">
            <button onClick={event => { event.preventDefault(); this.unblock(user.id) }}><i class="fas fa-check-circle"></i>&nbsp;Unblock</button>
          </td>
        </tr>
      );
    });
    return rankings;
  };

  unblock(userid) {
    unblockUser(userid).then(() => {
      this.updateData();
    });
  }

  componentDidMount() {
    this.updateData();
  }
  updateData() {
    getBlockedUsers().then((result) => {
      this.setState({ ...this.state, blockedUsers: result.data });
    });
    getInvitationStatus().then((result) => {
      this.setState({ ...this.state, invitationBlockStatus: result.data.status, emailChannelStatus: result.data.emailStatus});
    });
  }
  changedInvitationBlock(){
    let status = !!!this.state.invitationBlockStatus;
    updateInvitationStatus(status).then(( result )=>{
      this.setState({...this.state, invitationBlockStatus: status})
    });
  }
  changedEmailBlockStatus(){
    let status = !!!this.state.emailChannelStatus;
    updateEmailChannelStatus(status).then(( result )=>{
      this.setState({...this.state, emailChannelStatus: status})
    });
  }


  render() {
    return <div>
      <table class="deblock-user table ">
        <tbody>
          <tr align="left">
            <td style={{ width: "70%" }}>
              <input
                type="checkbox"
                hidden="true"
                value={this.state.emailChannelStatus}
                checked={!!!this.state.emailChannelStatus}
                onChange={this.changedEmailBlockStatus}
                id="emailStatus"
              />&nbsp;
              <label for="emailStatus">
              {this.state.emailChannelStatus ?
                <><i class="fas fa-check-circle"></i>&nbsp;<FormattedMessage id="WA.Email.Enabled" /></>
                :
                <><i class="fas fa-ban"></i>&nbsp;<FormattedMessage id="WA.Email.Blocked" /></>
              }
              </label>
            </td>
            <td style={{ width: "30%" }}>&nbsp;</td>
          </tr>
          <tr align="left">
            <td style={{ width: "70%" }}>
              <input
                type="checkbox"
                hidden="true"
                value={this.state.invitationBlockStatus}
                checked={!!!this.state.invitationBlockStatus}
                onChange={this.changedInvitationBlock}
                id="invitationStatus"
              />&nbsp;
              <label for="invitationStatus">
              {this.state.invitationBlockStatus ?
                <><i class="fas fa-ban"></i>&nbsp;<FormattedMessage id="WA.Invitation.Blocked" /></>
                :
                <><i class="fas fa-check-circle"></i>&nbsp;<FormattedMessage id="WA.Invitation.Enabled" /></>
              }
              </label>
            </td>
            <td style={{ width: "30%" }}>&nbsp;</td>
          </tr>

        </tbody>
      </table>
      <table class="deblock-user table ">
        <tbody>
          <tr align="center">
            <th style={{ width: "70%" }}>
              {this.state.blockedUsers.length > 0 ?
                <strong>
                  <FormattedMessage id="WA.BlockedUser.Name" />
                </strong>
                :
                <strong>
                  <i class="fas fa-check-circle"></i>&nbsp;<FormattedMessage id="WA.NoBlockedUser" />
                </strong>
              }
            </th>
            <th style={{ width: "30%" }}>&nbsp;</th>
          </tr>
          {this.renderBlockedUser(this.state.blockedUsers)}
        </tbody>
      </table>
    </div>
  }
}

DeblockUser.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  user: state.userData,
  config: state.config
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeblockUser)
);
