import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import es from "react-intl/locale-data/es";
import de from "react-intl/locale-data/de";
import nl from "react-intl/locale-data/nl";
import it from "react-intl/locale-data/it";
import pt from "react-intl/locale-data/pt";
import hu from "react-intl/locale-data/hu";


var locales = ["en", "fr", "es", "de", "nl", "it", "pt", "hu"];
addLocaleData([...en, ...fr, ...es, ...de, ...nl, ...it, ...pt, ...hu]);

export function getLocale() {
  let locale = navigator.language;
  if(locale && locale.length>2){
    locale = locale.substr(0,2);
  }
  if (!locale || (locales.indexOf(locale)===-1)) {
    locale = "en";
  }
  return locale;
}

export function getMessages(locale) {
  return window.ipm2Lang[locale];
}
