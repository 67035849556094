import React from 'react';
import PropTypes from 'prop-types';

class PaymentModal extends React.Component {
  constructor(props){
    super(props);
    this.state= { show: false};
  }
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: '2em',
      zIndex:1000
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: 800,
      minHeight: 300,
      height:'100%',
      margin: '0 auto',
      padding: '2em'
    };
    return (
      <>
      <div style={backdropStyle}>
        <div style={modalStyle}>
        <a href="#/" onClick={this.props.onClose} style={{float:'right'}}>X Close</a>
        <iframe src="pw_LOVEIT.html" title={this.props.plan} style={{border:'0', width:'100%', height:'100%', margin:'0px', padding:'0px', overflow: 'hidden'}} height="100%" width="100%"></iframe>
        </div>
        </div>
      </>
    );
  }
}

PaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  plan: PropTypes.string.isRequired
};

export default PaymentModal;