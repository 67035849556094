import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import CountryData from "./comp/CountryData";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import InputText from './comp/InputText';
import PasswordInputs from './comp/PasswordInputs';
import iconNewAccount from "./images/on-boarding/icon-newAccount.svg";
import iconReturningPlayer from "./images/on-boarding/icon-ReturningPlayer.svg";
import { generateYears } from './utils';

class SignUp extends Component {

  constructor(props) {
    super(props);
    const defaultContry = CountryData.filter((country, index) => this.props.countryCode === country.countryCode);
    let prefix = 'BE';
    if (defaultContry.length === 1){
      prefix = defaultContry[0].prefix;
    }

    this.state = {
      prefix,
      password: "",
      firstName: "",
      lastName: "",
      gender: 'FEMALE',
      yearOfBirth: 1980,
      error: {
        phone: true,
        password: true,
        firstName: true,
        lastName: true,
        regInvitationCode: false
      },
      style: {
        phone: '',
        password: '',
        firstName: '',
        lastName: '',
        gender: '',
        regInvitationCode: ''
      }
    }

    this.genderChanged = this.genderChanged.bind(this);
    this.yearOfBirthChanged = this.yearOfBirthChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.phoneChanged = this.phoneChanged.bind(this);
    this.submit = this.submit.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.invitationCodeRegExp = new RegExp("^\\D\\D\\D\\d*@\\d+$");
  }

  
  render() {
    if (this.props.signUpSuccess) {
      return <Redirect to="/login/checkYourEmail" />;
    }

    let submitEnabled = true;
    _.reduce(this.state.error, (result, error, key) => {
      submitEnabled = submitEnabled && !error;
    });
    return (
      <>

        <HeaderOnBoarding />

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <div className="wrapperOnboard text-center">
                    
                  <div className="boxTitle">
                    <img class="" src={iconNewAccount} alt="Create New Acount" />
                    <h1 className="h3"><FormattedMessage id="WA.Label.SignUpHeader" /></h1>
                    <span className="itisfree"><FormattedMessage id="WA.ItsFreeForever"/></span>
                  </div>

                  <div className="login-info text-left">
                    <form onSubmit={this.submit}>
                    
                      <div className="form-group-2">
                          <div className="form-group">
                            <label >
                              <FormattedMessage id="WA.Share.CountryCode" />{this.props.countryCode}
                            </label>
                            <select
                              name="prefix"
                              className="form-control"
                              onChange={(event) => this.updateValue(event.target.value, "prefix")}
                              value={this.state.prefix}
                            >
                              {
                                CountryData.map((country, index) => {
                                  return <option value={`${country.prefix}`} key={index} selected={country.countryCode === this.props.countryCode}>{`${country.country} +${country.prefix}`}</option>
                                }
                                )
                              }
                            </select>
                          </div>
                          <div className={"form-group has-feedback " + (this.state.style.phone)}>
                            <label >
                              <FormattedMessage id="WA.Landing.Phone" /> - {this.props.countryCode}
                            </label>
                            <InputText
                              label="&nbsp;"
                              placeholder="WA.Landing.PhonePlaceholder"
                              name="phone"
                              value={this.state.phone}
                              className="form-control"
                              onChange={this.phoneChanged}
                              onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                              valid={() => { this.setState({ style: { ...this.state.style, phone: 'has-success' } }) }}
                              notValid={() => { this.setState({ style: { ...this.state.style, phone: 'has-error' } }) }}
                              validator={(value) => (parseInt(value) === value) && value.length>5}
                            />
                          </div>
                        </div>


                      <PasswordInputs onChange={this.passwordChanged} />
                      <div className={"form-group has-feedback " + (this.state.style.firstName)}>
                        <InputText label="WA.EditProfile.FirstName" placeholder="WA.SignUpFirstNamePlaceholder" name="firstName" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          valid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-error' } }) }}
                          onChange={this.updateValue}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.firstName ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>
                      <div className={"form-group has-feedback " + (this.state.style.lastName)}>
                        <InputText label="WA.EditProfile.LastName" placeholder="WA.SignUpLastNamePlaceholder" name="lastName" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          valid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-error' } }) }}
                          onChange={(value) => this.setState({ lastName: value })}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.lastName ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>
                      <div className="row">
                        <div className="col-xs-6">
                          <div className={"form-group " + (this.state.error.gender ? "has-error" : "has-success")}>
                            <label>
                              <FormattedMessage id="WA.EditProfile.Gender" />
                            </label>
                            <div className="radio">
                              <label className="box-radio-checkbox">
                                <input
                                  id="genderRadioFemale"
                                  type="radio"
                                  name="genderRadio"
                                  value="FEMALE"
                                  checked={this.state.gender === 'FEMALE'}
                                  onChange={this.genderChanged}
                                />
                                <span class="radiobtn circle"></span>
                                <FormattedMessage id="ENUM.gender.FEMALE" />
                              </label>
                            </div>
                            <div className="radio">
                              <label className="box-radio-checkbox">
                                <input
                                  id="genderRadioMale"
                                  type="radio"
                                  name="genderRadio"
                                  value="MALE"
                                  checked={this.state.gender === 'MALE'}
                                  onChange={this.genderChanged}
                                />
                                <span class="radiobtn circle"></span>
                                <FormattedMessage id="ENUM.gender.MALE" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="form-group has-feedback">
                            <label>
                              <FormattedMessage id="WA.EditProfile.YearOfBirth" />
                            </label>
                            <select
                              name="yearOfBirth"
                              className="form-control"
                              onChange={this.yearOfBirthChanged}
                            >
                              {
                                generateYears().map(year =>
                                  <option value={year} key={year} selected={year===this.state.yearOfBirth}>{year}</option>
                                )
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className={"form-group has-feedback " + (this.state.style.regInvitationCode)}>
                        <InputText label="WA.EditProfile.InvitationCode" placeholder="WA.SignUpInvitationCodePlaceholder" name="regInvitationCode" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          validator ={(value) => value ==null || value==='' ||this.invitationCodeRegExp.test(value.toString())}
                          valid={() => { this.setState({ style: { ...this.state.style, regInvitationCode: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, regInvitationCode: 'has-error' } }) }}
                          onChange={(value) => this.setState({ regInvitationCode: value })}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.regInvitationCode ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>

                      <input
                        type="submit"
                        className="btn-gral verde form-control"
                        value={this.props.intl.formatMessage({ id: 'WA.Label.SignUpHeader' })}
                        disabled={!submitEnabled}
                      />
                    </form>
                  </div>
                </div>
            </div>

            <div className="col-xs-12 col-md-6 col-md-offset-3">
              <Link className="newAccountCTA loginCTA text-center" to="/login">
                  <img src={iconReturningPlayer} alt="Returning Player" />
                  <h2 className="h4">Returning player</h2>
                  <p><FormattedMessage id="WA.SignUp.HaveAnAccount?" /></p>
                  <span className="itisfree"><FormattedMessage id="WA.Landing.LoginButton" /></span>
              </Link>
            </div>

          </div>
        </div>
      </>
    );
  }


  
  genderChanged(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      gender: value
    });
  }

  yearOfBirthChanged(event) {
    const yearOfBirth = event.target.value;
    this.setState({
      yearOfBirth,
      error: {
        ...this.state.error,
        yearOfBirth: !yearOfBirth,
      }
    });
  }

  passwordChanged(newPassword) {
    this.setState({
      password: newPassword,
      error: {
        ...this.state.error,
        password: !newPassword
      }
    })
  }

  phoneChanged(phone) {
    phone = phone.replace(' ','');
    this.setState({
      ...this.state,
      phone
    });
  }

  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }

  submit(event) {
    event.preventDefault();
    for (let key in this.state.error) {
      if (this.state.error[key]) {
        return;
      }
    }

    this.props.signUp({
      emails: [{
        email: '+'+this.state.prefix+this.state.phone,
        main: true
      }],
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      yearOfBirth: this.state.yearOfBirth,
      regInvitationCode: this.state.regInvitationCode
    });
  }
}

SignUp.propTypes = {
  intl: intlShape.isRequired,
  signUp: PropTypes.func.isRequired,
  signUpSuccess: PropTypes.bool.isRequired,
  signUpError: PropTypes.number
};

const mapStateToProps = (state, ownProps) => ({
  signUpSuccess: state.signUpSuccess,
  signUpError: state.signUpError,
  countryCode: state.config.countryCode
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  signUp: (userData) => dispatch({ type: 'SIGNUP_REQUEST', userData }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SignUp));