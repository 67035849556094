import React, { Component } from "react";
import Carousel from "./CarouselBanner";
import sportBalls from "../images/banner-download/balls.svg";
import iconStores from "../images/banner-download/download-ipm2-badge-light.svg";
import ColorBars from "./ColorBars";

class DownloadBanner extends Component {
  constructor(props, context) {
    super(props);
}

render() {
    return (
      <>
        <div className="banner-download">
            <div className="row">
                <div className="col-sm-8">
                    <div className="title">
                        <div className="words-compo">
                            <div>Maximize</div>
                            <div>your</div>
                        </div>
                        <Carousel />
                        <div className="balls-compo">
                            <div>life</div>
                            <img src={sportBalls} alt="Sport balls" />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="box-stores text-center">
                        <a 
                            href="https://iPlayMe2.com" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                        >
                            <img src={iconStores} alt="Sport balls" className="img-responsive" />
                        </a>
                        <span>It's Free, Forever!</span>
                    </div>
                </div>
            </div>
            <ColorBars />
        </div>
      </>
    );
  }

}

export default DownloadBanner;
