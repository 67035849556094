import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { validate as validateEmail } from 'email-validator';
import PropTypes from 'prop-types';
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import PasswordInputs from './comp/PasswordInputs';
import InputText from './comp/InputText';
import { generateYears } from './utils';
import _ from 'lodash';
import iconReturningPlayer from "./images/on-boarding/icon-ReturningPlayer.svg";
import iconNewAccount from "./images/on-boarding/icon-newAccount.svg";

class SignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: 'FEMALE',
      yearOfBirth: 1980,
      error: {
        email: true,
        password: true,
        firstName: true,
        lastName: true,
        regInvitationCode: false
      },
      style: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        gender: '',
        regInvitationCode: ''
      },
      valid: false
    }

    this.genderChanged = this.genderChanged.bind(this);
    this.yearOfBirthChanged = this.yearOfBirthChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.submit = this.submit.bind(this);
    this.emailValidator = this.emailValidator.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.invitationCodeRegExp = new RegExp("^\\D\\D\\D\\d*@\\d+$");
  }

  emailValidator(value) {
    return (this.props.signUpError !== 409) && validateEmail(value)
  }

  render() {
    if (this.props.signUpSuccess) {
      return <Redirect to="/login/checkYourEmail" />;
    }

    let submitEnabled = this.state.valid;
    _.reduce(this.state.error, (result, error, key) => {
      submitEnabled = submitEnabled && !error;
    });
    const alreadyExists = this.props.signUpError === 409;


    return (
      <>

        <HeaderOnBoarding />

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <div className="wrapperOnboard text-center">
                    
                  <div className="boxTitle">
                    <img class="" src={iconNewAccount} alt="Create New Acount" />
                    <h1 className="h3"><FormattedMessage id="WA.Label.SignUpHeader" /></h1>
                    <span className="itisfree"><FormattedMessage id="WA.ItsFreeForever"/></span>
                  </div>

                  <div className="login-info text-left">
                    <form onSubmit={this.submit}>
                      <div className={"form-group has-feedback " + (alreadyExists ? 'has-error' : this.state.style.email)}>
                        <InputText 
                          label="WA.Landing.EmailAddress" 
                          placeholder="WA.Landing.EmailAddressPlaceholder" 
                          name="email" 
                          className="form-control"
                          validator={validateEmail}
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res }, valid: true }) }}
                          valid={() => { this.setState({ style: { ...this.state.style, email: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, email: 'has-error' } }) }}
                          onChange={this.updateValue}
                        />
                        {alreadyExists ?
                          <span className="help-block has-error">
                            <FormattedMessage
                              id="WA.SignUp.UserAlreadyExists"
                            />
                          </span>
                          :
                          null
                        }
                        <span className={"glyphicon glyphicon-" + (this.state.error.email || alreadyExists ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>
                      <PasswordInputs onChange={this.passwordChanged} />
                      <div className={"form-group has-feedback " + (this.state.style.firstName)}>
                        <InputText label="WA.EditProfile.FirstName" placeholder="WA.SignUpFirstNamePlaceholder" name="firstName" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          valid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, firstName: 'has-error' } }) }}
                          onChange={this.updateValue}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.firstName ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>
                      <div className={"form-group has-feedback " + (this.state.style.lastName)}>
                        <InputText label="WA.EditProfile.LastName" placeholder="WA.SignUpLastNamePlaceholder" name="lastName" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          valid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, lastName: 'has-error' } }) }}
                          onChange={(value) => this.setState({ lastName: value })}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.lastName ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>
                      <div className="row">
                        <div className="col-xs-6">
                          <div className={"form-group " + (this.state.error.gender ? "has-error" : "has-success")}>
                            <label>
                              <FormattedMessage id="WA.EditProfile.Gender" />
                            </label>
                            <div className="radio">
                              <label className="box-radio-checkbox">
                                <input
                                  id="genderRadioFemale"
                                  type="radio"
                                  name="genderRadio"
                                  value="FEMALE"
                                  checked={this.state.gender === 'FEMALE'}
                                  onChange={this.genderChanged}
                                />
                                <span class="radiobtn circle"></span>
                                <FormattedMessage id="ENUM.gender.FEMALE" />
                              </label>
                            </div>
                            <div className="radio">
                              <label className="box-radio-checkbox">
                                <input
                                  id="genderRadioMale"
                                  type="radio"
                                  name="genderRadio"
                                  value="MALE"
                                  checked={this.state.gender === 'MALE'}
                                  onChange={this.genderChanged}
                                />
                                <span class="radiobtn circle"></span>
                                <FormattedMessage id="ENUM.gender.MALE" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="form-group has-feedback">
                            <label>
                              <FormattedMessage id="WA.EditProfile.YearOfBirth" />
                            </label>
                            <select
                              name="yearOfBirth"
                              className="form-control"
                              onChange={this.yearOfBirthChanged}
                            >
                              {
                                generateYears().map(year =>
                                  <option value={year} key={year} selected={year===this.state.yearOfBirth}>{year}</option>
                                )
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className={"form-group has-feedback " + (this.state.style.regInvitationCode)}>
                        <InputText label="WA.EditProfile.InvitationCode" placeholder="WA.SignUpInvitationCodePlaceholder" name="regInvitationCode" className="form-control"
                          onValidated={(res) => { this.setState({ error: { ...this.state.error, ...res } }) }}
                          validator ={(value) => value ==null || value==='' ||this.invitationCodeRegExp.test(value.toString())}
                          valid={() => { this.setState({ style: { ...this.state.style, regInvitationCode: 'has-success' } }) }}
                          notValid={() => { this.setState({ style: { ...this.state.style, regInvitationCode: 'has-error' } }) }}
                          onChange={(value) => this.setState({ regInvitationCode: value })}
                        />
                        <span className={"glyphicon glyphicon-" + (this.state.error.regInvitationCode ? "remove" : "ok") + " form-control-feedback"} aria-hidden="true"></span>
                      </div>

                      <input
                        type="submit"
                        className="btn-gral verde form-control"
                        value={this.props.intl.formatMessage({ id: 'WA.Label.SignUpHeader' })}
                        disabled={!submitEnabled}
                      />
                    </form>
                  </div>
                </div>
            </div>

            <div className="col-xs-12 col-md-6 col-md-offset-3">
              <Link className="newAccountCTA loginCTA text-center" to="/login">
                  <img src={iconReturningPlayer} alt="Returning Player" />
                  <h2 className="h4"><FormattedMessage id="WA.ReturningPlayer"/></h2>
                  <p><FormattedMessage id="WA.SignUp.HaveAnAccount?" /></p>
                  <span className="itisfree"><FormattedMessage id="WA.Landing.LoginButton" /></span>
              </Link>
            </div>

          </div>
        </div>
      </>
    );
  }



  genderChanged(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      gender: value
    });
  }

  yearOfBirthChanged(event) {
    const yearOfBirth = event.target.value;
    this.setState({
      yearOfBirth,
      error: {
        ...this.state.error,
        yearOfBirth: !yearOfBirth,
      }
    });
  }

  passwordChanged(newPassword) {
    this.setState({
      password: newPassword,
      error: {
        ...this.state.error,
        password: !newPassword
      }
    })
  }

  updateValue(value, name) {
    this.setState({
      [name]: value
    });
  }

  submit(event) {
    event.preventDefault();
    for (let key in this.state.error) {
      if (this.state.error[key]) {
        return;
      }
    }

    this.props.signUp({
      emails: [{
        email: this.state.email,
        main: true
      }],
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      yearOfBirth: this.state.yearOfBirth,
      regInvitationCode: this.state.regInvitationCode
    });
  }
}

SignUp.propTypes = {
  intl: intlShape.isRequired,
  signUp: PropTypes.func.isRequired,
  signUpSuccess: PropTypes.bool.isRequired,
  signUpError: PropTypes.number
};

const mapStateToProps = (state, ownProps) => ({
  signUpSuccess: state.signUpSuccess,
  signUpError: state.signUpError
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  signUp: (userData) => dispatch({ type: 'SIGNUP_REQUEST', userData }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SignUp));