import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoIpm2White from "../images/logo-iplayme2-white.svg";
import logoIpm2 from "../images/logo-iplayme2.svg";
import { store } from '../store';

class HeaderExt extends Component {
  constructor(props, context) {
    super(props);
    this.state={
      lang:'EN'
    }
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  changeLanguage(event) {
    event.preventDefault();
    const lang = event.target.value;
    store.dispatch({ type: "SET_LANGUAGE", data: lang });
    
  }
  render() {
    return (
      <>

        <header className="header-ext headerOnBoarding">
            
            <div className="logoExt">
              <Link to="/">
                <img className="img-responsive hidden-md hidden-lg" src={logoIpm2} alt="logo iPlayMe2" />
                <img className="img-responsive hidden-xs hidden-sm" src={logoIpm2White} alt="logo iPlayMe2" />
              </Link>
            </div>
            <div className="col-right-header">
                {/* <div className="buttonsClubs">
                    <Link className="btn-gral empty" to="/"><img src={iconClubLogin} alt="Club Login" /> Log in as a Club</Link>
                    <Link className="btn-gral primary" to="/">Run a Club? Register Now!</Link>
                </div> */}
                <div className="lang">
                <select
                    name="language"
                    className="dropdown-toggle"
                    onChange={this.changeLanguage}
                >
                    <option value="en" selected={"en" === this.props.language}>English</option>
                    <option value="es" selected={"es" === this.props.language}>Español</option>
                    <option value="fr" selected={"fr" === this.props.language}>Français</option>
                    <option value="it" selected={"it" === this.props.language}>Italian</option>
                    <option value="de" selected={"de" === this.props.language}>Deutsch</option>
                    <option value="nl" selected={"nl" === this.props.language}>Nederlands</option>
                    <option value="pt" selected={"pt" === this.props.language}>Portuguese</option>
                    <option value="hu" selected={"hu" === this.props.language}>Magyar</option>
                </select>
                </div>
            </div>
            
        </header>   
      </>
    );
  }
  componentDidMount(){
    if(!window.document.getElementById("root").classList.contains("bg-webaccess")){
      window.document.getElementById("root").classList.add("bg-webaccess");
    }
  }  
}

HeaderExt.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData || {},
    language: state.appConfig.language
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserData: () => dispatch({ type: "GET_USERDATA" }),
  logOut: () => dispatch({ type: "LOGOUT" })
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderExt)
);
