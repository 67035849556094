import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from "react-router-dom";
import HeaderOnBoarding from "./comp/HeaderOnBoarding";
import iconApple from "./images/on-boarding/icon-apple.svg";
import iconFacebook from "./images/on-boarding/icon-facebook.svg";
import iconGoogle from "./images/on-boarding/icon-google.svg";
import iconNewAccount from "./images/on-boarding/icon-newAccount.svg";
import iconReturningPlayer from "./images/on-boarding/icon-ReturningPlayer.svg";
import { parseQueryStringToObject } from "./utils";

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      fieldError: false,
      bounce: false,
      trigger: ''
    };
    this.googleCallback = this.googleCallback.bind(this);
    this.googleCallbackFail = this.googleCallbackFail.bind(this);
    this.fbCallback = this.fbCallback.bind(this);

    this.emailChanged = this.emailChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <HeaderOnBoarding />

        <div className="container content-login">
          <div className="row">

            <div className="col-xs-12 col-md-8">

              <div className="wrapperOnboard wrapperOnboardLogin  text-center">
                <div className="boxTitle">
                  <img src={iconReturningPlayer} alt="Returning Player" />
                  <h1 className="h3">
                <Switch>
                  <Route path="/login/checkYourEmail" component={() => <FormattedMessage id="WA.Landing.RegistrationComplete"/>}/>
                  <Route path="/login" component={() => <FormattedMessage id="WA.ReturningPlayer"/>}/>
                </Switch>
                </h1>
                  
                  <p><FormattedMessage id="WA.LogIn"/></p>
                </div>
                <div class="loginOption">
                  <h2 className="p hidden"><FormattedMessage id="WA.Landing.OrConnectWith"/></h2>
                  <ul className="loginNetworks">

                    {this.props.googleClientId &&
                      <GoogleLogin
                        autoLoad={false}
                        clientId={this.props.googleClientId}
                        onSuccess={this.googleCallback}
                        onFailure={this.googleCallbackFail}
                        tag="span"
                        style={{}}
                        type={null}

                        render={renderProps => (
                          <li><button onClick={renderProps.onClick} disabled={renderProps.disabled}><img class="img-responsive" src={iconGoogle} alt="Sign in with Google"/></button></li>
                        )}
                      >
                      </GoogleLogin>
                    }
                    {this.props.fbAppId &&
                      <FacebookLogin
                        appId={this.props.fbAppId}
                        autoLoad={false}
                        fields="name,email"
                        callback={this.fbCallback}
                        render={renderProps => (
                          <li><button onClick={renderProps.onClick}><img class="img-responsive" src={iconFacebook} alt="Sign in with Facebook" /></button></li>
                        )}
                      />
                    }
                    <li><a href={`https://appleid.apple.com/auth/authorize?client_id=com.iplayme2RNXL.staging.oauth.test&redirect_uri=${encodeURI(this.props.webAccessUrl)}/api%2Fappleback&response_type=code%20id_token&state=2385.0447103884608&scope=name%20email&response_mode=form_post&frame_id=c0142b3c-030f-491d-b2f2-1cbab5146009&m=12&v=1.5.2`} target="_blank" rel="noopener noreferrer"><img class="img-responsive" src={iconApple} alt="Sign in with Apple" /></a></li>
                  </ul>
                </div>
                <div class="loginOption text-center">
                  <h2 className="p">
                    <FormattedMessage
                      id="WA.Landing.OrConnectWith"
                    />
                  </h2>
                  <Link className="btn-gral empty" to="/signinmobile"><FormattedMessage id="WA.Landing.SignUpWith.Mobile"/></Link>
                  <Link className="btn-gral empty" to="/signinmail"><FormattedMessage id="WA.Landing.SignUpWith.Email"/></Link>
                </div>
              </div>

            </div>

            
            <div className="col-xs-12 col-md-4">
              <Link className="newAccountCTA text-center" to="/signupsocial">
                  <img class="" src={iconNewAccount} alt="Create New Account" />
                  <h2 className="h3"><FormattedMessage id="WA.Landing.NewAccount"/></h2>
                  <p><FormattedMessage id="WA.StartPlayingNow"/></p>
                  <span className="itisfree"><FormattedMessage id="WA.ItsFreeForever"/></span>
              </Link>
            </div>

            {/* <div className="col-xs-12 hidden-md hidden-lg">
              <div className="buttonGroupClubs">
                  <Link className="btn-gral empty" to="/"><img class="" src={iconClubLogin} alt="Club Login" /> Log in as a Club</Link>
                  <Link className="btn-gral primary" to="/">Run a Club? Register Now!</Link>
              </div>
            </div> */}

            

          </div>
        </div>
      </>
    );
  }

  fbCallback(response) {
    console.log("FB login success");
    if (response.accessToken) {
      this.props.getServerToken(response, 'facebook');
    }
  }

  googleCallback(response) {
    console.log("Google login success");
    this.props.getServerToken(response, 'google');
  }

  googleCallbackFail(error, details) {
    console.log(`error: ${JSON.stringify(error)}, details: ${details}`);
  }

  emailChanged(event) {
    this.setState({ email: event.target.value });
  }

  passwordChanged(event) {
    this.setState({ password: event.target.value });
  }

  loginClicked(event) {
    event.preventDefault();
    if (this.state.password && this.state.email) {
      this.setState({ fieldError: false, bounce: true });
      this.props.loginWithPassword(this.state.email, this.state.password);
    } else {
      this.setState({ fieldError: true, bounce: true });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  }
  componentDidMount(){
    if(this.props.location.search){
      const query = parseQueryStringToObject(this.props.location.search);
      if(query.at){
        let variables = parseQueryStringToObject(atob(query.at));
        this.props.loginWithApple(variables.id, variables.fn, variables.ln);
      }
    }
    window.AppleID.auth.init({
        clientId: this.props.appleClientId,
        scope: 'name email',
        redirectURI: window.document.location.href.substring(0, window.document.location.href.lastIndexOf("/"))+"/api/appleback",
        state: (Math.random()*10000).toString()
    });
  }


  renderVerified() {
    return (
      <h2>
        <FormattedMessage id="WA.Login.VerifiedEmail" />
      </h2>
    )
  }

  renderCheckYourEmail() {
    return (
      <h2>
        <FormattedMessage id="WA.Login.CheckYourEmail" />
      </h2>
    )
  }

  renderHeader() {

    let bounce = this.state.bounce;
    if (bounce) {
      setTimeout(() => {
        this.setState({ bounce: false });
      }, 2000);
    }
    if (this.state.fieldError) {
      return this.headerText(true, 'WA.Error.LoginField', bounce);
    } else if (this.props.loginError) {
      return this.headerText(true, 'WA.ErrorLogin', bounce);
    }
    return this.headerText(false, 'WA.Landing.Login', false);
  }
  headerText(error, textKey, bounce) {

    let errorC = error ? "help-block" : "";
    let bounceC = bounce ? "anim-fade" : "";
    return (
      <h2 className={error ? "has-error" : ""}>
        <span className={[errorC, bounceC].join(" ")}>
          <FormattedMessage id={textKey} />
        </span>
      </h2>
    )
  }

};

Login.propTypes = {
  fbAppId: PropTypes.string,
  getServerToken: PropTypes.func.isRequired,
  googleClientId: PropTypes.string,
  intl: intlShape.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginError: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  fbAppId: state.config.fbAppId,
  googleClientId: state.config.googleClientId,
  appleClientId: state.config.appleClientId,
  webAccessUrl: state.config.webAccessUrl,
  loggedIn: state.loggedIn,
  loginError: state.loginError
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getServerToken: (response, provider) => {
    dispatch({ type: 'SOCIAL_LOGIN_SUCCEED', provider, response });
  },
  loginWithPassword: (username, password) => {
    dispatch({ type: 'LOGIN_WITH_PASSWORD', username, password });
    
  },
  loginWithApple: (token) => {
    dispatch({ type: 'LOGIN_WITH_APPLE', token });
    
  }
  
  // ,
  // getConfig: () => {
  //   dispatch({ type: 'GET_CONFIG' })
  // }
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));